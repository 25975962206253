// React
import { useEffect, useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Components
import KonsultantkiPreviewPanel from "../../components/KonsultantkiPreviewPanel";

// Settings
import KonsultantkiGrid from "../../components/KonsultantkiGrid";

// Css
import "./index.css"
import { isMobile, isMobileOnly } from "react-device-detect";

const KonsultantkiPage = () => {
    const reduxState = useSelector(state => state)

    const [mosueDownResizeBar, setMouseDownResizeBar] = useState(false)
    const [gridHeight, setGridHeight] = useState(0)

    useEffect(() => {
        if(reduxState.toolBar.panelVisible)
        {
            setGridHeight(50)
        }
        else
        {
            const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
            const toolBarHeight = document.querySelector("#toolBar")?.clientHeight
            if(correctionY && toolBarHeight)
            {
                const height = ((window.innerHeight - correctionY - toolBarHeight) / (window.innerHeight)) * 100
                setGridHeight(height)
            }
        }
    }, [reduxState.toolBar.panelVisible])

    const handleMouseDownResizeBar = (e) => {
        setMouseDownResizeBar(true)
    }

    const handleMouseUpResizeBar = (e) => {
        setMouseDownResizeBar(false)
    }

    const handleMouseMove = (e) => {
        if (mosueDownResizeBar) {
            const correctionY = document.querySelector(".ag-theme-alpine").getBoundingClientRect().y
            let clientY
            if(isMobile)
            {
                clientY = e.touches[0].clientY - correctionY
            }
            else
            {
                clientY = e.clientY - correctionY
            }

            const height = (clientY / (window.innerHeight)) * 100
            if(height > 0)
            {
                setGridHeight(height)
            }
        }
    }

    const getToolbarHeight = () => {
        if(reduxState.toolBar.panelVisible) {
            const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y ?? 0
            const toolBarHeight = document.querySelector("#toolBar")?.clientHeight ?? 0
            const correction = ((correctionY + toolBarHeight) * 100) / window.innerHeight
            return 100 - correction - gridHeight - 0.1
        }
        return 0
    }

    return (
        <div onMouseMove={handleMouseMove} 
            onTouchMove={handleMouseMove} 
            onMouseUp={handleMouseUpResizeBar} 
            onTouchEnd={handleMouseUpResizeBar} >
            <KonsultantkiGrid
                className="konsultantkiGrid"
                style={{ height: reduxState.toolBar.panelVisible ? `${gridHeight}vh` : "" }}
            />
            {
                reduxState.toolBar.panelVisible ?
                    <div style={{ height: `${getToolbarHeight()}vh`, boxSizing:"border-box" }}>
                        <div
                            className="panel-bar"
                            onMouseDown={handleMouseDownResizeBar}
                            onTouchStart={handleMouseDownResizeBar}>
                        </div>
                        <KonsultantkiPreviewPanel
                            height={getToolbarHeight()}
                            selectedRowsData={reduxState.navigationContext.selectedRows} />
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default KonsultantkiPage;