// Api
import LoginApi from "../api/LoginApi"

// Services
import TokenService from "./TokenService"

// login error types
const BAD_LOGIN = "BAD_LOGIN"
const BAD_PASSWORD = "BAD_PASSWORD"
const BLOCKED = "BLOCKED"

class LoginService {
    static async checkIsLogged() {
        const res = await LoginApi.checkIsLogged(TokenService.getToken())
        if (!res.ok) {
            return { success: false }
        }
        return { success: true, data: res.data }
    }

    // return { success, data }
    static async login(login, password) {
        const res = await LoginApi.login(login, password)
        if (!res.ok) {
            const message = this.getLoginErrorText(res)
            return { success: false, message }
        }
        TokenService.setToken(res.data.token)
        return { success: true, data: res.data }
    }

    static async logout() {
        const res = await LoginApi.logout(TokenService.getToken())
        if (!res.ok) {
            const message = `LoginService.logout()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        TokenService.removeToken()
    }

    static getLoginErrorText(res) {
        switch (res.data.errorType) {
            case BAD_LOGIN:
                return "Błędny login!"
            case BAD_PASSWORD:
                return "Błędne hasło"
            case BLOCKED:
                if (res.data.time == 0)
                    return "Konto zostało zablokowane. Zgłoś się do Administratora"
                else
                    return `Konto zostało zablokowane. Odczekaj ${res.data.time} min i spróbuj ponownie`
            default:
                return res.status
        }
    }

    static async getTemporaryPassword(userID) {
        const res = await LoginApi.getTemporaryPassword(TokenService.getToken(), userID)
        if (!res.ok) {
            const message = `LoginService.getTemporaryPassword()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.temporaryPassword
    }
}

export default LoginService