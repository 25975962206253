// Api
import AccessrightsApi from "../api/AccessrightsApi"

// Services
import TokenService from "./TokenService"

class AccessrightsService {
    static async getAccessrights() {
        const res = await AccessrightsApi.getAll(TokenService.getToken())
        if (!res.ok) {
            const message = `AccessrightsService.getAccessrights()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.accessrights
    }
}

export default AccessrightsService
