// Libraries
import { useEffect, useState } from "react";

// Constants
import AlertColors from "./constants/alertColors";
import AlertType from "./types/alertTypes";

// Css
import "./index.css"

const Alert = ({ title, text, type }) => {
    const [color, setColor] = useState("")

    useEffect(() => {
        setAlertColor()
    }, [type])

    const setAlertColor = () => {
        switch (type) {
            case AlertType.DANGER:
                setColor(AlertColors.DANGER)
                break;
            case AlertType.WARNING:
                setColor(AlertColors.WARNING)
                break;
            case AlertType.SUCCESS:
                setColor(AlertColors.SUCCESS)
                break;
            case AlertType.INFO:
                setColor(AlertColors.INFO)
                break;
            case AlertType.DEFAULT:
            default:
                setColor(AlertColors.DEFAULT)
        }
    }

    return (
        <div className="alert-container" style={{
            backgroundColor: color,
            borderColor: color,
        }}>
            <h2>{title}</h2>
            <p>{text}</p>
        </div>
    );
}

export default Alert;
