// React
import { useState } from "react"
import { useDispatch } from "react-redux"

// Libraries
import { Form, Formik } from "formik"

// Redux
import { setUserChangePassword } from "../../redux/actions/authActions"

// Services
import UsersService from "../../services/UsersService"

// Types
import AlertTypes from "../Alert/types/alertTypes";

// Components
import { FormCaption, FormItem, Input } from "../Styled"
import ButtonPrimary from "../Styled/ButtonPrimary"
import Alert from "../Alert"

// Validation
import { setPasswordValidationSchema } from "./validation"

// Modal actions
import { showErrorModal } from "../GlobalModal/actions"

const ChangePasswordForm = (props) => {
    // State
    const [userData, setUserData] = useState({
        password: "",
        reppassword: "",
    })

    // Redux
    const dispatch = useDispatch()

    const handleSubmit = async (values, actions) => {
        if (values.password !== values.reppassword) {
            showErrorModal(dispatch, "Błąd", "Podane hasła nie są takie same!")
            return
        }

        // Metoda do ustawienia hasła
        const userData = { password: values.password, changePassword: false }
        try {
            await UsersService.updateCurrent(userData)
            dispatch(setUserChangePassword(false))
            props.navigate("/konsultantki")
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    return <div>
        <FormCaption>Zmień hasło</FormCaption>
        <Formik
            initialValues={{ ...userData }}
            onSubmit={handleSubmit}
            validationSchema={setPasswordValidationSchema}
            render={({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormItem>
                        <Input
                            type="password"
                            name="password"
                            placeholder="hasło"
                            onChange={handleChange}
                            value={values.password}
                        />
                        {
                            errors.password && touched.password ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.password}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="password"
                            name="reppassword"
                            placeholder="powtórz hasło"
                            onChange={handleChange}
                            value={values.reppassword}
                        />
                        {
                            errors.reppassword && touched.reppassword ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.reppassword}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <ButtonPrimary type="submit">Zapisz</ButtonPrimary>
                    </FormItem>
                </Form>
            )}
        />
    </div>
}

export default ChangePasswordForm