// Tools
import RandomTool from "../../Tools/RandomTool"

// Types
import {
  SET_SERVER_EVENT,
  DELETE_SERVER_EVENT_BY_UUID,
  DELETE_SERVER_EVENT_BY_NAME
} from "../types/serverEventsTypes"

export const ServerEventNames = {
  UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK: "UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK",
  FETCH_KONSULTANTKI: "FETCH_KONSULTANTKI",
  UPDATE_APPSETTINGS_BASED_ON_EVENTS_STACK: "UPDATE_APPSETTINGS_BASED_ON_EVENTS_STACK",
  FETCH_APPSETTINGS: "FETCH_APPSETTINGS",
  REFRESH_APPSETTINGS: "REFRESH_APPSETTINGS",
}

export const setServerEvent = (name, data = {}) => {
  const uuid = RandomTool.getUUID()
  return {
      type: SET_SERVER_EVENT,
      payload: { uuid, name, data }
  }
}

export const deleteServerEventByUUID = (uuid) => {
  return {
      type: DELETE_SERVER_EVENT_BY_UUID,
      payload: { uuid }
  }
}

export const deleteServerEventByName = (name) => {
  return {
      type: DELETE_SERVER_EVENT_BY_NAME,
      payload: { name }
  }
}