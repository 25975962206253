// Components
import Modal from "../Modal"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { resetGlobalModal, setResultGlobalModal } from "../../redux/actions/globalModalActions"


const GlobalModal = () => {
    const dispatch = useDispatch()
    const reduxState = useSelector(state => state)

    const handleAction = (actionType, data) => {
        dispatch(setResultGlobalModal({ actionType, data }))
    }

    const handleClose = () => {
        dispatch(resetGlobalModal())
    }

    return <Modal
        open={reduxState.globalModal.options.open}
        type={reduxState.globalModal.options.type}
        title={reduxState.globalModal.options.title}
        text={reduxState.globalModal.options.text}
        blockClosing={reduxState.globalModal.options.blockClosing}
        buttons={reduxState.globalModal.options.buttons}
        onAction={handleAction}
        onClose={handleClose}
    />
}

export default GlobalModal