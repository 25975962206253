const INITIAL_STATE = {
    userData: {},
    initLoggedCheck: false,
    logged: false,
    loggedDateTime: null,
    navigationContext: {
        selectedRows: []
    },
    toolBar: {
        panelVisible: false
    },
    appSettings: [
    //    {
    //     type: AppSettingType,
    //     path: "",
    //     value --> konkretna wartość np. true, "test"
    //    } 
    ],
    notification: {
        panelVisible: false,
        panelEnable: false,
        modalEnable: false,
        notifications: [
            // {
            //     id: 1,
            //     dateTime: "2023-09-13T00:51:19",
            //     title: "Testowe powiadomienie",
            //     description: "Jakieś tam testy",
            //     priority: "HIGH",
            //     isNew: true
            // }
        ]
    },
    globalModal: {
        options: {
            key: "",
            open: false,
            type: "",
            title: "",
            text: "",
            buttons: "",
            blockClosing: false
        },
        result: {
            key: "",
            actionType: "",
            data: null
        }
    },
    error: {
        title: "",
        text: ""
    },
    konsultantki: 
    {
        fetchUUID: "",
        firstFetched: false,
        data: []
    },
    // Zdarzenia na które będziemy dodawać i na które będziemy reagować w aplikacji
    // Wyzwalacze
    events: [
        // { uuid:, name: "", data: {} }
    ],
    serverEvents: [
        // { uuid:, name: "", data: {} }
    ]
}

export default INITIAL_STATE