import RegisterSettingsApi from "../api/RegisterSettingsApi"
import TokenService from "./TokenService"

class RegisterSettingsService {
    // return 
    static async getSubliders() {
        const res = await RegisterSettingsApi.getSubliders(TokenService.getToken())
        if (!res.ok) {
            const message = `RegisterSettingsService.getSubliders()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    static async setRedirection(liderID) {
        const res = await RegisterSettingsApi.setRedirection(TokenService.getToken(), liderID)
        if (!res.ok) {
            const message = `RegisterSettingsService.setRedirection()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }
}

export default RegisterSettingsService