// Css
import "./index.css"

const MainSettingsPage = () => {

    return (
        <div>
            <h1>Ustawienia</h1>
            <p>Dokumentacja</p>
        </div>
    );
}

export default MainSettingsPage
