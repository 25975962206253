// Libraries
import { useEffect, useRef, useState } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { LocalEventNames } from "../../redux/actions/localEventsActions"

// Components
import { resetGlobalModal } from "../../redux/actions/globalModalActions"

// Services
import RegistrationRedirectionsScheduleService from "../../services/RegistrationRedirectionsScheduleService"

// Types
import ModalButtonsType from "../Modal/types/modalButtonsType"
import RegistrationRedirectionScheduleType from "../../types/RegistrationRedirectionScheduleType"

// Css
import "./index.css"

// Modal actions
import { showErrorModal, showWarningModal } from "../GlobalModal/actions"
import ButtonDanger from "../Styled/ButtonDanger"
import { AgGridReact } from "ag-grid-react"
import gridOptions from "./configuration/gridOptions"

const RegistrationRedirectionsScheduleGrid = (props) => {
    const gridRef = useRef()

    const [gridHeight, setGridHeight] = useState(20)
    const [registrationRedirectionsSchedule, setRegistrationRedirectionsSchedule] = useState([])
    const [deleteIds, setDeleteIds] = useState([])

    // Redux
    const dispatch = useDispatch()
    const reduxState = useSelector(x => x)

    useEffect(() =>{
        setTimeout(() => {
          const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
          if(correctionY)
          {
              const height = ((window.innerHeight - correctionY) / (window.innerHeight)) * 100
              setGridHeight(height)
          }
        }, 1000)

        window.addEventListener('resize', e => {
            const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
            if(correctionY)
            {
                const height = ((window.innerHeight - correctionY) / (window.innerHeight)) * 100
                setGridHeight(height)
            }
        })
      }, [])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        const event = reduxState.events.find(x => x.name === LocalEventNames.REFRESH_REGISTRATION_REDIRECTION_SSCHEDULE_GRID)
        if (event) {
            getData()
        }

    }, [reduxState.events])

    useEffect(() => {
        const deleteRegistrationRedirectionsSchedule = async () => {
            try {
                await RegistrationRedirectionsScheduleService.deleteMany(deleteIds)
                await getData()
            }
            catch (error) {
                showErrorModal(dispatch, "Błąd", error.toString())
            }
            dispatch(resetGlobalModal())
        }

        if (reduxState.globalModal.result.data != null
            && reduxState.globalModal.result.key === "DELETE_REGISTRATION_REDIRECTIONS_SCHEDULE") {
            
            if(reduxState.globalModal.result.data.result && deleteIds && deleteIds.length > 0)
            {
                deleteRegistrationRedirectionsSchedule()
            }
            else
            {
                dispatch(resetGlobalModal())
            }
        }

    }, [reduxState.globalModal.result])

    const getData = async () => {
        await getRegistrationRedirectionsSchedule()
    }

    const getRegistrationRedirectionsSchedule = async () => {
        try {
            const data = await RegistrationRedirectionsScheduleService.getByUser()

            const registrationRedirectionsSchedule = data.registrationRedirectionsSchedule.filter(x => x.type != RegistrationRedirectionScheduleType.CONSTANT)
            setRegistrationRedirectionsSchedule([...registrationRedirectionsSchedule])
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const handleClickDeleteRegistrationRedirectionsSchedule = async () => {
        const ids = gridRef.current.api
            .getSelectedRows()
            .map(x => x.id)
        
        if(!ids || ids.length == 0)
        {
            return
        }

        setDeleteIds(ids)

        const title = "Usuwanie zapisów harmongoramu"
        const text = "Czy na pewno chcesz usunąć zaznaczone zapisy harmonogramu ?"
        showWarningModal(dispatch, title, text, ModalButtonsType.YESNO, "DELETE_REGISTRATION_REDIRECTIONS_SCHEDULE")
    }

    return <div className="registrationredirectionsschedulegrid-container" {...props}>
         <div className="registrationredirectionsschedulegrid-buttons-container">
            <ButtonDanger
                onClick={handleClickDeleteRegistrationRedirectionsSchedule}
            >Usuń</ButtonDanger>
        </div>
        <div className="ag-theme-alpine" style={{ height: `${gridHeight}vh`, width: '100%' }}>
            <AgGridReact
                ref={gridRef}
                gridOptions={gridOptions}
                rowData={registrationRedirectionsSchedule}
            />
        </div>
    </div>
}

export default RegistrationRedirectionsScheduleGrid