// Libraries
import { composeWithDevTools } from 'redux-devtools-extension'

// Redux
import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './reducers/rootReducer'

// Middlewares
import serverEventsMiddleware from './middlewares/serverEventsMiddleware'

// Settings
import appModeSettings from '../settings/appModeSettings'

const configureStore = (rootReducer) => {
    const middlewares = [serverEventsMiddleware]
    const middlewareEnhancer = applyMiddleware(...middlewares)

    const enhancers = [middlewareEnhancer]

    const appMode = localStorage.getItem(appModeSettings.APP_MODE)
    if(appMode === appModeSettings.DEVELOPE)
    {
        return createStore(rootReducer, composeWithDevTools(...enhancers))
    }
    return createStore(rootReducer, compose(...enhancers))
}

export default configureStore(rootReducer)