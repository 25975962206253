import UsersApi from "../api/UsersApi";
import TokenService from "./TokenService";

class UsersService {
    static async getAll() {
        const res = await UsersApi.getAllUsersData(TokenService.getToken())
        if (!res.ok) {
            const message = `UsersService.getAll()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.usersData
    }

    static async getAllPermited() {
        const res = await UsersApi.getUsersData(TokenService.getToken())
        if (!res.ok) {
            const message = `UsersService.getAllPermited()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.usersData
    }

    static async getbyId(id) {
        const res = await UsersApi.getById(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `UsersService.getbyId()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.user
    }

    // userData = { login: null, password: null, roleId: null, blocked: null, changePassword: null }
    static async update(id, userData) {
        const res = await UsersApi.update(id, TokenService.getToken(), userData)
        if (!res.ok) {
            const message = `UsersService.update()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    // userData = { login: null, password: null, roleId: null, blocked: null, changePassword: null }
    static async updateCurrent(userData) {
        const res = await UsersApi.updateCurrent(TokenService.getToken(), userData)
        if (!res.ok) {
            const message = `UsersService.updateCurrent()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static async deleteUser(id){
        const res = await UsersApi.deleteUser(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `UsersService.deleteUser()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static async checkExists(login) {
        const res = await UsersApi.updateCurrent(TokenService.getToken(), login)
        if (!res.ok) {
            const message = `UsersService.checkExists()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.exists
    }
}

export default UsersService