// Libraries
import { useDispatch } from "react-redux"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"

// Redux
import { LocalEventNames, setLocalEvent } from "../../redux/actions/localEventsActions";

// Serives
import RolesService from "../../services/RolesService";
import LidersService from "../../services/LidersService";

// Types
import AlertTypes from "../Alert/types/alertTypes";

// Components
import Alert from "../Alert"
import { Button, FormCaption, FormItem, Input, Label, Select, TextArea, WrapForm } from "../Styled"

// Validation
import { createLiderValidationSchema } from "./validation"

// GFX
import xSymbol from "./gfx/x-symbol.svg"

// Css
import "./index.css"

// Modal actions
import { showErrorModal, showSuccessModal } from "../GlobalModal/actions";

const AddLiderForm = ({ visible, close }) => {
    // State
    const [liders, setLiders] = useState([])
    const [roles, setRoles] = useState([])
    const [liderData, setLiderData] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        personalNo: "",
        linkAvon: "",
        overridingLeader: 1,
        createUser: false,
        login: "",
        password: "",
        roleId: 1,
        pagesStr: ""
    })

    // Redux
    const dispatch = useDispatch()

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        await getLiders()
        await getRoles()
    }

    const getLiders = async () => {
        try {
            const liders = await LidersService.getAllPlain()
            setLiders(liders)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const getRoles = async () => {
        try {
            const roles = await RolesService.getAll()
            setRoles(roles)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const parsePagesStrToArray = (pagesStr) => {
        if (!pagesStr) {
            return []
        }

        return pagesStr.split("\n")
    }

    const handleSubmitForm = async (values, actions) => {
        try {
            const leaderData = {
                name: values.name,
                surname: values.surname,
                email: values.email,
                phone: values.phone,
                personalNo: values.personalNo,
                avonLink: values.linkAvon,
                overridingLider: values.overridingLeader,
                pages: parsePagesStrToArray(values.pagesStr)
            }

            if (values.createUser) {
                const userData = {
                    login: values.login,
                    password: values.password,
                    roleId: values.roleId
                }
                await LidersService.createWithUser(userData, leaderData)
            }
            else {
                await LidersService.create(leaderData)
            }
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
            return
        }

        const successTitle = "Utworzono lidera"
        const successMessage = "Lider został pomyślnie utworzony"
        showSuccessModal(dispatch, successTitle, successMessage)
        await refreshData()

        actions.resetForm()
        if (close) {
            close()
        }
    }

    const handleClickClose = (e) => {
        if (e.target.className !== "addliderform-overlay") {
            return
        }

        if (close) {
            close()
        }
    }

    const refreshData = async () => {
        dispatch(setLocalEvent(LocalEventNames.REFRESH_SUBLIDERS_GRID))
        await getData()
    }

    return <div
        onClick={handleClickClose}
        className="addliderform-overlay"
        style={{
            display: visible ? "" : "none"
        }}>

        <WrapForm className="addliderform-wrap">
            <img
                className="addliderform-x"
                onClick={() => close()}
                src={xSymbol}
                alt="xSymbol" />
            <FormCaption>Tworzenie lidera</FormCaption>
            <Formik
                initialValues={{ ...liderData }}
                onSubmit={handleSubmitForm}
                validationSchema={createLiderValidationSchema}
            >{({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormItem style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Input
                            style={{ width: 50 }}
                            type="checkbox"
                            name="createUser"
                            onChange={handleChange}
                            value={values.createUser}
                        />
                        <Label style={{ fontSize: 20 }}>Utwórz użytkownika</Label>
                    </FormItem>
                    {
                        values.createUser ?
                            <>
                                <FormItem>
                                    <Input
                                        type="text"
                                        name="login"
                                        placeholder="login"
                                        onChange={handleChange}
                                        value={values.login}
                                    />
                                    {
                                        errors.login && touched.login ?
                                            <Alert
                                                type={AlertTypes.DANGER}
                                                title="Błąd"
                                                text={errors.login}
                                            />
                                            :
                                            null
                                    }
                                </FormItem>
                                <FormItem>
                                    <Input
                                        type="text"
                                        name="password"
                                        placeholder="hasło"
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                    {
                                        errors.password && touched.password ?
                                            <Alert
                                                type={AlertTypes.DANGER}
                                                title="Błąd"
                                                text={errors.password}
                                            />
                                            :
                                            null
                                    }
                                </FormItem>
                                <FormItem>
                                    <Select
                                        name="roleId"
                                        onChange={handleChange}
                                        value={values.roleId}
                                    >
                                        {
                                            roles.map((r, key) => {
                                                return <option key={key} value={r.id}>{r.nazwa}</option>
                                            })
                                        }
                                    </Select>
                                </FormItem>
                                <FormItem>
                                    <TextArea
                                        name="pagesStr"
                                        placeholder="strony"
                                        onChange={handleChange}
                                        value={values.pagesStr}
                                    >
                                    </TextArea>
                                    {
                                        errors.pagesStr && touched.pagesStr ?
                                            <Alert
                                                type={AlertTypes.DANGER}
                                                title="Błąd"
                                                text={errors.pagesStr}
                                            />
                                            :
                                            null
                                    }
                                </FormItem>
                            </>
                            :
                            null
                    }

                    <FormItem>
                        <Input
                            type="text"
                            name="name"
                            placeholder="imię"
                            onChange={handleChange}
                            value={values.name}
                        />
                        {
                            errors.name && touched.name ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.name}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="text"
                            name="surname"
                            placeholder="nazwisko"
                            onChange={handleChange}
                            value={values.surname}
                        />
                        {
                            errors.surname && touched.surname ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.surname}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="email"
                            name="email"
                            placeholder="email"
                            onChange={handleChange}
                            value={values.email}
                        />
                        {
                            errors.email && touched.email ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.email}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="phone"
                            name="phone"
                            placeholder="telefon"
                            onChange={handleChange}
                            value={values.phone}
                        />
                        {
                            errors.phone && touched.phone ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.phone}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="text"
                            name="personalNo"
                            placeholder="nr personalny"
                            onChange={handleChange}
                            value={values.personalNo}
                        />
                        {
                            errors.personalNo && touched.personalNo ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.personalNo}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <label>Nadrzędny lider</label>
                        <Select
                            name="overridingLeader"
                            onChange={handleChange}
                            value={values.overridingLeader}
                        >
                            {
                                liders.map((l, key) => {
                                    return <option key={key} value={l.id}>{l.nrPersonalny} {l.imie} {l.nazwisko}</option>
                                })
                            }
                        </Select>
                    </FormItem>

                    <FormItem>
                        <Input
                            type="text"
                            name="linkAvon"
                            placeholder="link avon"
                            onChange={handleChange}
                            value={values.linkAvon}
                        />
                        {
                            errors.linkAvon && touched.linkAvon ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.linkAvon}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Button type="submit">Utwórz</Button>
                    </FormItem>
                </Form>
            )}
            </Formik>
        </WrapForm>
    </div>
}

export default AddLiderForm