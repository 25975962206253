// React
import React, { useEffect, useState } from "react";

// Libraries
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import UIConfigurationService from "./services/UIConfigurationService";

// Routes
import PrivateRoute from "./components/Routes/PrivateRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";

// Components
import MainNavbar from "./components/MainNavbar";
import ToolBar from "./components/ToolBar";
import GlobalModal from "./components/GlobalModal";
import NotificationsModalContainer from "./components/NotificationsModalContainer";

// Listeners
import RouteChangeListener from "./listeners/RouteChangeListener";

// Settings
import routes, { RouteType } from "./settings/routes"

// Modal actions
import { showErrorModal } from "./components/GlobalModal/actions";

// Css
import "./reset.css"
import "./aggridCustom.css"
import "./App.css"

const App = () => {
  const [uiSettings, setUiSettings] = useState({
    toolbarVisible: false
  })

  const dispatch = useDispatch()
  const reduxState = useSelector(state => state)

  useEffect(() => {
    // initUISettings
    initUISettings()
  }, [])

  const onChangeRoute = async () => {
    await initUISettings()
  }

  const initUISettings = async () => {
    try {
      const toolbarVisible = await UIConfigurationService.isToolBarVisible()
      setUiSettings({ toolbarVisible })
    }
    catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
    }
  }

  return (
    <div>
      <Router>
        <RouteChangeListener
          onChange={onChangeRoute}
        >
          {
            reduxState.logged ?
              <MainNavbar />
              : null
          }
          <div className="app-container">
            <Routes>
              {
                routes.map((routeData, index) => {
                  if (routeData.type === RouteType.PRIVATEROUTE) {
                    if (routeData.exact) {
                      return <Route
                        key={index}
                        exact path={routeData.path}
                        element={<PrivateRoute />}
                      >
                        <Route 
                          exact path={routeData.path} 
                          element={routeData.component} 
                        >
                          {
                            routeData.subpagesRoutes?.map(subrouteData => {
                              return <Route 
                                path={subrouteData.path}
                                element={subrouteData.component}
                              />
                            })
                          }
                        </Route>
                      </Route>
                    }
                    else {
                      return <Route
                        key={index}
                        path={routeData.path}
                        element={<PrivateRoute />}
                      >
                        <Route 
                          path={routeData.path} 
                          element={routeData.component} 
                        >
                          {
                            routeData.subpagesRoutes?.map(subrouteData => {
                              return <Route 
                                path={subrouteData.path}
                                element={subrouteData.component}
                              />
                            })
                          }
                        </Route>
                    </Route>
                    }

                  }

                  if (routeData.type === RouteType.PROTECTEDROUTE) {
                    if (routeData.exact) {
                      return <Route
                        key={index}
                        exact path={routeData.path}
                        element={
                        <ProtectedRoute 
                          blockLogged={routeData.blockLogged}
                          redirect={routeData.redirect}
                        />
                        }
                      >
                        <Route 
                          exact path={routeData.path} 
                          element={routeData.component} 
                        />
                    </Route>
                    }
                    else {
                      return <Route
                        key={index}
                        path={routeData.path}
                        element={
                        <ProtectedRoute 
                          blockLogged={routeData.blockLogged}
                          redirect={routeData.redirect}
                        />
                        }
                      >
                        <Route 
                          path={routeData.path} 
                          element={routeData.component} 
                        />
                    </Route> 
                    }
                  }

                  if (routeData.type === RouteType.PUBLICROUTE) {
                    if (routeData.exact) {
                      return <Route
                        key={index}
                        exact path={routeData.path}
                        element={routeData.component}
                      />
                    }
                    else {
                      return <Route
                        key={index}
                        path={routeData.path}
                        element={routeData.component}
                      />
                    }
                  }
                  return <></>
                })
              }
            </Routes>
          </div>
          {
            reduxState.logged ?
            <>
              {
                uiSettings.toolbarVisible ?
                <ToolBar />
                : 
                null
              }
              {

              }

              <NotificationsModalContainer/>
            </>
            :
            null
          }
        </RouteChangeListener>
      </Router>
      <GlobalModal />
    </div>
  );
}

export default App;