import { useEffect } from "react"
import appModeSettings from "../settings/appModeSettings"
import { useDispatch } from "react-redux"
import TokenService from "../services/TokenService"
import { setUserLogged, setUserLoggedout } from "../redux/actions/authActions"
import LoginService from "../services/LoginService"

// Nie usuwać tego import, ponieważ inaczej AG-Grid nie działa!
import { LicenseManager } from "ag-grid-enterprise"
import AppSettingsConfiguration from "../settings/AppSettingsConfiguration"
import AppSettingsService from "../services/AppSettingsService"

// Component
const InitializeProvider = ({ children }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        // initialize functions
        const initializeAppMode = () => {
            const localhostAddresses = ["127.0.0.1", "localhost"]
            if (!localhostAddresses.includes(window.location.hostname)) {
                localStorage.setItem(appModeSettings.APP_MODE, appModeSettings.PRODUCTION)
            }
            else {
                localStorage.setItem(appModeSettings.APP_MODE, appModeSettings.DEVELOPE)
            }
        }
    
        const checkIsUserLogged = async () => {
            const token = TokenService.getToken();
            if (!token) {
              logout()
              return
            }
        
            const result = await LoginService.checkIsLogged()
            if (!result.success) {
              logout()
              return
            }
        
            dispatch(setUserLogged(result.data))
        }

        // helpers
        const logout = () => {
            TokenService.removeToken()
            dispatch(setUserLoggedout())
        }

        // Main function
        const initialize = async () => {
            initializeAppMode()
            await checkIsUserLogged()
        }

        // Invoke
        initialize()
    }, [])

    return <>{children}</>
}

export default InitializeProvider