import styled from "styled-components";

export const FormCaption = styled.h1`
font-size:35pt;
`

export const WrapForm = styled.div`
    padding: 35px 45px;
    position: absolute;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:#e5e5e5;
    border-radius:10px;
    text-align:center;
    `


export const Form = styled.form`    
    margin-top:20px;
    `

export const FormItem = styled.div`
    margin-top:10px;
    `
export const Button = styled.button`
    border: none;
    font-size:20px;
    border-radius:5px;
    margin-top:30px;
    padding:20px 0;
    background-color:#343a40;
    width:100%;
    color:white;
    &:hover {        
    background-color:#23272b;
    cursor:pointer;
    }
    `
export const Label = styled.label`

`

export const Input = styled.input`
    font-size:20px;
    border: none;
    border-radius:5px;
    padding:15px;
    margin:8px 0;
    width:100%;
    `

export const Select = styled.select`
    font-size:20px;
    border: none;
    border-radius:5px;
    padding:15px;
    margin:8px 0;
    width:100%;
    `

export const TextArea = styled.textarea`
    font-size:20px;
    border: none;
    border-radius:5px;
    padding:15px;
    margin:8px 0;
    width:100%;
    min-height: 150px;
`