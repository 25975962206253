class TimeTool
{
  static format(timeStr)
  {
    if(!timeStr)
    {
      return ""
    }
    const date = new Date(timeStr)
    return date.toLocaleString()
  }

  static async delay(ms) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }
}

export default TimeTool