// Libraries
import { useEffect, useState } from "react";
import { Form, Formik } from "formik"
import { useDispatch } from "react-redux";

// Serives
import UsersService from "../../services/UsersService";
import RolesService from "../../services/RolesService"

// Types
import AlertTypes from '../Alert/types/alertTypes';

// Components
import Alert from "../Alert"
import { Button, FormCaption, FormItem, Input, Label, Select } from "../Styled"

// Css
import "./index.css"

// GFX
import xSymbol from "./gfx/x-symbol.svg"

// Modal actions
import { showErrorModal, showSuccessModal } from "../GlobalModal/actions";

const EditUserForm = ({ userId, closeForm, refreshUsersData }) => {
    const [roles, setRoles] = useState([])
    const [userData, setUserData] = useState({
        login: "",
        password: "",
        blocked: false,
        changePassword: false,
        roleId: 1
    })

    const dispatch = useDispatch()

    useEffect(() => {
        if (userId) {
            getData()
        }
    }, [userId])

    const getData = async () => {
        await getUserData()
        await getRoles()
    }

    const getUserData = async () => {
        try {
            const userData = await UsersService.getbyId(userId)
            setUserData({
                login: userData.login,
                blocked: userData.blokada,
                roleId: userData.rolaID,
                changePassword: userData.zmienHaslo
            })
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const getRoles = async () => {
        try {
            const roles = await RolesService.getAll()
            setRoles(roles)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const handleSubmitForm = async (values, actions) => {
        try {
            const userData = {
                login: values.login,
                roleId: values.roleId,
                blocked: values.blocked,
                changePassword: values.changePassword
            }

            if (values.password) {
                userData.password = values.password
            }

            await UsersService.update(userId, userData)
            await getData()
        }
        catch (error) {
            showErrorModal(error.toString())
            return
        }

        const successTitle = "Pomyślna aktualizacja"
        const successMessage = "Dane użytkownika zostały zaktualizowane"
        showSuccessModal(dispatch, successTitle, successMessage)
        actions.resetForm()

        if (closeForm) {
            closeForm()
        }

        if (refreshUsersData) {
            await refreshUsersData()
        }
    }

    return <div className="edituserform-container">
        <img
            className="edituserform-x"
            onClick={() => closeForm()}
            src={xSymbol}
            alt="xSymbol" />
        <FormCaption>Edycja użytkownika</FormCaption>
        <Formik
            enableReinitialize={true}
            initialValues={{ ...userData }}
            onSubmit={handleSubmitForm}
        >{({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting
        }) => (
            <Form onSubmit={handleSubmit}>
                <FormItem>
                    <Input
                        type="text"
                        name="login"
                        placeholder="login"
                        onChange={handleChange}
                        value={values.login}
                    />
                    {
                        errors.login && touched.login ?
                            <Alert
                                type={AlertTypes.DANGER}
                                title="Błąd"
                                text={errors.login}
                            />
                            :
                            null
                    }
                </FormItem>
                <FormItem>
                    <Input
                        type="password"
                        name="password"
                        placeholder="hasło"
                        onChange={handleChange}
                        value={values.password}
                    />
                    {
                        errors.password && touched.password ?
                            <Alert
                                type={AlertTypes.DANGER}
                                title="Błąd"
                                text={errors.password}
                            />
                            :
                            null
                    }
                </FormItem>
                <FormItem style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Input
                        style={{ width: 50 }}
                        type="checkbox"
                        name="blocked"
                        onChange={handleChange}
                        checked={values.blocked}
                        value={values.blocked}
                    />
                    <Label style={{ fontSize: 20 }}>Blokada</Label>
                </FormItem>
                <FormItem style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Input
                        style={{ width: 50 }}
                        type="checkbox"
                        name="changePassword"
                        onChange={handleChange}
                        checked={values.changePassword}
                        value={values.changePassword}
                    />
                    <Label style={{ fontSize: 20 }}>Zmiana hasła</Label>
                </FormItem>
                <FormItem>
                    <div style={{ textAlign: "left" }}>
                        <Label style={{ fontSize: 20 }}>Rola</Label>
                    </div>
                    <Select
                        name="roleId"
                        onChange={handleChange}
                        value={values.roleId}
                    >
                        {
                            roles.map((r, key) => {
                                return <option key={key} value={r.id}>{r.nazwa}</option>
                            })
                        }
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="submit">Zapisz</Button>
                </FormItem>
            </Form>
        )}
        </Formik>
    </div>
}

export default EditUserForm