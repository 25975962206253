import {
    SET_SELECTED_ROWS,
    CLEAR_SELECTED_ROWS
} from '../types/navigationContextTypes'

export const setSelectedRows = (selectedRows) => {
    return {
        type: SET_SELECTED_ROWS,
        payload: selectedRows
    }
}

export const clearSelectedRows = (selectedRows) => {
    return {
        type: CLEAR_SELECTED_ROWS
    }
}