// Subpages
import RolesSettingsPage from "../pages/SettingsPage/subpages/RolesSettingsPage";
import LogsSettingsPage from "../pages/SettingsPage/subpages/LogsSettingsPage";
import UsersSettingsPage from "../pages/SettingsPage/subpages/UsersSettingsPage";
import MainSettingsPage from "../pages/SettingsPage/subpages/MainSettingsPage";
import LidersSettingsPage from "../pages/SettingsPage/subpages/LidersSettingsPage";
import IntegrationsSettingsPage from "../pages/SettingsPage/subpages/IntegrationsSettingsPage";
import NotificationsSettingsPage from "../pages/SettingsPage/subpages/NotificationsSettingsPage";
import AppSettingsPage from "../pages/SettingsPage/subpages/AppSettingsPage";

const settingsSubpagesRoutes = [
    {
        name: "Ustawienia",
        path: "/ustawienia/",
        component: <MainSettingsPage />
    },
    {
        name: "Aplikacja",
        path: "/ustawienia/app",
        component: <AppSettingsPage />
    },
    {
        name: "Liderzy",
        path: "/ustawienia/liderzy",
        component: <LidersSettingsPage />
    },
    {
        name: "Użytkownicy",
        path: "/ustawienia/uzytkownicy",
        component: <UsersSettingsPage />
    },
    {
        name: "Role",
        path: "/ustawienia/role",
        component: <RolesSettingsPage />
    },
    {
        name: "Integracje",
        path: "/ustawienia/integracje",
        component: <IntegrationsSettingsPage />
    },
    {
        name: "Powiadomienia",
        path: "/ustawienia/powiadomienia",
        component: <NotificationsSettingsPage />
    },
    {
        name: "Logi",
        path: "/ustawienia/logi",
        component: <LogsSettingsPage />
    },
    // {
    //     name: "Podgląd tabel",
    //     path: "/ustawienia/podgladtabel",
    //     component: TablePreviewSettingsPage
    // },
]

export default settingsSubpagesRoutes