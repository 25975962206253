// Redux
import { showGlobalModal } from "../../redux/actions/globalModalActions"

// Types
import ModalButtonsType from "../Modal/types/modalButtonsType"
import ModalType from "../Modal/types/modalType"


export const showSuccessModal = (dispatch, title, text) => {
    const options = {
        type: ModalType.SUCCESS,
        title,
        text,
        buttons: ModalButtonsType.OK,
        // autoClose: false
    }
    dispatch(showGlobalModal(options))
}

export const showInfoModal = (dispatch, title, text) => {
    const options = {
        type: ModalType.INFO,
        title,
        text,
        buttons: ModalButtonsType.OK
    }
    dispatch(showGlobalModal(options))
}

export const showWarningModal = (dispatch, title, text, buttons = ModalButtonsType.OK, key = "") => {
    const options = {
        key,
        open: true,
        type: ModalType.WARNING,
        title,
        text,
        buttons
    }
    dispatch(showGlobalModal(options))
}

export const showErrorModal = (dispatch, title, text) => {
    const options = {
        type: ModalType.DANGER,
        title,
        text,
        buttons: ModalButtonsType.OK
    }
    dispatch(showGlobalModal(options))
}