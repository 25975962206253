import {
    SET_PANEL_VISIBLE,
    SET_PANEL_INVISIBLE
} from "../types/toolBarTypes"

export const setPanelVisible = () => {
    return {
        type: SET_PANEL_VISIBLE
    }
}

export const setPanelInvisible = () => {
    return {
        type: SET_PANEL_INVISIBLE
    }
}