// React
import { useEffect } from "react";

// Libraries
import styled from "styled-components";
import { Airplay, Image, File, ChevronsUp, Delete } from "react-feather";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setPanelInvisible, setPanelVisible } from "../../redux/actions/toolBarActions";
import { resetGlobalModal } from "../../redux/actions/globalModalActions";

// Types
import ModalButtonsType from "../Modal/types/modalButtonsType"

// Services
import KonsultantkiService from "../../services/KonsultantkiService";
import ScreenshotsService from "../../services/ScreenshotsService";
import ImportExportXLSXService from "../../services/ImportExportXLSXService";

// Config
import { clearSelectedRows } from "../../redux/actions/navigationContextActions";

// Modal actions
import { showErrorModal, showInfoModal, showWarningModal } from "../GlobalModal/actions";
import { ServerEventNames, setServerEvent } from "../../redux/actions/serverEventsActions";

const ToolBar = () => {
  const dispatch = useDispatch()
  const reduxState = useSelector(state => state)

  useEffect(() => {
    // Usuwanie konsultantek
    const deleteKonsultantki = async () => {
      try {
        const ids = reduxState.navigationContext.selectedRows.map(k => k.id)
        await KonsultantkiService.deleteMany(ids)
        dispatch(clearSelectedRows())
        dispatch(resetGlobalModal())
        dispatch(setServerEvent(ServerEventNames.UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK))
      }
      catch (error) {
        showErrorModal(dispatch, "Błąd", error.toString())
      }
    }
    
    if (reduxState.globalModal.result.data != null
      && reduxState.globalModal.result.key === "DELETE_KONSULTANTKA") {
        if(reduxState.globalModal.result.data.result)
        {
          deleteKonsultantki()
        }
        else
        {
          dispatch(resetGlobalModal())
        }
    }
  }, [reduxState.globalModal.result])

  const handleClickPanel = () => {
    if (reduxState.toolBar.panelVisible) {
      dispatch(setPanelInvisible())
    }
    else {
      dispatch(setPanelVisible())
    }
  }

  const handleClickDownloadPhoto = () => {
    if (reduxState.navigationContext.selectedRows.length === 0) {
      const title = "Uwaga"
      const text = "Nie zaznaczono żadnej konsultantki!"
      showWarningModal(dispatch, title, text)
      return
    }

    const ids = []
    for (const row of reduxState.navigationContext.selectedRows) {
      ids.push(row.id)
    }
    ScreenshotsService.DownloadByKonsultantkiIds(ids)
  }

  const handleClickDownloadExcel = () => {
    if (reduxState.navigationContext.selectedRows.length === 0) {
      const title = "Uwaga"
      const text = "Nie zaznaczono żadnej konsultantki!"
      showWarningModal(dispatch, title, text)
      return
    }

    const ids = reduxState.navigationContext.selectedRows.map(x => x.id)
    ImportExportXLSXService.ExportKonsultantkiByIds(ids)
  }

  const handleClickRegister = async () => {
    if (reduxState.navigationContext.selectedRows.length === 0) {
      const title = "Uwaga"
      const text = "Nie zaznaczono żadnej konsultantki!"
      showWarningModal(dispatch, title, text)
      return
    }

    const ids = reduxState.navigationContext.selectedRows.map(k => parseInt(k.id))

    try {
      const waitingModalTitle = "Trwa rejestracja"
      const waitingModalText = "Proszę czekać..."
      showInfoModal(dispatch, waitingModalTitle, waitingModalText)

      const results = await KonsultantkiService.register(ids)

      let afterRegisterModalTitle = "Rejestracja zakończona"
      let afterRegisterModalText = ""
      if (results && results.length > 0) {

        if (results.every(x => x.success)) {
          afterRegisterModalText = "Rejestracja przebiegła poprawnie"
          afterRegisterModalTitle += " - Sukces"
        }
        else {
          afterRegisterModalTitle += " - Błędy"
          for (let result of results) {
            afterRegisterModalText += `\n${result.message}`
          }
        }
      }
      showInfoModal(dispatch, afterRegisterModalTitle, afterRegisterModalText)
      dispatch(clearSelectedRows())
    }
    catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
    }
  }

  const handleClickDelete = async () => {
    if (reduxState.navigationContext.selectedRows.length === 0) {
      const title = "Uwaga"
      const text = "Nie zaznaczono żadnej konsultantki!"
      showWarningModal(dispatch, title, text)
      return
    }

    const ids = reduxState.navigationContext.selectedRows.map(k => k.id)

    const title = "Usuwanie konsultantek"
    let text
    if (ids.length === 1) {
      text = `Czy chcesz usunąć wybraną konsultantkę ?`
    }
    else if (ids.length <= 4) {
      text = `Czy chcesz usunąć wybrane ${ids.length} konsultantki ?`
    }
    else {
      text = `Czy chcesz usunąć wybrane ${ids.length} konsultantek ?`
    }

    showWarningModal(dispatch, title, text, ModalButtonsType.YESNO, "DELETE_KONSULTANTKA")
  }

  return (
    <Styles.Wrapper>
      <BottomToolBar.Wrapper id="toolBar">
        <BottomToolBar.Items>
          <BottomToolBar.Item
            style={{ backgroundColor: reduxState.toolBar.panelVisible ? "#D2EDFF" : "" }}
            onClick={handleClickPanel}>
            <BottomToolBar.Icon>
              <Airplay size={16} />
            </BottomToolBar.Icon>
            Panel
          </BottomToolBar.Item>
          <BottomToolBar.Item onClick={handleClickRegister}>
            <BottomToolBar.Icon>
              <ChevronsUp size={16} />
            </BottomToolBar.Icon>
            Rejestracja
          </BottomToolBar.Item>
          <BottomToolBar.Item onClick={handleClickDownloadExcel}>
            <BottomToolBar.Icon>
              <File size={16} />
            </BottomToolBar.Icon>
            Pobierz Excel
          </BottomToolBar.Item>
          <BottomToolBar.Item onClick={handleClickDownloadPhoto}>
            <BottomToolBar.Icon>
              <Image size={16} />
            </BottomToolBar.Icon>
            Pobierz zdjęcia
          </BottomToolBar.Item>
          <BottomToolBar.Item onClick={handleClickDelete}>
            <BottomToolBar.Icon>
              <Delete size={16} />
            </BottomToolBar.Icon>
            Usuń
          </BottomToolBar.Item>
        </BottomToolBar.Items>
      </BottomToolBar.Wrapper>
    </Styles.Wrapper>
  );
}

export default ToolBar;

const Styles = {
  Wrapper: styled.div`
      display: flex;
      background-color: #eeeeee;
      border-top: 1px solid;
    `
};

const Navbar = {
  Wrapper: styled.nav`
      flex: 1;
      align-self: flex-start;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
    `,
  Logo: styled.h1`
      border: 1px solid gray;
      padding: 0.5rem 1rem;
    `,
  Items: styled.ul`
      display: flex;
      list-style: none;
    `,
  Item: styled.li`
  padding: 0 2rem;
      cursor: pointer;
    `
};

const BottomToolBar = {
  Wrapper: styled(Navbar.Wrapper)`
      width: 100vw;
      height: 65px;
      justify-content: center;
      align-content: center;
      align-items: center;
      box-sizing: border-box;
    `,
  Items: styled(Navbar.Items)`
      flex: 1;
      padding: 15px 0;  
      justify-content:space-around;
      max-width:600px;
      align-items: center;
    `,
  Item: styled(Navbar.Item)`  
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center; 
      font-size: 1.2rem;           
      &:hover {        
        background: #D2EDFF;
      }
    `,
  Icon: styled.span``
};