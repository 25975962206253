import Api from "./base/Api"

class LidersApi extends Api {
    static baseRoute = "liders"

    static async getLider(token, id) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/one/${id}`, null, null, token)
    }

    static async getAllPermited(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/permitted`, null, null, token)
    }

    static async getAll(token){
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, null, null, token)
    }

    static async getAllLidersPlain(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/plain`, null, null, token)
    }

    // liderData = { name, surname, email, phone, personalNumber, avonLink, userId, overridingLider = null  }
    static async create(token, leaderData) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}`, { leaderData, createUser: false }, true, headers, token)
    }

    // userData = { login, password, rolaID, changePassword }
    // leaderData = { name, surname, email, phone, personalNo, avonLink, overridingLider }
    static async createWithUser(token, leaderData, userData) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}`, { leaderData, userData, createUser: true }, true, headers, token)
    }

    static async deleteOne(token, id) {
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, null, false, null, token)
    }

    /* 
    leaderData = { email: null, telefon: null, imie: null, nazwisko: null, nrPersonalny: null, 
            link: null, aktywny: null, nadrzednyID: null, aktywujacyID: null, uzytkownikID: null }
    */
    static async update(token, id, leaderData) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._put(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, { leaderData }, true, headers, token)
    }
}

export default LidersApi