// Libraries
import { Navigate, Outlet, Route, redirect } from "react-router-dom";

// Redux
import { useSelector } from "react-redux";

const ProtectedRoute = (props) => {
    const reduxState = useSelector(state => state)

    if (reduxState.logged && props.blockLogged)
    {
        return <Navigate to={props.redirect} />
    }
    return <Outlet />
}

export default ProtectedRoute;