import TimeTool from "../../../../../../Tools/TimeTool"

const gridDefaultConfiguration = [
  {
    headerName: "Data",
    field: "datetime",
    editable: false,
    resizable: true,
    sortable: true,
    sort: 'desc',
    wrapText: true,
    autoHeight: true,
    valueGetter(params) {
        return TimeTool.format(params.data.datetime)
    },
    filter: 'agTextColumnFilter',
  },
  {
    headerName: "Typ",
    field: "type",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: "Tytuł",
    field: "title",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter',
    minWidth: 300,
    flex: 1
  },
  {
    headerName: "Opis",
    field: "description",
    filter: 'agTextColumnFilter',
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    suppressSizeToFit: true,
    minWidth: 300,
    cellStyle:{ wordBreak:'normal', whiteSpace:'pre-wrap'},
    flex: 1,
    cellRenderer(params) {
      if(params.data && params.data.description)
      {
        return params.data.description.replaceAll("<br/>", "\n")
      }
      return ""
    }
  }
]

export default gridDefaultConfiguration