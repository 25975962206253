const AppSettingsConfiguration = {
  AUTH: {
    GROUP: 'AUTH'
  },
  GOOGLE_TRANSLATOR: {
    GROUP: 'GOOGLE_TRANSLATOR',
    FIELD_ENABLE: 'ENABLE',
    FIELD_ENABLE_PATH: '',
    FIELD_URL: 'URL',
    FIELD_URL_PATH: '',
    FIELD_TOKEN: 'TOKEN',
    FIELD_TOKEN_PATH: ''
  },
  NOTIFICATIONS: {
    GROUP: 'NOTIFICATIONS',
    FIELD_ENABLE: 'ENABLE',
    FIELD_ENABLE_PATH: '',
    FIELD_MODAL_ENABLE: 'MODAL_ENABLE',
    FIELD_MODAL_ENABLE_PATH: '',
    FIELD_PANEL_ENABLE: 'PANEL_ENABLE',
    FIELD_PANEL_ENABLE_PATH: ''
  }
}

AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_ENABLE_PATH = `${AppSettingsConfiguration.GOOGLE_TRANSLATOR.GROUP}/${AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_ENABLE}`
AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_URL_PATH = `${AppSettingsConfiguration.GOOGLE_TRANSLATOR.GROUP}/${AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_URL}`
AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_TOKEN_PATH = `${AppSettingsConfiguration.GOOGLE_TRANSLATOR.GROUP}/${AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_TOKEN}`

AppSettingsConfiguration.NOTIFICATIONS.FIELD_ENABLE_PATH = `${AppSettingsConfiguration.NOTIFICATIONS.GROUP}/${AppSettingsConfiguration.NOTIFICATIONS.FIELD_ENABLE}`
AppSettingsConfiguration.NOTIFICATIONS.FIELD_MODAL_ENABLE_PATH = `${AppSettingsConfiguration.NOTIFICATIONS.GROUP}/${AppSettingsConfiguration.NOTIFICATIONS.FIELD_MODAL_ENABLE}`
AppSettingsConfiguration.NOTIFICATIONS.FIELD_PANEL_ENABLE_PATH = `${AppSettingsConfiguration.NOTIFICATIONS.GROUP}/${AppSettingsConfiguration.NOTIFICATIONS.FIELD_PANEL_ENABLE}`

module.exports = AppSettingsConfiguration
