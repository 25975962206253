// Redux
import store from '../redux/store';

// Providers
import { Provider as ReduxProvider } from 'react-redux'
import ServerEventsProvider from './ServerEventsProvider'
import InitializeProvider from './InitializeProvider'
import LoginProvider from './LoginProvider'

const MainProvider = ({ children }) => {
  return <>
   <ReduxProvider store={store}>
    <InitializeProvider>
      <ServerEventsProvider>
        <LoginProvider>
        {children}
        </LoginProvider>
      </ServerEventsProvider>
    </InitializeProvider>
   </ReduxProvider>
  </>
}


export default MainProvider