import RandomTool from "../../Tools/RandomTool"
import {
    SET_LOCAL_EVENT,
    DELETE_LOCAL_EVENT_BY_UUID,
    DELETE_LOCAL_EVENT_BY_NAME
} from "../types/localEventsTypes"

export const LocalEventNames = {
    UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK: "UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK",
    // FETCH_KONSULTANTKI: "FETCH_KONSULTANTKI",
    REFRESH_REGISTRATION_REDIRECTION_SSCHEDULE_GRID: "REFRESH_REGISTRATION_REDIRECTION_SSCHEDULE_GRID",
    REFRESH_SUBLIDERS_GRID: "REFRESH_SUBLIDERS_GRID",
    GRID_KONSULTANTKI_ADD: "GRID_KONSULTANTKI_ADD",
    GRID_KONSULTANTKI_UPDATE: "GRID_KONSULTANTKI_UPDATE",
    GRID_KONSULTANTKI_DELETE: "GRID_KONSULTANTKI_DELETE",
    NEW_NOTIFICATION: "NEW_NOTIFICATION"
}

export const setLocalEvent = (name, data = {}) => {
    const uuid = RandomTool.getUUID()
    return {
        type: SET_LOCAL_EVENT,
        payload: { uuid, name, data }
    }
}

export const deleteLocalEventByUUID = (uuid) => {
    return {
        type: DELETE_LOCAL_EVENT_BY_UUID,
        payload: { uuid }
    }
}

export const deleteLocalEventByName = (name) => {
    return {
        type: DELETE_LOCAL_EVENT_BY_NAME,
        payload: { name }
    }
}