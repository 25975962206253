// Tools
import TimeTool from "../../Tools/TimeTool"

// Css
import "./index.css"

const NotificationsPanelItem = ({ title, description, datetime, isNew }) => {
    return (
        <div className="notificationsPanelItem-container" style={{ background: isNew ? "#EEE" : "" }}>
            <div className="notificationsPanelItem-item">
                <p className="notificationsPanelItem-title">{title}</p>
            </div>
            <div className="notificationsPanelItem-item">
                <p className="notificationsPanelItem-dateTime">{datetime ? TimeTool.format(datetime) : datetime}</p>
            </div>
            <div className="notificationsPanelItem-item">
                <p className="notificationsPanelItem-description">{description}</p>
            </div>
        </div>
    );
}
export default NotificationsPanelItem;
