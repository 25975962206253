import UIConfigurationApi from "../api/UIConfigurationApi"
import TokenService from "./TokenService"

class UIConfigurationService {
    static async isToolBarVisible() {
        // Tymczasow dajemy tablicę z konkretnymi wartościami
        // Potem to będzie pobierane z konfiguracji

        const paths = ["/konsultantki"]
        const { pathname } = window.location
        return paths.includes(pathname)
    }

    static async getConfiguration(name) {
        const res = await UIConfigurationApi.getConfiguration(TokenService.getToken(), name)
        if (!res.ok) {
            const message = `UIConfigurationApi.getConfiguration()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.configuration
    }

    static async saveConfiguration(name, configuration) {
        const res = await UIConfigurationApi.saveConfiguration(TokenService.getToken(), name, configuration)
        if (!res.ok) {
            const message = `UIConfigurationApi.saveConfiguration()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static async deleteConfiguration(name) {
        const res = await UIConfigurationApi.deleteConfiguration(TokenService.getToken(), name)
        if (!res.ok) {
            const message = `UIConfigurationApi.deleteConfiguration()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }
}

export default UIConfigurationService