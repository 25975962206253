import TimeTool from "../../../../../../Tools/TimeTool"

const gridDefaultConfiguration = [
  {
    headerName: "Data aktualizacji",
    field: "dataAktualizacji",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    sort: 'desc',
    valueGetter(params) {
        return TimeTool.format(params.data.dataAktualizacji)
    },
    filter: 'agTextColumnFilter'
  },
  {
    headerName: "Imię",
    field: "imie",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter'
},
  {
    headerName: "Nazwisko",
    field: "nazwisko",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: "Email",
    field: "email",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter',
    minWidth: 300,
    flex: 1
  },
  {
    headerName: "Telefon",
    field: "telefon",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    minWidth: 150,
    flex: 1
  }
]

export default gridDefaultConfiguration