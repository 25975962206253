const gridDefaultConfiguration = [
  { 
    headerName: "id",
    field: "id",
    filter: true,
    editable: false,
    resizable: true,
    sortable: true,
  },
  { 
    headerName: "login",
    field: "login",
    filter: true,
    editable: false,
    resizable: true,
    sortable: true,
  },
  {
      headerName: "blokada",
      field: "blocked",
      filter: true,
      editable: false,
      resizable: true,
      sortable: true,
      valueGetter(params) {
          if (params.data && params.data.blocked) {
              return "Tak"
          }
          else {
              return "Nie"
          }
      },
  },
  {
    headerName: "zmiana hasła",
    field: "changePassword",
    filter: true,
    editable: false,
    resizable: true,
    sortable: true,
    valueGetter(params) {
        if (params.data && params.data.changePassword) {
            return "Tak"
        }
        else {
            return "Nie"
        }
    },
  },
  { 
    headerName: "rola",
    field: "role",
    editable: false,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "strony rejestracji",
    field: "reigsterPages",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    cellStyle:{ wordBreak:'normal', whiteSpace:'pre-wrap'},
    cellRenderer(params) {
        if(params.data && params.data.registerPages && params.data.registerPages.length > 0)
        {
            return params.data.registerPages
                .map(x => x.link)
                .join("\n")
        }
        return ""
    }
  },
  {
      headerName: "zalogowany",
      field: "logged",
      filter: true,
      editable: false,
      resizable: true,
      sortable: true,
      valueGetter(params) {
          if (params.data && params.data.logged) {
              return "Tak"
          }
          else {
              return "Nie"
          }
      },
  }
]

export default gridDefaultConfiguration