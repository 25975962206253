// React
import { useEffect, useState } from "react"

// Libraries
import { Plus, X, Check, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";

// Redux
import { resetGlobalModal } from "../../../../redux/actions/globalModalActions";

// Types
import ModalButtonsType from "../../../../components/Modal/types/modalButtonsType"

// Services
import AccessrightsService from "../../../../services/AccessrightsService";

// Css
import "./index.css"
import RolesService from "../../../../services/RolesService";

// Modal actions
import { showErrorModal, showWarningModal } from "../../../../components/GlobalModal/actions";

const RolesSettingsPage = () => {
    const [rolesData, setRolesData] = useState([])
    const [addRoleData, setAddRoleData] = useState({
        inptVisible: false,
        roleName: ""
    })
    const [deletedRoleId, setDeletedRoleId] = useState(null)

    const reduxState = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        getRolesData()
    }, [])

    useEffect(() => {
        if (reduxState.globalModal.result.data != null
            && reduxState.globalModal.result.key === "DELETE_ROLE") {

            if(reduxState.globalModal.result.data.result && deletedRoleId != null)
            {
                handleDeleteRole()
            }
            else
            {
                dispatch(resetGlobalModal())
            }
        }
    }, [reduxState.globalModal.result])

    const getRolesData = async () => {
        let accessrights = []
        let roles = []
        try {
            accessrights = await AccessrightsService.getAccessrights()
            roles = await RolesService.getRoles()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
            return
        }

        const rolesData = roles.map((role, index) => {
            // grupowanie ACR po typie
            // { type: 1, typeName: "strony", acr: [] }
            const acrsPerType = []

            for (let a of accessrights) {
                let acrPerTypeIndex = acrsPerType.findIndex(x => x.type == a.typ)
                if (acrPerTypeIndex < 0) {
                    acrsPerType.push({
                        panelOpen: false,
                        type: a.typ,
                        typeName: a.typNazwa,
                        acrs: []
                    })
                    acrPerTypeIndex = acrsPerType.length - 1
                }

                if (role.prawoDostepus.some(p => p.nazwa === a.nazwa && p.metoda == a.metoda)) {
                    acrsPerType[acrPerTypeIndex].acrs.push({
                        id: a.id,
                        name: a.nazwa,
                        type: a.typ,
                        typeName: a.typNazwa,
                        method: a.metoda,
                        granted: true
                    })
                }
                else {
                    acrsPerType[acrPerTypeIndex].acrs.push({
                        id: a.id,
                        name: a.nazwa,
                        type: a.typ,
                        typeName: a.typNazwa,
                        method: a.metoda,
                        granted: false
                    })
                }


            }

            return {
                panelOpen: false,
                id: role.id,
                role: role.nazwa,
                level: role.poziom,
                description: role.opis,
                acrsPerType: acrsPerType
            }
        })
        setRolesData(rolesData)
    }

    const handleChangeChecked = async (granted, index, index2, index3) => {
        const rData = [...rolesData]
        rData[index].acrsPerType[index2].acrs[index3].granted = granted
        setRolesData(rData)
        const roleId = rData[index].id
        const accessrightId = rData[index].acrsPerType[index2].acrs[index3].id

        try {
            await RolesService.updateRoleAccessright(roleId, accessrightId, granted)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const handleClickPanel = (e, level, index1, index2) => {
        const clickableClasses = ["accessrighttype", "accessrighttype-title", "role-title-container", "role-title"]
        // accessrighttype accessrighttype-title
        if (!clickableClasses.includes(e.target.className)) {
            return
        }

        if (level > 2) {
            return
        }

        const rData = [...rolesData]
        if (level === 1) {
            rData[index1].panelOpen = !rData[index1].panelOpen
        }
        else if (level === 2) {
            rData[index1].acrsPerType[index2].panelOpen = !rData[index1].acrsPerType[index2].panelOpen
        }
        setRolesData(rData)
    }

    const handleClickAddRolePlus = () => {
        setAddRoleData({
            ...addRoleData,
            inptVisible: true
        })
    }

    const handleClickAddRoleSave = async () => {
        try {
            await RolesService.add(addRoleData.roleName)
            setAddRoleData({
                ...addRoleData,
                roleName: "",
                inptVisible: false
            })

            // Refresh roles view
            getRolesData()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const handleClickAddRoleDiscard = () => {
        setAddRoleData({
            ...addRoleData,
            roleName: "",
            inptVisible: false
        })
    }

    const handleClickDeleteRole = async (id) => {
        setDeletedRoleId(id)

        const title = "Usuwanie roli"
        const text = "Czy na pewno chcesz usunąć rolę ?"
        showWarningModal(dispatch, title, text, ModalButtonsType.YESNO, "DELETE_ROLE")
    }

    const handleDeleteRole = async () => {
        await deleteRole()
        dispatch(resetGlobalModal())
    }

    const deleteRole = async () => {
        try {
            await RolesService.deleteOne(deletedRoleId)
            await getRolesData()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    return <div>
        <h1 className="page-roles-title">Role w Aplikacji</h1>
        <hr className="page-roles-title-hr" />
        <div className="role-container">
            <div className="role-container-panel">
                {
                    rolesData.map((roleData, index) => {
                        return <div key={index} className="role">
                            <div className="role-title-container" onClick={(e) => handleClickPanel(e, 1, index, null)}>
                                <p className="role-title">
                                    {roleData.role}
                                </p>

                                {
                                    roleData.level > 1 ?
                                        <Trash2
                                            cursor="pointer"
                                            onClick={() => handleClickDeleteRole(roleData.id)} />
                                        :
                                        null
                                }

                            </div>
                            {
                                roleData.panelOpen ?
                                    <div>
                                        {
                                            roleData.acrsPerType.map((acrPertype, index2) => {
                                                return <div key={index2} className="accessrighttype">
                                                    <div className="accessrighttype-title" onClick={(e) => handleClickPanel(e, 2, index, index2)}>
                                                        {acrPertype.typeName}
                                                    </div>
                                                    {
                                                        acrPertype.panelOpen ?
                                                            <table className="accessright">
                                                                <tbody>
                                                                    {
                                                                        acrPertype.acrs.map((acr, index3) => {
                                                                            return <tr key={index3} className="accessright-row">
                                                                                <td className="accessright-title">
                                                                                    {acr.name}
                                                                                </td>
                                                                                {
                                                                                    acr.method != null ?
                                                                                        <td>
                                                                                            {acr.method}
                                                                                        </td>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="accessright-checkbox"
                                                                                        onChange={() => handleChangeChecked(!acr.granted, index, index2, index3)}
                                                                                        checked={acr.granted} />
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    })
                }
                <div className="role-add">
                    {
                        addRoleData.inptVisible ?
                            <div className="role-add-input-container">
                                <input
                                    type="text"
                                    className="role-add-input"
                                    value={addRoleData.roleName}
                                    onChange={(e => setAddRoleData({ ...addRoleData, roleName: e.target.value }))} />
                                <Check
                                    size={35}
                                    cursor="pointer"
                                    onClick={handleClickAddRoleSave} />
                                <X
                                    size={35}
                                    cursor="pointer"
                                    onClick={handleClickAddRoleDiscard} />
                            </div>
                            :
                            <Plus
                                size={35}
                                cursor="pointer"
                                onClick={handleClickAddRolePlus} />
                    }
                </div>
            </div>
        </div>
    </div>
}

export default RolesSettingsPage