import Api from "./base/Api"

class LogsApi extends Api {
    static baseRoute = "logs"

    static async getAll(token, lastId = null, count = null) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, { lastId, count }, null, token)
    }

    static async getOne(token, id) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, null, null, token)
    }

    static async getQuery(token, query) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, query, null, token)
    }
}

export default LogsApi