import {
  SET_APPSETTINGS,
  UPDATE_APPSETTINGS,
} from "../types/appSettingsTypes"

// appSettings = []
export const setAppSettings = (appSettings) => {
  return {
      type: SET_APPSETTINGS,
      payload: appSettings
  }
}

// appSettings = []
export const updateAppSettings = (appSettings) => {
  return {
      type: UPDATE_APPSETTINGS,
      payload: appSettings
  }
}