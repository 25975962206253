// Libraries
import { NavLink as Link } from "react-router-dom"

// Css
import "./index.css"

const NavLink = (props) => {
    return <Link className={({ isActive, isPending }) =>
            isActive ? "nav-link-active" : "nav-link"
        }
        {...props} 
        >{props.children}</Link>
}
export default NavLink