// Libraries
import { useDispatch } from "react-redux"
import { Form, Formik } from "formik"
import { useEffect, useState } from "react"

// Redux
import { LocalEventNames, setLocalEvent } from "../../redux/actions/localEventsActions";

// Serives
import RolesService from "../../services/RolesService";
import LidersService from "../../services/LidersService";

// Types
import AlertTypes from "../Alert/types/alertTypes";

// Components
import Alert from "../Alert"
import { Button, FormCaption, FormItem, Input, Select, WrapForm } from "../Styled"

// Validation
// import { createLiderValidationSchema } from "./validation"

// Css
import "./index.css"

// GFX
import xSymbol from "./gfx/x-symbol.svg"

// Modal actions
import { showErrorModal, showSuccessModal } from "../GlobalModal/actions";

const EditLiderForm = ({ visible, close, leaderID }) => {
    // State
    const [liders, setLiders] = useState([])
    const [roles, setRoles] = useState([])
    const [leaderData, setLeaderData] = useState({
        name: "",
        surname: "",
        email: "",
        phone: "",
        personalNo: "",
        linkAvon: "",
        overridingLeader: 1,
        pagesStr: ""
    })

    // Redux
    const dispatch = useDispatch()

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        

        getLeaderData()
    }, [leaderID])

    const refreshData = async () => {
        dispatch(setLocalEvent(LocalEventNames.REFRESH_SUBLIDERS_GRID))
        await getData()
        await getLeaderData()
    }

    const getLeaderData = async () => {
        if (!leaderID) {
            return
        }

        try {
            const leaders = await LidersService.getById(leaderID)
            if (leaders && leaders.length > 0) {
                const leader = leaders[0]
                setLeaderData({
                    name: leader.imie,
                    surname: leader.nazwisko,
                    email: leader.email,
                    phone: leader.telefon,
                    personalNo: leader.nrPersonalny,
                    linkAvon: leader.link,
                    overridingLeader: leader.nadrzednyLiderId
                })
            }
            
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const getData = async () => {
        await getLiders()
        await getRoles()
    }

    const getLiders = async () => {
        try {
            const liders = await LidersService.getAllPlain()
            setLiders(liders)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const getRoles = async () => {
        try {
            const roles = await RolesService.getAll()
            setRoles(roles)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const parsePagesStrToArray = (pagesStr) => {
        if (!pagesStr) {
            return []
        }

        return pagesStr.split("\n")
    }

    const handleSubmitForm = async (values, actions) => {
        try {
            const leaderData = {
                name: values.name,
                surname: values.surname,
                email: values.email,
                phone: values.phone,
                personalNo: values.personalNo,
                avonLink: values.linkAvon,
                overridingLider: values.overridingLeader,
                pages: parsePagesStrToArray(values.pagesStr)
            }

            await LidersService.update(leaderID, leaderData)
            await refreshData()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
            return
        }

        const successTitle = "Zapisano lidera"
        const successMessage = "Dane lidera zostały pomyślnie zaktualizowane"
        showSuccessModal(dispatch, successTitle, successMessage)

        await getData()
        await refreshData()
        actions.resetForm()
    }

    const handleClickClose = (e) => {
        if (e.target.className !== "editliderform-overlay") {
            return
        }

        if (close) {
            close()
        }
    }

    return <div
        onClick={handleClickClose}
        className="editliderform-overlay"
        style={{
            display: visible ? "" : "none"
        }}>

        <WrapForm className="editliderform-wrapform">
            <img
                className="editliderform-x"
                onClick={() => close()}
                src={xSymbol}
                alt="xSymbol" />
            <FormCaption>Edycja lidera</FormCaption>
            <Formik
                enableReinitialize={true}
                initialValues={{ ...leaderData }}
                onSubmit={handleSubmitForm}
            // validationSchema={createLiderValidationSchema}
            >{({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormItem>
                        <Input
                            type="text"
                            name="name"
                            placeholder="imię"
                            onChange={handleChange}
                            value={values.name}
                        />
                        {
                            errors.name && touched.name ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.name}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="text"
                            name="surname"
                            placeholder="nazwisko"
                            onChange={handleChange}
                            value={values.surname}
                        />
                        {
                            errors.surname && touched.surname ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.surname}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="email"
                            name="email"
                            placeholder="email"
                            onChange={handleChange}
                            value={values.email}
                        />
                        {
                            errors.email && touched.email ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.email}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="phone"
                            name="phone"
                            placeholder="telefon"
                            onChange={handleChange}
                            value={values.phone}
                        />
                        {
                            errors.phone && touched.phone ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.phone}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Input
                            type="text"
                            name="personalNo"
                            placeholder="nr personalny"
                            onChange={handleChange}
                            value={values.personalNo}
                        />
                        {
                            errors.personalNo && touched.personalNo ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.personalNo}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <label>Nadrzędny lider</label>
                        <Select
                            name="overridingLeader"
                            onChange={handleChange}
                            value={values.overridingLider}
                        >
                            {
                                liders.map((l, key) => {
                                    return <option key={key} value={l.id}>{l.nrPersonalny} {l.imie} {l.nazwisko}</option>
                                })
                            }
                        </Select>
                    </FormItem>

                    <FormItem>
                        <Input
                            type="text"
                            name="linkAvon"
                            placeholder="link avon"
                            onChange={handleChange}
                            value={values.linkAvon}
                        />
                        {
                            errors.linkAvon && touched.linkAvon ?
                                <Alert
                                    type={AlertTypes.DANGER}
                                    title="Błąd"
                                    text={errors.linkAvon}
                                />
                                :
                                null
                        }
                    </FormItem>
                    <FormItem>
                        <Button type="submit">Zapisz</Button>
                    </FormItem>
                </Form>
            )}
            </Formik>
        </WrapForm>
    </div>
}

export default EditLiderForm