// Libraries
import * as Yup from 'yup';

export const createLiderValidationSchema = Yup.object().shape({
    email: Yup.string()
        .min(2, "Błedny email!")
        .required('Pole jest wymagane'),
    name: Yup.string()
        .min(2, "Błedne imię!")
        .required('Pole jest wymagane'),
    surname: Yup.string()
        .min(2, "Błedne nazwisko!")
        .required('Pole jest wymagane'),
    personalNo: Yup.string()
        .min(2, "Błedny nr Personalny!")
        .required('Pole jest wymagane'),
    linkAvon: Yup.string()
        .min(5, "Błedny link!")
        .required('Pole jest wymagane'),
})

