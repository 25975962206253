class TokenService {
    static getToken() {
        return localStorage.getItem("token")
    }

    static setToken(token) {
        return localStorage.setItem("token", token)
    }

    static removeToken() {
        localStorage.removeItem("token")
    }
}
export default TokenService