import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const RouteChangeListener = ({ children, onChange }) => {
    const location = useLocation()

    useEffect(() => {
        if (onChange) {
            onChange()
        }
    }, [location])

    return <>{children}</>
}

export default RouteChangeListener