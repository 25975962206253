// Pages
import ChangePasswordPage from "../pages/ChangePasswordPage"
import KonsultantkiPage from "../pages/KonsultantkiPage"
import LoginPage from "../pages/LoginPage"
import SettingsPage from "../pages/SettingsPage"
import settingsSubpagesRoutes from "./settingsSubpagesRoutes"

export const RouteType = {
    PROTECTEDROUTE: "PROTECTEDROUTE",
    PRIVATEROUTE: "PRIVATEROUTE",
    PUBLICROUTE: "PUBLICROUTE"
}

const routes = [
    { path: "/", type: RouteType.PROTECTEDROUTE, component: <LoginPage/>, exact: true, blockLogged: true, redirect: "/konsultantki" },
    { path: "/zmianahasla", type: RouteType.PRIVATEROUTE, component: <ChangePasswordPage/>, exact: true },
    { path: "/konsultantki", type: RouteType.PRIVATEROUTE, component: <KonsultantkiPage/>, exact: true },
    { path: "/ustawienia", type: RouteType.PRIVATEROUTE, component: <SettingsPage/>, exact: false, subpagesRoutes: settingsSubpagesRoutes },
]

export default routes