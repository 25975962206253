import LidersApi from "../api/LidersApi"
import TokenService from "./TokenService"

class LidersService {
    // liderData = { liderData, surname, email, phone, personalNo, linkAvon, overridingLider, pages }
    static async create(liderData) {
        const res = await LidersApi.create(TokenService.getToken(), liderData)
        if (!res.ok) {
            const message = `LidersService.create()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    // liderData = { name, surname, email, phone, personalNo, linkAvon, overridingLider }
    // userData = { login, password, roleId }
    static async createWithUser(userData, leaderData) {
        const res = await LidersApi.createWithUser(TokenService.getToken(), leaderData, userData)
        if (!res.ok) {
            const methodMessage = `LidersService.createWithUser()\n${res.status} - ${res.message}`

            let message = null
            if (res.data) {
                message = res.data.message
            }

            throw new Error(`${methodMessage}\n${message}`)
        }
    }

    static async getById(id) {
        const res = await LidersApi.getLider(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `LidersService.getById()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    static async getAllPermited() {
        const res = await LidersApi.getAllPermited(TokenService.getToken())
        if (!res.ok) {
            const message = `LidersService.getAllPermited()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.liders
    }

    static async getAll() {
        const res = await LidersApi.getAll(TokenService.getToken())
        if (!res.ok) {
            const message = `LidersService.getAll()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.liders
    }

    static async getAllPlain() {
        const res = await LidersApi.getAllLidersPlain(TokenService.getToken())
        if (!res.ok) {
            const message = `LidersService.getAllPlain()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.liders
    }

    static async deleteOne(id) {
        const res = await LidersApi.deleteOne(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `LidersService.deleteOne()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    /* 
  leaderData = { email: null, telefon: null, imie: null, nazwisko: null, nrPersonalny: null, 
          link: null, aktywny: null, nadrzednyID: null, aktywujacyID: null, uzytkownikID: null }
  */
    static async update(id, leaderData) {
        const res = await LidersApi.update(TokenService.getToken(), id, leaderData)
        if (!res.ok) {
            const message = `LidersService.update()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }
}

export default LidersService