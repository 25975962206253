// Types
import {
    SET_USER_LOGGED,
    SET_USER_LOGGEDOUT,
    SET_USER_CHANGEPASSWORD,
    SET_INIT_LOGGED_CHECK
} from "../types/authTypes"

import {
    SET_PANEL_VISIBLE,
    SET_PANEL_INVISIBLE
} from "../types/toolBarTypes"

import {
    SET_SELECTED_ROWS,
    CLEAR_SELECTED_ROWS
} from '../types/navigationContextTypes'

import {
    SET_NOTIFICATIONS_PANEL_VISIBILITY,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATIONS,
    SET_NOTIFICATION_MODAL_VISIBILITY,
    SET_NOTIFICATIONS_IS_NEW
} from "../types/notificationsTypes"

import {
    ADD_KONSULTANTKI,
    ADD_KONSULTANTKI_START,
    SET_KONSULTANTKI,
    UPDATE_KONSULTANTKI,
    DELETE_KONSULTANTKI
} from "../types/konsultantkiTypes"

import {
    SHOW_GLOBALMODAL,
    SET_RESULT_GLOBALMODAL,
    HIDE_GLOBALMODAL,
    RESET_GLOBALMODAL
} from "../types/globalModalTypes"

import { 
    SET_ERROR 
} from "../types/errorsTypes"

import { 
    SET_LOCAL_EVENT,
    DELETE_LOCAL_EVENT_BY_UUID,
    DELETE_LOCAL_EVENT_BY_NAME
} from "../types/localEventsTypes"

import { 
    DELETE_SERVER_EVENT_BY_NAME, 
    DELETE_SERVER_EVENT_BY_UUID, 
    SET_SERVER_EVENT 
} from "../types/serverEventsTypes"

import {
    SET_APPSETTINGS,
    UPDATE_APPSETTINGS,
  } from "../types/appSettingsTypes"

// Data
import INITIAL_STATE from "../initialState"

// Tools
import RandomTool from "../../Tools/RandomTool"

// Actions
import { LocalEventNames } from "../actions/localEventsActions"


const rootReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // authTypes
        case SET_USER_LOGGED:
            return {
                ...state,
                userData: action.payload,
                loggedDateTime: new Date(),
                logged: true
            }
        case SET_USER_LOGGEDOUT:
            return {
                ...INITIAL_STATE
            }
        case SET_USER_CHANGEPASSWORD:
            return {
                ...state,
                userData: { ...state.userData, changePassword: action.payload }
            }
        case SET_INIT_LOGGED_CHECK:
            return {
                ...state,
                initLoggedCheck: action.payload
            }

        // toolBarTypes
        case SET_PANEL_VISIBLE:
            return {
                ...state,
                toolBar: { ...state.toolBar, panelVisible: true }
            }
        case SET_PANEL_INVISIBLE:
            return {
                ...state,
                toolBar: { ...state.toolBar, panelVisible: false }
            }

        // navigationContextTypes
        case SET_SELECTED_ROWS:
            return {
                ...state,
                navigationContext: {
                    selectedRows: [...action.payload]
                }
            }

        case CLEAR_SELECTED_ROWS:
            return {
                ...state,
                navigationContext: {
                    selectedRows: []
                }
            }

        // notificationsTypes
        case SET_NOTIFICATIONS_PANEL_VISIBILITY:
            return {
                ...state,
                notification: {
                    ...state.notification,
                    panelVisible: action.payload
                }
            }
        case ADD_NOTIFICATIONS:
            const addedNotifications = [...action.payload].map(x => ({ 
                    ...x,
                    fetchDateTime: new Date(),
                    modalVisible: true
                })
            )

            return {
                ...state,
                notification: {
                    ...state.notification,
                    notifications: [...addedNotifications, ...state.notification.notifications]
                }
            }
        case SET_NOTIFICATIONS:
            const setNotifications = [...action.payload].map(x => ({ 
                    ...x,
                    fetchDateTime: new Date(),
                    modalVisible: true
                })
            )

            return {
                ...state,
                notification: {
                    ...state.notification,
                    notifications: [...setNotifications]
                }
            }
        case SET_NOTIFICATIONS_IS_NEW:
            const updatedNewNotifications = [...state.notification.notifications]
                .map(x => ({ ...x, isNew: false }) )

            return {
                ...state,
                notification: {
                    ...state.notification,
                    notifications: [...updatedNewNotifications]
                }
            }
        case SET_NOTIFICATION_MODAL_VISIBILITY:
            const updatedNotifications = [...state.notification.notifications]
            const index = updatedNotifications.findIndex(x => x.id == action.payload.id)
            if(index >= 0)
            {
                updatedNotifications[index].modalVisible = action.payload.visible
            }

            return {
                ...state,
                notification: {
                    ...state.notification,
                    notifications: [...updatedNotifications]
                }
            }
        // konsultantkiTypes
        case ADD_KONSULTANTKI:
            return {
                ...state,
                konsultantki: {
                    ...state.konsultantki,
                    fetchUUID: action.payload.fetchUUID,
                    data: [...state.konsultantki.data, ...action.payload.konsultantki]
                }
            }
        case ADD_KONSULTANTKI_START:
            return {
                ...state,
                konsultantki: {
                    ...state.konsultantki,
                    fetchUUID: action.payload.fetchUUID,
                    data: [ ...action.payload.konsultantki, ...state.konsultantki.data]
                }
            }
        case SET_KONSULTANTKI:
            return {
                ...state,
                konsultantki: {
                    fetchUUID: action.payload.fetchUUID,
                    firstFetched: true,
                    data: [...action.payload.konsultantki]
                } 
            }
        case UPDATE_KONSULTANTKI:
            const newKonsultantki = []
            const updatedKonsultantki = []
            const allKonsultantki = [...state.konsultantki.data]
            for (let updatedKonsultantka of action.payload) {
                const index = allKonsultantki.findIndex(k => k.id === updatedKonsultantka.id)
                if (index >= 0) {
                    allKonsultantki[index] = updatedKonsultantka
                    updatedKonsultantki.push(updatedKonsultantka)
                }
                else {
                    allKonsultantki.unshift(updatedKonsultantka)
                    newKonsultantki.push(updatedKonsultantka)
                }
            }
            // Local events
            const newLocalEvents = []
            if(updatedKonsultantki.length > 0)
            {
                newLocalEvents.push({ uuid: RandomTool.getUUID(), name: LocalEventNames.GRID_KONSULTANTKI_UPDATE, data: [...updatedKonsultantki] })
            }

            if(newKonsultantki.length > 0)
            {
                newLocalEvents.push({ uuid: RandomTool.getUUID(), name: LocalEventNames.GRID_KONSULTANTKI_ADD, data: [...newKonsultantki] })
            }

            return {
                ...state,
                konsultantki: {
                    ...state.konsultantki,
                    data: [...allKonsultantki]
                },
                events: [
                    ...state.events,
                    ...newLocalEvents
                ]
            }
        case DELETE_KONSULTANTKI:
            let updatedDeleteKonsultantki = [...state.konsultantki.data]
            updatedDeleteKonsultantki = updatedDeleteKonsultantki.filter(k => !action.payload.includes(k.id))
            return {
                ...state,
                konsultantki: {
                    ...state.konsultantki,
                    data: [...updatedDeleteKonsultantki]
                },
                // Local events
                events: [
                    ...state.events,
                    { uuid: RandomTool.getUUID(), name: LocalEventNames.GRID_KONSULTANTKI_DELETE, data: [...action.payload] }
                ]
            }

        // globalModalTypes
        case SHOW_GLOBALMODAL:
            return {
                ...state,
                globalModal: {
                    ...state.globalModal,
                    options: { ...INITIAL_STATE.globalModal.options, open: true, ...action.payload }
                }
            }
        case HIDE_GLOBALMODAL:
            return {
                ...state,
                globalModal: {
                    ...INITIAL_STATE.globalModal
                }
            }
        case SET_RESULT_GLOBALMODAL:
            return {
                ...state,
                globalModal: {
                    ...state.globalModal,
                    result: {
                        ...INITIAL_STATE.globalModal.result,
                        ...action.payload,
                        key: state.globalModal.options.key
                    }
                }
            }
        case RESET_GLOBALMODAL:
            return {
                ...state,
                globalModal: {
                    ...INITIAL_STATE.globalModal
                }
            }

        // errorsTypes
        case SET_ERROR:
            return {
                ...state,
                error: {
                    title: action.payload.title,
                    text: action.payload.text
                },
            }

        // localEventsTypes
        case SET_LOCAL_EVENT:
            return {
                ...state,
                events: [
                    ...state.events,
                    action.payload
                ]
            }

        case DELETE_LOCAL_EVENT_BY_UUID:
            const updatedLocalEventsByUUID = state.events.filter(x => x.uuid != action.payload.uuid)
            return {
                ...state,
                events: [
                    ...updatedLocalEventsByUUID
                ]
            }
        case DELETE_LOCAL_EVENT_BY_NAME:
            const updatedLocalEventsByName = state.events.filter(x => x.name != action.payload.name)
            return {
                ...state,
                events: [
                    ...updatedLocalEventsByName
                ]
            }

        // serverEventsTypes
        case SET_SERVER_EVENT:
            return {
                ...state,
                serverEvents: [
                    ...state.serverEvents,
                    action.payload
                ]
            }
        case DELETE_SERVER_EVENT_BY_UUID:
            const updatedserverEventsByUUID = state.serverEvents.filter(x => x.uuid != action.payload.uuid)
            return {
                ...state,
                serverEvents: [
                    ...updatedserverEventsByUUID
                ]
            }
        case DELETE_SERVER_EVENT_BY_NAME:
            const updatedServerEventsByName = state.serverEvents.filter(x => x.name != action.payload.name)
            return {
                ...state,
                serverEvents: [
                    ...updatedServerEventsByName
                ]
            }

        // appSettings
        case SET_APPSETTINGS:
            const appSettingsSetCopy = [...state.appSettings]
            for(const appSetting of action.payload) {
                const appSettingUpdateIndex = appSettingsSetCopy.findIndex(x => x.path === appSetting.path)
                if(appSettingUpdateIndex >= 0)
                {
                    appSettingsSetCopy[appSettingUpdateIndex] = appSetting
                }
                else
                {
                    appSettingsSetCopy.push(appSetting)
                }
            }

            return {
                ...state,
                appSettings: [
                    ...appSettingsSetCopy
                ]
            }
        case UPDATE_APPSETTINGS:
            const appSettingsUpdateCopy = [...state.appSettings]
            for(const appSetting of action.payload) {
                const appSettingUpdateIndex = appSettingsUpdateCopy.findIndex(x => x.path === appSetting.path)
                if(appSettingUpdateIndex >= 0)
                {
                    appSettingsUpdateCopy[appSettingUpdateIndex] = appSetting
                }
            }

            return {
                ...state,
                appSettings: [
                    ...appSettingsUpdateCopy
                ]
            }
        default:
            return state
    }
}

export default rootReducer