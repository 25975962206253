// libraries
import styled from "styled-components";

const ButtonSecondary = styled.button`
    background-color: #5bc0de;
    color: white;
    font-size: 1.5rem;
    padding: 10px;
    border: 2px solid #5bc0de;
    border-radius: 8%;
    cursor: pointer;
    &:hover {        
        background-color:#63cdf2;
        border-color: #63cdf2;
        cursor:pointer;
    }
`

export default ButtonSecondary