// Libraries
import { useDispatch, useSelector } from "react-redux";

// Redux
import { setNotificationsIsNew, setNotificationsPanelVisibility } from "../../redux/actions/notificationsActions";

// Components
import ButtonSecondary from "../Styled/ButtonSecondary";
import NotificationsPanel from "../NotificationsPanel";

// Css
import "./index.css"

const NotificationsButton = () => {
    const reduxState = useSelector(state => state)
    const dispatch = useDispatch()

    const handleClick = async () => {
        if (reduxState.notification.panelVisible) {
            // W momencie otwarcia zmieniami nowe powiadomienia na odczytane
            dispatch(setNotificationsIsNew(false))
        }

        if(reduxState.notification.panelVisible) {
            dispatch(setNotificationsPanelVisibility(false))
        }
        else
        {
            dispatch(setNotificationsPanelVisibility(true))
        }
    }

    const countNewNotifications = () => {
        return reduxState.notification.notifications
            .filter(n => n.isNew)
            .length
    }

    return (
        <div className="notificationButton-contrainer">
            <ButtonSecondary id="notificationButton" onClick={handleClick} >Powiadomienia</ButtonSecondary>
            {
                reduxState.notification.panelVisible ?
                    <NotificationsPanel />
                    :
                    null
            }
            <div className="notificationButton-counter">{countNewNotifications()}</div>
        </div>
    );
}
export default NotificationsButton;
