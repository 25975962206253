// Libraries
import { AgGridReact } from "ag-grid-react"

// Configuration
import gridOptions from "./configuration/gridOptions"

// Css
import "./index.css"

const KonsultantkaHistoryTab = ({ konsultantka }) => {
  if(!konsultantka || !konsultantka.konsultantkaHistories)
  {
    return <p>Brak danych do wyświetlenia</p>
  }

  return <div className="ag-theme-alpine konsultantkaHistoryTab-grid-container">
      <AgGridReact
        gridOptions={gridOptions}
        rowData={konsultantka.konsultantkaHistories}
      />
    </div>
}

export default KonsultantkaHistoryTab