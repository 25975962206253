// React
import { useCallback, useEffect, useRef, useState } from "react"

// Metarial UI
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { resetGlobalModal } from "../../../../redux/actions/globalModalActions";

// Types
import ModalButtonsType from "../../../../components/Modal/types/modalButtonsType"

// Services
import UsersService from "../../../../services/UsersService";
import ButtonPrimary from "../../../../components/Styled/ButtonPrimary";
import LoginService from "../../../../services/LoginService";
import { showErrorModal, showInfoModal, showWarningModal } from "../../../../components/GlobalModal/actions";

// Components
import ButtonSecondary from "../../../../components/Styled/ButtonSecondary";
import ButtonDanger from "../../../../components/Styled/ButtonDanger";
import { WrapForm } from "../../../../components/Styled";
import EditUserForm from "../../../../components/EditUserForm";

// Configuration
import gridOptions from "./configuration/gridOptions";

// Css
import "./index.css"

const UsersSettingsPage = () => {
    const gridRef = useRef();

    const [gridHeight, setGridHeight] = useState(100)
    const [usersData, setUsersData] = useState([])
    const [selectedUser, setSelectedUser] = useState({})
    const [editUserFormVisibility, setEditUserFormVisibility] = useState(false)

    const dispatch = useDispatch()
    const reduxState = useSelector(state => state)

    useEffect(() =>{
        setTimeout(() => {
          const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
          if(correctionY)
          {
              const height = ((window.innerHeight - correctionY) / (window.innerHeight)) * 100
              setGridHeight(height)
          }
        }, 1000)

        window.addEventListener('resize', e => {
            const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
            if(correctionY)
            {
                const height = ((window.innerHeight - correctionY) / (window.innerHeight)) * 100
                setGridHeight(height)
            }
        })
      }, [])

    useEffect(() => {
        getData()
    }, [])

    // Modal events
    useEffect(() => {
        const handleDeleteUser = async () => {
            await deleteUser()
            dispatch(resetGlobalModal())
            setSelectedUser({})
        }

        if (reduxState.globalModal.result.data != null
            && reduxState.globalModal.result.key === "DELETE_USER") {
                if(reduxState.globalModal.result.data.result && selectedUser)
                {
                    handleDeleteUser()
                }
                else
                {
                    dispatch(resetGlobalModal())
                }
            }
    }, [reduxState.globalModal.result])

    const deleteUser = async () => {
        try
        {
            await UsersService.deleteUser(selectedUser.id)
            await getData()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const handleClickRefresh = async () => {
        await getData()
    }

    const getData = async () => {
        await getUsers()
    }

    const getUsers = async () => {
        try {
            const users = await UsersService.getAll()
            setUsersData(users)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const onSelectionChanged = useCallback(() => {
        const selectedUsers = gridRef.current.api.getSelectedRows();
        if (selectedUsers && selectedUsers.length > 0) {
            setSelectedUser(selectedUsers[0])
        }
        else {
            setSelectedUser({})
        }
    })

    const handleClickHideEditUserForm = (e) => {
        if (e.target.className !== "edituserform-overlay") {
            return
        }
        setEditUserFormVisibility(false)
    }

    const handleClickEdit = () => {
        setEditUserFormVisibility(true)
    }

    const handleClickDelete = () => {
        const title = "Usuwanie użytkownika"
        const text = "Czy na pewno chcesz usunąć danego użytkownika ?"
        showWarningModal(dispatch, title, text, ModalButtonsType.YESNO, "DELETE_USER")
    }

    const handleClickGetTemporaryPassword = async () => {
        const password = await LoginService.getTemporaryPassword(selectedUser.id)
        navigator.clipboard.writeText(password)
        const title = `Hasło jednorazowe dla ${selectedUser.login}`
        const text = "Skopiowano do schowka!\nHasło: " + password
        showInfoModal(dispatch, title, text)
    }

    return <div className="settings-userspage-container">
        <div className="settings-userspage-bar">
            <ButtonPrimary onClick={handleClickRefresh}>Odśwież</ButtonPrimary>
            {
                selectedUser && JSON.stringify(selectedUser) !== "{}" ?
                    <>
                        <ButtonSecondary onClick={handleClickEdit}>Edytuj</ButtonSecondary>
                        <ButtonDanger onClick={handleClickDelete}>Usuń</ButtonDanger>
                        <ButtonPrimary onClick={handleClickGetTemporaryPassword}>Generuj jednorazowe hasło</ButtonPrimary>
                    </>
                    :
                    null
            }
        </div>
        <div className="ag-theme-alpine" style={{ height: `${gridHeight}vh`, width: '100%' }}>
            <AgGridReact
                gridOptions={gridOptions}
                ref={gridRef}
                rowData={usersData}
                rowSelection="single"
                onSelectionChanged={onSelectionChanged}
            />
        </div>
        <div style={{ marginTop: 30, marginLeft: 10 }}>
            <div
                onClick={handleClickHideEditUserForm}
                className="edituserform-overlay"
                style={{
                    display: editUserFormVisibility ? "" : "none"
                }}>
                <WrapForm className="edituserform-wrapform">
                    <EditUserForm
                        userId={selectedUser.id}
                        closeForm={() => setEditUserFormVisibility(false)}
                        refreshUsersData={getData} />
                </WrapForm>
            </div>
        </div>
    </div>
}

export default UsersSettingsPage