// React
import { useEffect, useState } from "react";

// Libraries
import { Minus, Plus } from "react-feather";

// Services
import LidersService from "../../../../services/LidersService";
import RegisterSettingsService from "./../../../../services/RegisterSettingsService";
import RolesService from "../../../../services/RolesService";
import RegistrationRedirectionsScheduleService from "../../../../services/RegistrationRedirectionsScheduleService";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Types
import ModalButtonsType from "./../../../../components/Modal/types/modalButtonsType"
import RegistrationRedirectionScheduleType from "../../../../types/RegistrationRedirectionScheduleType";

// Components
import AddLiderForm from "./../../../../components/AddLiderForm";
import AddRegistrationRedirectionsScheduleForm from "../../../../components/AddRegistrationRedirectionsScheduleForm";
import RegistrationRedirectionsScheduleGrid from "../../../../components/RegistrationRedirectionsScheduleGrid";
import EditLiderForm from "../../../../components/EditLiderForm";

// Modal actions
import { showErrorModal, showWarningModal } from "../../../../components/GlobalModal/actions";
import { resetGlobalModal } from "../../../../redux/actions/globalModalActions";

// Css
import "./index.css"
import { LocalEventNames } from "../../../../redux/actions/localEventsActions";
import SublidersGrid from "../../../../components/SublidersGrid";

const LidersSettingsPage = () => {
    const [mainLeaderData, setMainLeaderData] = useState(null)
    const [addLeaderFormVisibility, setAddLeaderFormVisibility] = useState(false)
    const [editLeaderFormVisibility, setEditLeaderFormVisibility] = useState(false)
    const [addRegistrationRedirectionsScheduleFormVisibility, setAddRegistrationRedirectionsScheduleFormVisibility] = useState(false)
    const [leaderID, setLeaderID] = useState(null)
    const [registrationRedirectionsSchedule, setRegistrationRedirectionsSchedule] = useState([])
    const [userUIAccessrights, setUserUIAccessrights] = useState({
        addLiderForm: false,
        buttonDeleteLeader: false,
        buttonEditLeader: false,
        buttonAddregistrationredirectionsschedule: false
    })

    const reduxState = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        if (reduxState.globalModal.result.data != null
            && reduxState.globalModal.result.key === "DELETE_LEADER") {

            if(reduxState.globalModal.result.data.result && leaderID != null)
            {
                handleDeleteLeader()
            }
            else
            {
                dispatch(resetGlobalModal())
            }
        }
    }, [reduxState.globalModal.result])

    useEffect(() => {
        const event = reduxState.events.find(x => x.name === LocalEventNames.REFRESH_SUBLIDERS_GRID)
        if (event) {
            getData()
        }

    }, [reduxState.events])

    const getData = async () => {
        await getSubliders()
        await getUserUIAccessrights()
        await getRegistrationRedirectionsSchedule()
    }

    const getSubliders = async () => {
        try {
            const data = await RegisterSettingsService.getSubliders()
            if(data.liderData)
            {
                setSubleadersTreeOpened(data.liderData)
                openTreeRedirectedLeader(data.liderData)
                setMainLeaderData(data.liderData)
            }
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    
    const getUserUIAccessrights = async () => {
        const addLiderForm = await RolesService.checkHasAccessToEndpoint("/liders", "POST")
        const buttonDeleteLeader = await RolesService.checkHasAccessToEndpoint("/liders/:id", "DELETE")
        const buttonEditLeader = await RolesService.checkHasAccessToEndpoint("/liders/:id", "PUT")
        const buttonAddregistrationredirectionsschedule = await RolesService.checkHasAccessToEndpoint("/registrationredirectionsschedule", "POST")

        setUserUIAccessrights({
            addLiderForm,
            buttonDeleteLeader,
            buttonEditLeader,
            buttonAddregistrationredirectionsschedule
        })
    }

    const getRegistrationRedirectionsSchedule = async () => {
        try {
            const data = await RegistrationRedirectionsScheduleService.getByUser()

            const registrationRedirectionsSchedule = data.registrationRedirectionsSchedule.filter(x => x.type != RegistrationRedirectionScheduleType.CONSTANT)
            setRegistrationRedirectionsSchedule([...registrationRedirectionsSchedule])
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const setSubleadersTreeOpened = (leaderData) => {
        leaderData.treeOpened = false;
        if (leaderData.subLiders != null) {
            leaderData.canTreeOpen = true
            for (let subLeaderData of leaderData.subLiders) {
                setSubleadersTreeOpened(subLeaderData)
            }
        }
        else {
            leaderData.canTreeOpen = false
        }
    }

    const openTreeRedirectedLeader = (leaderData) => {
        if (leaderData.przekierowaniaRejestracji.some(pr => pr.odAktualnegoUzytkownia)) {
            return true
        }

        if (leaderData.subLiders != null) {
            for (let subLeaderData of leaderData.subLiders) {
                if (openTreeRedirectedLeader(subLeaderData)) {
                    leaderData.treeOpened = true;
                    break
                }
            }
        }
        return leaderData.treeOpened
    }

    const handleClickActivateConstRedirection = async (id) => {
        try {
            const registrationRedirectionScheduleData = {
                type: RegistrationRedirectionScheduleType.CONSTANT,
                leaderID: id
            }
            await RegistrationRedirectionsScheduleService.create(registrationRedirectionScheduleData)
            // await RegisterSettingsService.setRedirection(id)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }

        await getData()
    }

    const handleClickDeleteLeader = (id) => {
        setLeaderID(id)
        const title = "Usuwanie lidera"
        const text = "Czy na pewno chcesz usunąć lidera ?"
        showWarningModal(dispatch, title, text, ModalButtonsType.YESNO, "DELETE_LEADER")
    }

    const handleDeleteLeader = async () => {
        await deleteLider()
        dispatch(resetGlobalModal())
    }

    const deleteLider = async () => {
        try {
            await LidersService.deleteOne(leaderID)
            setLeaderID(null)
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }

        await getData()
    }

    const handleClickEditLeader = async (id) => {
        setLeaderID(id)
        setEditLeaderFormVisibility(true)
    }

    const handleClickAddRegistrationRedirectionsSchedule = async (id) => {
        setLeaderID(id)
        setAddRegistrationRedirectionsScheduleFormVisibility(true)
    }

    const handleClickShowAddLiderForm = (e) => {
        setAddLeaderFormVisibility(true)
    }

    const handleClickOpenTreeLevel = (leaderData) => {
        const mainLeaderDataNew = { ...mainLeaderData }
        setLiderTreeOpened(mainLeaderDataNew, leaderData)
        setMainLeaderData(mainLeaderDataNew)
    }

    const setLiderTreeOpened = (mainLeaderData, liderdata) => {
        if (mainLeaderData.id == liderdata.id) {
            mainLeaderData.treeOpened = !mainLeaderData.treeOpened
            return
        }

        if (mainLeaderData.subLiders != null) {
            for (let subLiderData of mainLeaderData.subLiders) {
                setLiderTreeOpened(subLiderData, liderdata)
            }
        }
    }

    return (
        <div>
            <>
                {
                    userUIAccessrights.addLiderForm ?
                        <div
                            className="addlider-container"
                            onClick={handleClickShowAddLiderForm}>
                            <Plus
                                size={35}
                                cursor="pointer" />
                            <p className="addlider-title">Dodawanie lidera</p>
                        </div>
                        :
                        null
                }
                {
                    userUIAccessrights.addLiderForm ?
                        <AddLiderForm
                            visible={addLeaderFormVisibility}
                            close={() => setAddLeaderFormVisibility(false)} />
                        :
                        null
                }

                {
                    userUIAccessrights.buttonEditLeader ?
                        <EditLiderForm
                            visible={editLeaderFormVisibility}
                            close={() => setEditLeaderFormVisibility(false)}
                            leaderID={leaderID} />
                        :
                        null
                }

                <AddRegistrationRedirectionsScheduleForm
                    visible={addRegistrationRedirectionsScheduleFormVisibility}
                    close={() => setAddRegistrationRedirectionsScheduleFormVisibility(false)}
                    leaderID={leaderID} />
            </>
            <SublidersGrid
                // style={{ minHeight: 200, maxHeight: 300 }}
                style={{ height: 300 }}
                userUIAccessrights={userUIAccessrights}
                mainLeaderData={mainLeaderData}
                onClickActivateConstRedirection={handleClickActivateConstRedirection}
                onClickDeleteLeader={handleClickDeleteLeader}
                onClickEditLeader={handleClickEditLeader}
                onClickAddRegistrationRedirectionsSchedule={handleClickAddRegistrationRedirectionsSchedule}
                onClickOpenTreeLevel={handleClickOpenTreeLevel}
            />
            <RegistrationRedirectionsScheduleGrid  />
        </div>
    );
}

export default LidersSettingsPage;
