import RolesApi from "../api/RolesApi"
import TokenService from "./TokenService"

class RolesService {
    static async getAll() {
        const res = await RolesApi.getRoles(TokenService.getToken())
        if (!res.ok) {
            const message = `RolesService.getAll()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.roles
    }

    static async getRoles() {
        const res = await RolesApi.getRoles(TokenService.getToken())
        if (!res.ok) {
            const message = `RolesService.getRoles()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.roles
    }

    static async checkHasAccessToRoute(route) {
        const res = await RolesApi.checkHasAccessToRoute(TokenService.getToken(), route)
        return res.ok
    }

    static async checkHasAccessToEndpoint(endpoint, method) {
        const res = await RolesApi.checkHasAccessToEndpoint(TokenService.getToken(), endpoint, method)
        return res.ok
    }

    static async updateRoleAccessright(roleId, accessrightId, granted) {
        const res = await RolesApi.updateRoleAccessright(TokenService.getToken(), roleId, accessrightId, granted)
        if (!res.ok) {
            const message = `RolesService.updateRoleAccessright()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.ok
    }

    static async getUserRolePathsAccessrights() {
        const res = await RolesApi.getUserRoleAccessrights(TokenService.getToken(), 1)
        if (!res.ok) {
            const message = `RolesService.getUserRolePathsAccessrights()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.accessrights
    }

    static async add(roleName) {
        const res = await RolesApi.addRole(TokenService.getToken(), roleName)
        if (!res.ok) {
            const message = `RolesService.add()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static async deleteOne(id) {
        const res = await RolesApi.deleteRole(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `RolesService.deleteOne()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

}

export default RolesService