// React
import { useState, useEffect, useRef } from 'react'

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Libraries
import styled from "styled-components";

// Services
import RolesService from '../../services/RolesService';

// Components
import NavLink from '../NavLink';

// Settings
import settingsSubpagesRoutes from '../../settings/settingsSubpagesRoutes';
import { showErrorModal } from '../GlobalModal/actions';

// Css
import "./index.css"
import { isMobile } from 'react-device-detect';

const SettingsNavbar = () => {
  const [pathsAccessrights, setPathsAccessrights] = useState([])
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  const reduxState = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    const closeDrawer = event => {
      if (drawerRef.current && drawerRef.current.contains(event.target))
      {
        return;
      }
      toggleDrawer(false)
    }

    getUserRoleAccessrights()

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, [])

  const getUserRoleAccessrights = async () => {
    try {
      const accessRights = await RolesService.getUserRolePathsAccessrights()
      const paths = accessRights.map(ar => ar.nazwa)
      setPathsAccessrights(paths)
    }
    catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
    }
  }

  return (
    <div className='settingsnavbar-wrapper'>
      <nav className='settingsnavbar-nav' id="settingsNavbar">
        <ul className='settingsnavbar-items' ref={drawerRef}>
          {
            isMobile ?
              <li className='settingsnavbar-item'>
                <div className='settingsnavbar-arrowdown-container'>
                  {
                    openDrawer ?
                      <img 
                        onClick={() => toggleDrawer(!openDrawer)}
                        className='settingsnavbar-arrow-img' 
                        src='/gfx/svg/arrow-up.svg' 
                        alt='arrow-up' />
                    :
                      <img
                        onClick={() => toggleDrawer(!openDrawer)} 
                        className='settingsnavbar-arrow-img' 
                        src='/gfx/svg/arrow-down.svg' 
                        alt='arrow-down' />
                    }
                </div>
              </li>
              :
              null
          }
      
          {
            openDrawer || !isMobile ?
            <>
              {
                settingsSubpagesRoutes.map((route, index) => {
                  if (!reduxState.logged) {
                    return null
                  }

                  if (!pathsAccessrights.includes(route.path)) {
                    return null
                  }

                  return <li
                    className='settingsnavbar-item' 
                    key={index}>
                    <NavLink 
                      to={route.path}
                      onClick={() => isMobile ? toggleDrawer(!openDrawer) : null}>
                        {route.name}
                    </NavLink>
                  </li>
                })
              }
            </>
            :
            null
          }
        </ul>
      </nav>
    </div>
  );
}

export default SettingsNavbar;