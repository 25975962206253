// React
import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Services
import AppSettingsService from '../../../../services/AppSettingsService'

// Components
import ButtonPrimary from '../../../../components/Styled/ButtonPrimary'

// Types
import AppSettingsConfiguration from '../../../../settings/AppSettingsConfiguration'
import AppSettingType from '../../../../types/AppSettingType'

// Modal actions
import { showErrorModal, showSuccessModal } from "../../../../components/GlobalModal/actions"

// Css
import './index.css'
import { ServerEventNames, setServerEvent } from '../../../../redux/actions/serverEventsActions'

const AppSettingsPage = () => {
  // Redux
  const dispatch = useDispatch()
  const reduxState = useSelector(x => x)

  const [settingsState, setSettingsState] = useState({
    googleTranslator: {
      enabled: false,
      token: "",
      url: "",
    },
    notifications: {
      enabled: false,
      modalEnabled: false,
      panelEnabled: false
    }
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = useCallback(() => {
    const googleTranslator = getGoogleTranslatorAppSettings()
    const notifications = getNotificationsAppSettings()

    setSettingsState({
      ...settingsState,
      googleTranslator: {
        ...googleTranslator
      },
      notifications: {
        ...notifications
      }
    })
  }, [settingsState, reduxState.appSettings])

  const getGoogleTranslatorAppSettings = useCallback(() => {
    const googleTranslator = {
      enabled: false,
      token: "",
      url: "",
    }

    const appsettingEnabled = reduxState.appSettings.find(x => x.path === AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_ENABLE_PATH)
    googleTranslator.enabled = appsettingEnabled?.value ?? false

    const appsettingToken = reduxState.appSettings.find(x => x.path === AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_TOKEN_PATH)
    googleTranslator.token = appsettingToken?.value ?? ""

    const appsettingUrl = reduxState.appSettings.find(x => x.path === AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_URL_PATH)
    googleTranslator.url = appsettingUrl?.value ?? ""

    return googleTranslator
  }, [settingsState, reduxState.appSettings])

  const getNotificationsAppSettings = useCallback(() => {
    const notifications = {
      enabled: true,
      modalEnabled: false,
      panelEnabled: false
    }

    const appsettingEnabled = reduxState.appSettings.find(x => x.path === AppSettingsConfiguration.NOTIFICATIONS.FIELD_ENABLE_PATH)
    notifications.enabled = appsettingEnabled?.value ?? false

    const appsettingModalVisible = reduxState.appSettings.find(x => x.path === AppSettingsConfiguration.NOTIFICATIONS.FIELD_MODAL_ENABLE_PATH)
    notifications.modalEnabled = appsettingModalVisible?.value ?? false

    const appsettingPanelVisible = reduxState.appSettings.find(x => x.path === AppSettingsConfiguration.NOTIFICATIONS.FIELD_PANEL_ENABLE_PATH)
    notifications.panelEnabled = appsettingPanelVisible?.value ?? false

    return notifications
  }, [settingsState, reduxState.appSettings])

  const handleChange = useCallback((section, field, value, type = "text") => {
    if(type === "text")
    {
      setSettingsState({
            ...settingsState,
            [section]: {
                ...settingsState[section],
                [field]: value
            }
        })
    }
    else if(type === "checkbox")
    {
      setSettingsState({
          ...settingsState,
          [section]: {
              ...settingsState[section],
              [field]: !settingsState[section][field]
          }
      })
    }
  }, [settingsState])

  const handleClickSave = useCallback(async () => {
    try
    {
      await updateGoogleTranslatorAppSettings()
      await updateNotificationsAppSettings()

      const successTitle = "Zapisano dane"
      const successMessage = "Dane zostały zapisane poprawnie"
      showSuccessModal(dispatch, successTitle, successMessage)
    }
    catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
      return
    }
  }, [settingsState])

  // appsettings = [
  //   { path, type, value }
  // ] 
  const updateGoogleTranslatorAppSettings = useCallback(async () => {
    const appsettings = [
      { path: AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_ENABLE_PATH, type: AppSettingType.GLOBAL, value: settingsState.googleTranslator.enabled  },
      { path: AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_TOKEN_PATH, type: AppSettingType.GLOBAL, value: settingsState.googleTranslator.token  },
      { path: AppSettingsConfiguration.GOOGLE_TRANSLATOR.FIELD_URL_PATH, type: AppSettingType.GLOBAL, value: settingsState.googleTranslator.url  }
    ]

    console.log(appsettings)

    await AppSettingsService.updateBulk(appsettings)
  }, [settingsState])

  const updateNotificationsAppSettings = useCallback(async () => {
    const appsettings = [
      { path: AppSettingsConfiguration.NOTIFICATIONS.FIELD_ENABLE_PATH, type: AppSettingType.GLOBAL, value: settingsState.notifications.enabled  },
      { path: AppSettingsConfiguration.NOTIFICATIONS.FIELD_MODAL_ENABLE_PATH, type: AppSettingType.GLOBAL, value: settingsState.notifications.modalEnabled  },
      { path: AppSettingsConfiguration.NOTIFICATIONS.FIELD_PANEL_ENABLE_PATH, type: AppSettingType.GLOBAL, value: settingsState.notifications.panelEnabled  }
    ]

    await AppSettingsService.updateBulk(appsettings)
    dispatch(setServerEvent(ServerEventNames.REFRESH_APPSETTINGS))

  }, [settingsState])

  return <div className='appsettingspage-container'>
    {/* Tłumacz Google */}
    <div className='appsettingspage-group'>
      <h2 className='appsettingspage-group-caption'>Tłumacz Google</h2>
      <table className='appsettingspage-group-fieldstable'>
        <tr>
          <td>Aktywne</td>
          <td>
            <input
              type='checkbox'
              name='enabled'
              className='appsettingspage-group-input'
              onChange={e => handleChange("googleTranslator", e.target.name, e.target.value, 'checkbox')}
              checked={settingsState.googleTranslator.enabled}
              value={settingsState.googleTranslator.enabled}
            />
          </td>
        </tr>
        <tr>
          <td>Url</td>
          <td>
            <input 
              type='text'
              name='url'
              className='appsettingspage-group-input'
              onChange={e => handleChange("googleTranslator", e.target.name, e.target.value, 'text')}
              readOnly={settingsState.googleTranslator.enabled}
              value={settingsState.googleTranslator.url}
            />
          </td>
        </tr>
        <tr>
          <td>Token</td>
          <td>
            <input 
              type='text'
              name='token'
              className='appsettingspage-group-input' 
              readOnly={settingsState.googleTranslator.enabled}
              onChange={e => handleChange("googleTranslator", e.target.name, e.target.value, 'text')}
              value={settingsState.googleTranslator.token}
            />
          </td>
        </tr>
      </table>
    </div>
    {/* Powiadomienia */}
    <div className='appsettingspage-group'>
      <h2 className='appsettingspage-group-caption'>Powiadomienia</h2>
      <table className='appsettingspage-group-fieldstable'>
        <tr>
          <td>Aktywne</td>
          <td>
          <input 
            type='checkbox' 
            name='enabled'
            className='appsettingspage-group-input'
            onChange={e => handleChange("notifications", e.target.name, e.target.value, 'checkbox')}
            checked={settingsState.notifications.enabled}
            value={settingsState.notifications.enabled} />
          </td>
        </tr>
        <tr>
          <td>Modal</td>
          <td>
            <input 
              type='checkbox'
              name='modalEnabled'
              className='appsettingspage-group-input'
              // readOnly={settingsState.notifications.enabled}
              onChange={e => handleChange("notifications", e.target.name, e.target.value, 'checkbox')}
              checked={settingsState.notifications.modalEnabled}
              value={settingsState.notifications.modalEnabled} />
          </td>
        </tr>
        <tr>
          <td>Wyświetlanie</td>
          <td>
            <input 
              type='checkbox'
              name='panelEnabled'
              className='appsettingspage-group-input'
              // readOnly={settingsState.notifications.enabled}
              onChange={e => handleChange("notifications", e.target.name, e.target.value, 'checkbox')}
              checked={settingsState.notifications.panelEnabled}
              value={settingsState.notifications.panelEnabled} />
          </td>
        </tr>
      </table>
    </div>

    <div className='appsettingspage-group'>
      <ButtonPrimary onClick={handleClickSave}>Zapisz</ButtonPrimary>
    </div>

  </div>
}

export default AppSettingsPage