// Libraries
import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    login: Yup.string()
        .min(2, "Błedny login!")
        .required('Pole jest wymagane'),
    password: Yup.string()
        .min(2, "Błędne hasło")
        .required('Pole jest wymagane')
})
