import Api from "./base/Api"

class AccessrightsApi extends Api {
    static baseRoute = "accessrights"

    static async getAll(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, null, null, token)
    }
}

export default AccessrightsApi