import Api from "./base/Api"

class UIConfigurationApi extends Api {
    static baseRoute = "uiconfiguration"

    static async getConfiguration(token, name) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/${name}`, null, null, token)
    }

    static async saveConfiguration(token, name, configuration) {
        const headers = {
            "Content-Type": "application/json"
        }

        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}`, { name, configuration }, true, headers, token)
    }

    static async deleteConfiguration(token, name) {
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/${name}`, null, false, null, token)
    }
}

export default UIConfigurationApi