import {
    SET_USER_LOGGED,
    SET_USER_LOGGEDOUT,
    SET_USER_CHANGEPASSWORD,
    SET_INIT_LOGGED_CHECK
} from "../types/authTypes"

export const setUserLogged = (data) => {
    return {
        type: SET_USER_LOGGED,
        payload: data
    }
}

export const setUserLoggedout = (data) => {
    return {
        type: SET_USER_LOGGEDOUT
    }
}

export const setUserChangePassword = (changePassword) => {
    return {
        type: SET_USER_CHANGEPASSWORD,
        payload: changePassword
    }
}


export const setInitLoggedCheck = (value) => {
    return {
        type: SET_INIT_LOGGED_CHECK,
        payload: value
    }
}