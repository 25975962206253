import TimeTool from "../../../../../Tools/TimeTool"

const gridDefaultConfiguration = [
  {
    headerName: "Data i czas",
    field: "datetime",
    editable: false,
    resizable: true,
    valueGetter(params) {
        return TimeTool.format(params.data.datetime)
    },
    filter: 'agTextColumnFilter'
  },
  {
    headerName: "Typ",
    field: "type",
    editable: false,
    resizable: true,
    filter: 'agTextColumnFilter'
},
  {
    headerName: "Tytuł",
    field: "title",
    editable: false,
    resizable: true,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: "Opis",
    field: "description",
    editable: false,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    flex: 1
  },
]

export default gridDefaultConfiguration