import Api from "./base/Api"

class LoginApi extends Api {
    static baseRoute = "auth"

    static async login(login, password) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}/login`, { login, password }, true, headers)
    }

    static async checkIsLogged(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/checklogged`, null, null, token)
    }

    static async logout(token) {
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}/logout`, null, false, null, token)
    }

    static async getTemporaryPassword(token, userID) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/temporarypassword`, { userID }, null, token)
    }
}

export default LoginApi