// Libraries
import { useState } from "react"

// Redux
import { useDispatch } from "react-redux"
import { LocalEventNames, setLocalEvent } from "../../redux/actions/localEventsActions"

// Services
import RegistrationRedirectionsScheduleService from "../../services/RegistrationRedirectionsScheduleService"

// Types
import RegistrationRedirectionScheduleType from "../../types/RegistrationRedirectionScheduleType"

// Components
import { Button, FormItem, Input, Select, WrapForm } from "../Styled"

// GFX
import xSymbol from "./gfx/x-symbol.svg"

// Css
import "./index.css"

// Modal actions
import { showErrorModal } from "../GlobalModal/actions"

const AddRegistrationRedirectionsScheduleForm = ({ visible, close, leaderID }) => {
    const [registrationRedirectionScheduleData, setRegistrationRedirectionScheduleData] = useState({
        type: RegistrationRedirectionScheduleType.TIME,
        dateFrom: "",
        dateTo: "",
        quantity: 0
    })

    // Redux
    const dispatch = useDispatch()

    const handleChange = (e) => {
        setRegistrationRedirectionScheduleData({
            ...registrationRedirectionScheduleData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            const data = {
                ...registrationRedirectionScheduleData,
                leaderID
            }

            await RegistrationRedirectionsScheduleService.create(data)
            refreshData()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const handleClickClose = (e) => {
        if (e.target.className !== "addregistrationredirectionsscheduleform-overlay") {
            return
        }

        if (close) {
            close()
        }
    }

    const refreshData = () => {
        dispatch(setLocalEvent(LocalEventNames.REFRESH_REGISTRATION_REDIRECTION_SSCHEDULE_GRID))
    }

    return <div
        onClick={handleClickClose}
        className="addregistrationredirectionsscheduleform-overlay"
        style={{
            display: visible ? "" : "none"
        }}>
        <WrapForm>
            <img
                className="addliderform-x"
                onClick={() => close()}
                src={xSymbol}
                alt="xSymbol" />
            <form onSubmit={handleSubmit}>
                <FormItem>
                    <Select name="type"
                        onChange={handleChange}
                        value={registrationRedirectionScheduleData.type}>
                        <option value={RegistrationRedirectionScheduleType.TIME}>Czasowe</option>
                        <option value={RegistrationRedirectionScheduleType.QUANTITY}>Ilościowe</option>
                    </Select>
                </FormItem>

                {
                    registrationRedirectionScheduleData.type == RegistrationRedirectionScheduleType.TIME ?
                        <>
                            <FormItem>
                                <Input type="datetime-local"
                                    name="dateFrom"
                                    onChange={handleChange}
                                    value={registrationRedirectionScheduleData.dateFrom} />
                            </FormItem>
                            <FormItem>
                                <Input type="datetime-local"
                                    name="dateTo"
                                    onChange={handleChange}
                                    value={registrationRedirectionScheduleData.dateTo} />
                            </FormItem>
                        </>
                        :
                        null
                }

                {
                    registrationRedirectionScheduleData.type == RegistrationRedirectionScheduleType.QUANTITY ?
                        <FormItem>
                            <Input
                                type="number"
                                name="quantity"
                                onChange={handleChange}
                                value={registrationRedirectionScheduleData.quantity} />
                        </FormItem>
                        :
                        null
                }

                <FormItem>
                    <Button type="submit">Dodaj</Button>
                </FormItem>
            </form>
        </WrapForm>
    </div>
}

export default AddRegistrationRedirectionsScheduleForm