import TimeTool from "../../../../../../Tools/TimeTool"

const gridDefaultConfiguration = [
  {
    headerName: "Data",
    field: "dataczas",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    sort: 'desc',
    valueGetter(params) {
        return TimeTool.format(params.data.dataczas)
    },
    filter: 'agTextColumnFilter',
  },
  {
    headerName: "Typ",
    field: "typ",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: "Opis",
    field: "opis",
    editable: false,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    filter: 'agTextColumnFilter',
    suppressSizeToFit: true,
    minWidth: 300,
    flex: 1
  }
]

export default gridDefaultConfiguration