// React
import { useEffect, useMemo, useCallback, useState } from "react"

// Components
import { AgGridReact } from "ag-grid-react"

// Services
import LogsService from "../../../../services/LogsService"

// Configuration
import gridOptions from "./configuration/gridOptions";

// Css
import "./index.css"

const LogsSettingsPage = () => {
    const [gridHeight, setGridHeight] = useState(100)

    useEffect(() =>{
        setTimeout(() => {
          const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
          if(correctionY)
          {
              const height = ((window.innerHeight - correctionY) / (window.innerHeight)) * 100
              setGridHeight(height)
          }
        }, 1000)

        window.addEventListener('resize', e => {
            const correctionY = document.querySelector(".ag-theme-alpine")?.getBoundingClientRect().y
            if(correctionY)
            {
                const height = ((window.innerHeight - correctionY) / (window.innerHeight)) * 100
                setGridHeight(height)
            }
        })
      }, [])

    const handleGridReady = useCallback((params) => {
        const datasource = createServerSideDatasource()
        params.api.setServerSideDatasource(datasource)
    }, [])

    const createServerSideDatasource = useCallback(() => {
        return {
            getRows: async (params) => {
                const lastRow = params.api.getDisplayedRowAtIndex(params.api.getLastDisplayedRow() - 1)?.data;
                const lastId = lastRow ? lastRow.id : 0
                const count = params.request.endRow - params.request.startRow
                const data = await LogsService.getAll(lastId, count)

                params.success({
                    rowData: [...data.logs],
                });
            }
        }
    }, [])

    const getRowId = useMemo(() => {
        return params => `${params.data.id}`
    }, [])

    return <div>
        <div className="ag-theme-alpine" style={{ height: `${gridHeight}vh`, width: '100%' }}>
            <AgGridReact
                gridOptions={gridOptions}
                rowModelType={'serverSide'}
                animateRows={true}
                getRowId={getRowId}
                onGridReady={handleGridReady}
            />
        </div>
    </div>
}

export default LogsSettingsPage