// Redux
import { setUserLoggedout } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";

// Services
import LoginService from "../../services/LoginService";

// Components
import ButtonPrimary from "../Styled/ButtonPrimary";

const LogoutButton = () => {
    const dispatch = useDispatch()
    const handleClick = async () => {
        // Kolejność wywołania jest istotna!
        await LoginService.logout()
        dispatch(setUserLoggedout())
    }

    return (
        <ButtonPrimary onClick={handleClick} >Wyloguj</ButtonPrimary>
    );
}
export default LogoutButton;
