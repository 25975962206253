import Api from './base/Api'

class ImportExportXLSXApi extends Api {
    static async ImportKonsultantki(token, file, userID, liderID) {
        const querystring = `userID=${userID}&leaderID=${liderID}`

        const formData = new FormData()
        formData.append("file", file)
        return await this._post(`${Api.getBaseUrl()}/konsultantki/data/import?${querystring}`, formData, false, null, token)
    }

    static DownloadExcelKonsultatkiImportErrorsFile(token, filename) {
        window.location = `${Api.getBaseUrl()}/konsultantki/data/errorsfile?appRequest=appRequest&token=${token}&filename=${filename}`
    }

    static ExportKonsultantki(token) {
        window.location = `${Api.getBaseUrl()}/konsultantki/data/export?appRequest=appRequest&token=${token}`
    }

    static ExportKonsultantkiByIds(token, ids) {
        let querystring = ""
        for (let id of ids) {
            querystring += `konsultantkiIds[]=${id}&`
        }
        querystring = querystring.substring(0, querystring.length - 1)
        window.location = `${Api.getBaseUrl()}/konsultantki/data/export?${querystring}&token=${token}&appRequest=appRequest`
    }

    static DownloadScreenshotsByKonsultantkiIds(token, ids) {
        let querystring = ""
        for (let id of ids) {
            querystring += `konsultantkiIds[]=${id}&`
        }
        querystring = querystring.substring(0, querystring.length - 1)
        window.location = `${Api.getBaseUrl()}/konsultantki/screenshoots/download?${querystring}&token=${token}&appRequest=appRequest`
    }
}

export default ImportExportXLSXApi