// Libraries
import { useDispatch, useSelector } from "react-redux"

// Components
import NotificationsModal from "../NotificationsModal"

// Css
import "./index.css"
import { useEffect } from "react"
import store from "../../redux/store"
import { setNotificationModalVisibility } from "../../redux/actions/notificationsActions"
import { isMobile } from "react-device-detect"
import AppSettingsConfiguration from "../../settings/AppSettingsConfiguration"

// Constants
const secondsTimeoutDesktop = 10
const secondsTimeoutMobile = 5

const NotificationsModalContainer = () => {
  const reduxState = useSelector(x => x)
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      let notificationsToCheck = store.getState().notification.notifications.filter(x => x.modalVisible)
      for(let notification of notificationsToCheck)
      {
        const startDate = new Date(notification.fetchDateTime);
        const endDate = new Date();
        const seconds = (endDate.getTime() - startDate.getTime()) / 1000;

        const secondsTimeout = isMobile ? secondsTimeoutMobile : secondsTimeoutDesktop
        if(seconds > secondsTimeout)
        {
          // Trzeba zaktualizować powiadomienie
          store.dispatch(setNotificationModalVisibility(notification.id, false))
        }
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const handleClickClose = (id) => {
    dispatch(setNotificationModalVisibility(id, false))
  }

  const getNotifications = () => {
    return reduxState.notification.notifications.filter(x => x.modalVisible)
  }

  if(reduxState.appSettings.some(x => x.path === AppSettingsConfiguration.NOTIFICATIONS.FIELD_MODAL_ENABLE_PATH && !x.value)) {
    return <div className="notificationsModalContainer-container-mobile"></div>
  }

  if(isMobile)
  {
    return <div className="notificationsModalContainer-container-mobile">
      {
        getNotifications().map((x, index) => {
          return <NotificationsModal
            key={index}
            id={x.id}
            title={x.title}
            datetime={x.dateTime}
            description={x.description}
            onClickClose={handleClickClose}
          />
        })
      }
    </div>
  }

  return <div className="notificationsModalContainer-container">
    {
      getNotifications().map((x, index) => {
        return <NotificationsModal
          key={index}
          id={x.id}
          title={x.title}
          datetime={x.dateTime}
          description={x.description}
          onClickClose={handleClickClose}
        />
      })
    }
  </div>
}

export default NotificationsModalContainer
