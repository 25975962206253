// React
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

// Services
import KonsultantkiService from "../../../../../services/KonsultantkiService"

// Components
import ScreenShootPreview from "../../../ScreenShootPreview"

// Actions
import { showErrorModal } from "../../../../GlobalModal/actions"

// Css
import "./index.css"

const ScreenShootsTab = ({ konsultantka }) => {
  const [screenShoots, setScreenShoots] = useState([])
  const [currentScreenShootIndex, setCurrentScreenShootIndex] = useState(0)
  const [screenShootPreview, setScreenShootPreview] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    getData()
  }, [konsultantka])

  const getData = () => {
    getScreenShoots()
  }

  const getScreenShoots = useCallback(async () => {
    if (!konsultantka || JSON.stringify(konsultantka) === "{}") {
        return
    }

    setCurrentScreenShootIndex(0)

    try {
        const files = await KonsultantkiService.getScreenShoots(konsultantka.id)
        setScreenShoots(files)
    }
    catch (error) {
        showErrorModal(dispatch, "Błąd", error.toString())
        setScreenShoots([])
    }
  }, [konsultantka])

  const handleClickNextScreenShoot = () => {
    let index = currentScreenShootIndex
    index++
    if (index === screenShoots.length)
    {
      index = 0
    }
    setCurrentScreenShootIndex(index)
  }

  const handleClickPrevScreenShoot = () => {
    let index = currentScreenShootIndex
    index--
    if (index === -1)
        index = screenShoots.length - 1
    setCurrentScreenShootIndex(index)
  }

  const handleClickScreenShootPreview = () => {
    setScreenShootPreview(true)
}

  if(!konsultantka)
  {
    return <p>Brak danych do wyświetlenia</p>
  }

  return <>
    {
      screenShoots.length > 0 ?
          <div className="screenShootsTab-container">
              {
                screenShoots.length > 1 ?
                <div className="screenShootsTab-arrow" onClick={handleClickPrevScreenShoot} >&lt;</div>
                :
                null
              }
              <div className="screenShootsTab-image-container">
                  <img
                      src={`data:image/png;base64, ${screenShoots[currentScreenShootIndex]?.base64 ?? ""}`}
                      alt={screenShoots[currentScreenShootIndex]?.name ?? ""}
                      className="screenShootsTab-image-img"
                      onClick={handleClickScreenShootPreview}
                  />
                  <p className="screenShootsTab-caption">
                    <span className="screenShootsTab-number">Nr {currentScreenShootIndex + 1} </span>
                    - {screenShoots[currentScreenShootIndex]?.date ?? ""}
                  </p>
              </div>
              {
                screenShoots.length > 1 ?
                <div className="screenShootsTab-arrow" onClick={handleClickNextScreenShoot}>&gt;</div>
                :
                null
              }

          </div>
          :
          <p>Brak danych do wyświetlenia</p>
        }
        {
          screenShootPreview && screenShoots[currentScreenShootIndex] ?
              <ScreenShootPreview
                  src={`data:image/png;base64, ${screenShoots[currentScreenShootIndex]?.base64 ?? ""}`}
                  date={screenShoots[currentScreenShootIndex]?.date ?? ""}
                  index={currentScreenShootIndex}
                  handleClickNextScreenShoot={handleClickNextScreenShoot}
                  handleClickPrevScreenShoot={handleClickPrevScreenShoot}
                  closePreview={() => setScreenShootPreview(false)}
                  showArrows={screenShoots.length > 1} />
              : null
        }
  </>
}

export default ScreenShootsTab