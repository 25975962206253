// Libraries
import { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";

// Redux
import store from "../../redux/store"
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRows } from "../../redux/actions/navigationContextActions";
import { addKonsultantki, setKonsultantki } from "../../redux/actions/konsultantkiActions";
import { resetGlobalModal } from "../../redux/actions/globalModalActions";
import { showErrorModal, showInfoModal, showWarningModal } from "../GlobalModal/actions";
import { LocalEventNames, deleteLocalEventByUUID } from "../../redux/actions/localEventsActions";

// Services
import KonsultantkiService from "../../services/KonsultantkiService";
import UIConfigurationService from "../../services/UIConfigurationService";

// Configuration
import gridOptions from "./configuration/gridOptions"
import gridDefaultColState from './configuration/gridDefaultColState';
import { CONFIGURATIONNAME } from "./constants/configuration";

// Components
import ButtonPrimary from "../Styled/ButtonPrimary";
import ButtonSecondary from "../Styled/ButtonSecondary";

// Settings
import konsultantkiSettings from "../../settings/konsultantkiSettings"

// Types
import ModalButtonsType from "../Modal/types/modalButtonsType";

// Tools
import TimeTool from "../../Tools/TimeTool";

// Css
import './index.css'
import { ServerEventNames, setServerEvent } from "../../redux/actions/serverEventsActions";
import RandomTool from "../../Tools/RandomTool";

const KonsultantkiGrid = ({ className, style }) => {
  const gridStyle = useMemo(() => ({ height: '100vh', width: '100%' }), []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 110,
      editable: true,
      resizable: true,
    }
  }, []);

  const gridRef = useRef();
  const reduxState = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    const clearSelectedRows = () => {
      dispatch(setSelectedRows([]))
    }

    return clearSelectedRows
  }, [])

  useEffect(() => {
    if (reduxState.globalModal.result
      && reduxState.globalModal.result.key === "RESET_KONSULTANTKI_GRID_UICONFIGURATION") {
        if(reduxState.globalModal.result.data.result)
        {
            restoreDefaultColumnDefs()
        }
        else
        {
            dispatch(resetGlobalModal())
        }
    }
  }, [reduxState.globalModal.result])

  const restoreDefaultColumnDefs = useCallback(async () => {
    try {
        await UIConfigurationService.deleteConfiguration(CONFIGURATIONNAME)
        gridRef.current.columnApi.applyColumnState({
            state: gridDefaultColState,
            applyOrder: true,
        });
        dispatch(resetGlobalModal())
    }
    catch (error) {
        showErrorModal(dispatch, "Błąd", error.toString())
    }
}, [dispatch])


  const handleSelectionChanged = async (params) => {
      const selectedRowsData = getSelectedRowsData()
      dispatch(setSelectedRows(selectedRowsData))
  }

  const handleGridReady = (params) => {
    getGridConfiguration()

    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    
    // Ustawienie domyślnych filtrów na gridzie
    setDefaultGridFilters()

    // Nasłuchiwanie zmian na konsultantkach
    listenChanges()
  }

  const createServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        let state = store.getState()

        let firstId = 0
        if(params.request.startRow !== 0 && state.konsultantki && state.konsultantki.data && state.konsultantki.data.length > 0)
        {
          let konsultantkiSorted = [...state.konsultantki.data].sort((a, b) => a.id - b.id)
          firstId = konsultantkiSorted[0].id
        }

        let fetchUUID = RandomTool.getUUID()

        try {
          const fetchedKonsultantki = await KonsultantkiService.getRange(firstId, konsultantkiSettings.FETCHED_COUNT, params.request.filterModel)
          if(params.request.startRow === 0)
          {
            store.dispatch(setKonsultantki(fetchedKonsultantki, fetchUUID))
          }
          else
          {
            store.dispatch(addKonsultantki(fetchedKonsultantki, fetchUUID))
          }
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }

        let counter = 1
        while(state.konsultantki.fetchUUID !== fetchUUID && counter < 50)
        {
          state = store.getState()
          await TimeTool.delay(10)
          counter++;
        }

        params.success({
          rowData: [...state.konsultantki.data].slice(params.request.startRow, params.request.endRow)
        });
      }
    }
  }

  const setDefaultGridFilters = () => {
    let defaultFilters = {}
    if(reduxState.userData)
    {
      defaultFilters = { 
        ...defaultFilters,
        "uzytkownik.login": { values:[reduxState.userData.login], filterType:"set" }
      }
    }

    // if(reduxState.userData && reduxState.userData.lider)
    // {
    //   const leaderNameSurname = `${reduxState.userData.lider.imie} ${reduxState.userData.lider.nazwisko}` 
    //   defaultFilters = { 
    //     ...defaultFilters,
    //     "lider.imie+nazwisko": { values:[leaderNameSurname], filterType:"set" }
    //   }
    // }

    gridRef.current.api.setFilterModel(defaultFilters)
  }

  const listenChanges = () => {
    store.subscribe(() => {
      if(!gridRef || !gridRef.current)
      {
        return
      }

      const reduxState = store.getState()
      for(let event of reduxState.events)
      {
        if(event.name === LocalEventNames.GRID_KONSULTANTKI_ADD)
        {
          const filterModel = gridRef.current.api.getFilterModel(); 

          let newKonsultantki = []
          for (const konsultantka of event.data) {
            let isOk = true

            for (const key of Object.keys(filterModel)) {
              const filterData = filterModel[key]

              if (key.includes('.')) {
                const keyParts = key.split('.')
                if (keyParts[0] === 'uzytkownik') {
                  isOk = KonsultantkiService.checkIfMeetsTheAgGridFilters(konsultantka.uzytkownik[keyParts[1]], filterData)
                }

                if (keyParts[0] === 'lider') {
                  const parts = keyParts[1].split('+')
                  for (let i = 0; i < parts.length; i++) {
                    const part = parts[i]
                    const inValues = filterData.values.map(x => x.split(' ')[i])
                      isOk = isOk && inValues.some(x => x.toLowerCase() == konsultantka.lider[part].toLowerCase())
                  }
                }
              } else {
                if (filterData.conditions) {
                  switch (filterData.operator) {
                    case 'AND':
                      isOk = KonsultantkiService.checkIfMeetsTheAgGridFilters(konsultantka[key], filterData.conditions[0])
                        && KonsultantkiService.checkIfMeetsTheAgGridFilters(konsultantka[key], filterData.conditions[1])
                      break
                    case 'OR':
                      isOk = KonsultantkiService.checkIfMeetsTheAgGridFilters(konsultantka[key], filterData.conditions[0])
                        || KonsultantkiService.checkIfMeetsTheAgGridFilters(konsultantka[key], filterData.conditions[1])
                      break
                  }
                } else {
                  isOk = KonsultantkiService.checkIfMeetsTheAgGridFilters(konsultantka[key], filterData)
                }
              }
            }

            if (isOk) {
              newKonsultantki.push(konsultantka)
            }
          }

          if(newKonsultantki.length > 0) {
            const transaction = {
              addIndex: 0,
              add: newKonsultantki,
            }
  
            gridRef.current.api.applyServerSideTransaction(transaction)
          }

          store.dispatch(deleteLocalEventByUUID(event.uuid))
        }

        if(event.name === LocalEventNames.GRID_KONSULTANTKI_UPDATE)
        {
          const transaction = {
            update: event.data
          }
          gridRef.current.api.applyServerSideTransaction(transaction)
          store.dispatch(deleteLocalEventByUUID(event.uuid))
        }

        if(event.name === LocalEventNames.GRID_KONSULTANTKI_DELETE)
        {
          const removeIds = event.data.map(x => ({ id: x }))
            const transaction = {
              remove: removeIds
            }
            gridRef.current.api.applyServerSideTransaction(transaction)
            store.dispatch(deleteLocalEventByUUID(event.uuid))
        }
      }
    })
  }

  const getGridConfiguration = async () => {
    try {
        const configuration = await UIConfigurationService.getConfiguration(CONFIGURATIONNAME)
        if (configuration && JSON.stringify(configuration) !== "[]") {
            gridRef.current.columnApi.applyColumnState({
                state: configuration,
                applyOrder: true,
            });
        }
    }
    catch (error) {
        showErrorModal(dispatch, "Błąd", error.toString())
    }
}

const handleCellEditingStopped = async (event) => {
  // update konsultantki

  const { field } = event.colDef
  const yesNoFieldsNames = ["zarejestrowanoPoprawnie", "przetworzono"]

  let valid = true
  // Walidacja wprowadzonych danych
  if (field === "telefon") {
      if (isNaN(event.newValue)) {
          valid = false
      }
  }

  const allowNullOrEmptyFields = ["numerMieszkania"]
  if (!allowNullOrEmptyFields.includes(field) && !event.newValue) {
      valid = false
  }

  if (!valid) {
      const text = `Wprowadź poprawną wartość dla pola ${field}`
      event.data[field] = event.oldValue
      showErrorModal(dispatch, "Błąd", text)
      return
  }

  // obsługa wartości pola
  let newValue
  if (yesNoFieldsNames.includes(field)) {
      newValue = event.newValue === "Tak" ? true : false
  }
  else {
      newValue = event.newValue
  }

  try {
      await KonsultantkiService.update(event.data.id, field, newValue)
  }
  catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
  }
  dispatch(setServerEvent(ServerEventNames.UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK))
}

const getSelectedRowsData = () => {
  const selectedNodes = gridRef.current.api.getSelectedNodes();
  const selectedData = selectedNodes.map(node => node.data);
  return [...selectedData]
}

const handleClickSaveColumnDefs = async () => {
  try {
      const columnState = gridRef.current.columnApi.getColumnState()
      await UIConfigurationService.saveConfiguration(CONFIGURATIONNAME, columnState)
      const title = "Zapisano konfigurację"
      const message = "Zapis konfiguracji grida przebiegł pomyślnie."
      showInfoModal(dispatch, title, message)
  }
  catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
  }
}

const handleClickRestoreDefaultColumnDefs = async () => {
  const title = "Przywracanie domyślnej konfiguracji"
  const text = "Czy na pewno chcesz przywrócić domyślną konfigurację ?"
  showWarningModal(dispatch, title, text, ModalButtonsType.YESNO, "RESET_KONSULTANTKI_GRID_UICONFIGURATION")
}

  const getRowId = useMemo(() => {
    return params => `${params.data.id}`
  }, [])

  return <div id="divKonsultantkiGridContainer">
    <div className="konsultantkiGrid-buttons-container">
        <ButtonPrimary onClick={handleClickSaveColumnDefs}>Zapis konfiguracje</ButtonPrimary>
        <ButtonSecondary onClick={handleClickRestoreDefaultColumnDefs}>Domyślna konfiguracja</ButtonSecondary>
    </div>
    <div className="konsultantkiGrid-info-container">
        <p className="konsultantkiGrid-info-item">Zaznaczone wiersze: {reduxState.navigationContext.selectedRows.length}</p>
    </div>
    <div style={{...style, gridStyle}} className={"ag-theme-alpine " + className}>
        <AgGridReact
          ref={gridRef}
          gridOptions={gridOptions}
          cacheBlockSize={konsultantkiSettings.FETCHED_COUNT}
          defaultColDef={defaultColDef}
          rowModelType={'serverSide'}
          animateRows={true}
          onSelectionChanged={handleSelectionChanged}
          onCellEditingStopped={handleCellEditingStopped}
          getRowId={getRowId}
          onGridReady={handleGridReady}
        />
    </div>
  </div>
}

export default KonsultantkiGrid