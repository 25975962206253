// React
import { useEffect, useState } from "react"

// Redux
import { useDispatch } from "react-redux"

// Services
import UsersService from "../../../../services/UsersService"
import LidersService from "../../../../services/LidersService"
import ImportExportXLSXService from "../../../../services/ImportExportXLSXService"

// Components
import ButtonPrimary from "../../../../components/Styled/ButtonPrimary"

// Modal actions
import { showErrorModal, showSuccessModal, showWarningModal } from "../../../../components/GlobalModal/actions"

// Css
import "./index.css"

const IntegrationsSettingsPage = () => {
    const [state, setState] = useState({
        googleTranslator: {
            token: "",
            url: "",
            enabled: false
        },
        excelImport: {
            userID: 1,
            leaderID: 1,
            file: null
        }
    })

    const [stateData, setStateData] = useState({
        users: [],
        leaders: []
    })

    // Redux
    const dispatch = useDispatch()

    useEffect(() => {

    }, [stateData])

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            await getUsersAndLeaders()
            setDefaultLeaderAndUser()
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const getUsersAndLeaders = async () => {
        const leaders = await LidersService.getAllPlain()
        const users = await UsersService.getAll()

        setStateData({
            ...stateData,
            users: [...users],
            leaders: [...leaders]
        })
    }

    const setDefaultLeaderAndUser = () => {
        if (stateData.users.length > 0) {
            setState({
                ...state,
                excelImport: {
                    ...state.excelImport,
                    userID: stateData.users[0].id
                }
            })
        }

        if (stateData.leaders.length > 0) {
            setState({
                ...state,
                excelImport: {
                    ...state.excelImport,
                    leaderID: stateData.leaders[0].id
                }
            })
        }
    }

    const handleChange = (section, field, value, type = "text") => {
        if(type == "text")
        {
            setState({
                ...state,
                [section]: {
                    ...state[section],
                    [field]: value
                }
            })
        }
        else if(type == "checkbox")
        {
            setState({
                ...state,
                [section]: {
                    ...state[section],
                    [field]: !state[section][field]
                }
            })
        }
    }

    const handleSubmitImportKonsultantki = async (e) => {
        e.preventDefault()

        if (!state.excelImport.file) {
            const title = "Uzupełnij dane"
            const text = "Wybierz plik!"
            showWarningModal(dispatch, title, text)
            return
        }

        try {
            const result = await ImportExportXLSXService.ImportKonsultantki(state.excelImport.file, state.excelImport.userID, state.excelImport.leaderID)
            setState({
                ...state,
                excelImport: {
                    userID: 1,
                    leaderID: 1,
                    file: null
                }
            })

            if (result.errors) {
                ImportExportXLSXService.DownloadExcelKonsultatkiImportErrorsFile(result.filename)

                const title = "Import konsultantek"
                const text = "Import danych konsultantek zakończył się cześciowym powodzeniem"
                showWarningModal(dispatch, title, text)
            }
            else {
                const title = "Import konsultantek"
                const text = "Import danych konsultantek zakończył się powodzeniem"
                showSuccessModal(dispatch, title, text)
            }
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
            return
        }
    }

    return <div className="integrationssettingspage-container">
        <form onSubmit={handleSubmitImportKonsultantki}>
            <div className="integrationssettingspage-section">
                <h2 className="integrationssettingspage-section-title">Import konsultantek Excel</h2>
                <div className="integrationssettingspage-section-item">
                    <label className="integrationssettingspage-section-item-label">Użytkownik:</label>
                    <select
                        name="userID"
                        className="integrationssettingspage-section-item-input"
                        onChange={e => handleChange("excelImport", e.target.name, e.target.value)}
                        value={state.excelImport.userID}
                        style={{ width: 400 }}>
                        {
                            stateData.users.map((user, index) => {
                                return <option
                                    key={index}
                                    value={user.id}>{user.login}</option>
                            })
                        }
                    </select>
                </div>
                <div className="integrationssettingspage-section-item">
                    <label className="integrationssettingspage-section-item-label">Lider:</label>
                    <select
                        name="leaderID"
                        className="integrationssettingspage-section-item-input"
                        onChange={e => handleChange("excelImport", e.target.name, e.target.value)}
                        value={state.excelImport.leaderID}
                        style={{ width: 400 }}>
                        {
                            stateData.leaders.map((leader, index) => {
                                return <option
                                    key={index}
                                    value={leader.id}> {leader.imie} {leader.nazwisko}</option>
                            })
                        }
                    </select>
                </div>
                <div className="integrationssettingspage-section-item">
                    <label className="integrationssettingspage-section-item-label">Plik:</label>
                    <input type="file"
                        name="file"
                        onChange={e => handleChange("excelImport", e.target.name, e.target.files[0])} />
                </div>
            </div>

            <div style={{ marginTop: 30 }}>
                <ButtonPrimary type="submit">Importuj</ButtonPrimary>
            </div>
        </form>
    </div>
}

export default IntegrationsSettingsPage