import {
    SET_NOTIFICATIONS_PANEL_VISIBILITY,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATIONS,
    SET_NOTIFICATION_MODAL_VISIBILITY,
    SET_NOTIFICATIONS_IS_NEW
} from "../types/notificationsTypes"


export const setNotificationsPanelVisibility = (visibility) => {
    return {
        type: SET_NOTIFICATIONS_PANEL_VISIBILITY,
        payload: visibility
    }
}

export const setNotifications = (notifications) => {
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications
    }
}

export const addNotifications = (notifications) => {
    const newNotifications = notifications.map(x => ({ ...x, isNew: true}))
    return {
        type: ADD_NOTIFICATIONS,
        payload: newNotifications
    }
}

export const setNotificationModalVisibility = (id, visible) => {
    return {
        type: SET_NOTIFICATION_MODAL_VISIBILITY,
        payload: { id, visible }
    }
}

export const setNotificationsIsNew = (isNew) => {
    return {
        type: SET_NOTIFICATIONS_IS_NEW,
        payload: isNew
    }
}