import Api from "./base/Api";

class EventsStackApi extends Api {
    static baseRoute = "eventsstack"

    static async getAll(token, loggedDateTime, dataType, eventType) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, { loggedDateTime, dataType, eventType }, null, token)
    }
}

export default EventsStackApi