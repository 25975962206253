// Api
import ImportExportXLSXApi from "../api/ImportExportXLSXApi"

// Services
import TokenService from "./TokenService"

// Settings
import appModeSettings from "../settings/appModeSettings"

class ImportExportXLSXService {
    static async ImportKonsultantki(file, userID, liderID) {
        const res = await ImportExportXLSXApi.ImportKonsultantki(TokenService.getToken(), file, userID, liderID)
        if (!res.ok) {
            const message = `ImportExportXLSXService.ImportKonsultantki()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    static DownloadExcelKonsultatkiImportErrorsFile(filename) {
        ImportExportXLSXApi.DownloadExcelKonsultatkiImportErrorsFile(TokenService.getToken(), filename)
    }

    static ExportKonsultantki() {
        ImportExportXLSXApi.ExportKonsultantki(TokenService.getToken())
    }

    static ExportKonsultantkiByIds(ids) {
        ImportExportXLSXApi.ExportKonsultantkiByIds(TokenService.getToken(), ids)
    }
}

export default ImportExportXLSXService