// React
import { useEffect, useState } from "react";

// Components
import TabsPanel from "./TabsPanel";

// Css
import "./index.css"

const KonsultantkiPreviewPanel = ({ selectedRowsData, height }) => {
    const [selectedRow, setSelectedRow] = useState({})

    useEffect(() => {
        setSelectedRow(selectedRowsData[0])
    }, [selectedRowsData])

    if (!selectedRowsData || selectedRowsData.length === 0)
    {
        return null
    }

    return <div className="konsultantkipreviewpanel-container">
        <TabsPanel konsultantka={selectedRow} />
    </div>
}

export default KonsultantkiPreviewPanel;
