import { useCallback } from "react"

// GFX
import xSymbol from "./gfx/x-symbol.svg"

// Css
import "./index.css"

const ScreenShootPreview = ({ handleClickPrevScreenShoot, handleClickNextScreenShoot, src, date, index, closePreview, showArrows = true }) => {
    const handleClickClose = (e) => {
        if (e.target.id !== "screenschootpreviewContainer" && e.target.id !== "screenschootpreviewWrap")
        {
            return
        }
        closePreview()
    }

    const handleClickXSymbol = useCallback(() => {
        closePreview()
    }, [closePreview])

    return <div onClick={handleClickClose} id="screenschootpreviewWrap" className="screenschootpreview-wrap">
        <img 
            src={xSymbol} 
            alt="x-symbol" 
            className="screenschootpreview-xsymbol"
            onClick={handleClickXSymbol} />
        <div id="screenschootpreviewContainer" className="screenschootpreview-container">
            <div className="screenschootpreview-img-container" >

                {
                    showArrows ?
                    <div className="screenschootpreview-img-arrow" onClick={handleClickPrevScreenShoot}>&lt;</div>
                    :
                    null
                }
                <img className="screenschootpreview-img" src={src} alt="screenschootpreview" />

                {
                    showArrows ?
                    <div className="screenschootpreview-img-arrow" onClick={handleClickNextScreenShoot}>&gt;</div>
                    :
                    null
                }

            </div>
            <div className="screenschootpreview-title-container">
                <p className="screenschootpreview-title">
                    <span className="screenschootpreview-number">Nr {index + 1} </span>- {date}
                </p>
            </div>
        </div>
    </div>
}

export default ScreenShootPreview