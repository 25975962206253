// Libraries
import { useEffect } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { ServerEventNames, setServerEvent } from "../redux/actions/serverEventsActions"

const LoginProvider = ({ children }) => {
  const dispatch = useDispatch()
  const reduxState = useSelector(state => state)

  useEffect(() => {
    if(reduxState.logged) {
      onLogged()
    } else {
      onLoggedOut()
    }

  }, [reduxState.logged])

  const onLogged = () => {
    dispatch(setServerEvent(ServerEventNames.FETCH_APPSETTINGS))
  }

  const onLoggedOut = () => {

  }

  return <>{children}</>
}


export default LoginProvider