// Api
import ScreenshotsApi from "../api/ScreenshotsApi"

// Services
import TokenService from "./TokenService"

class ScreenshotsService {
    static DownloadByKonsultantkiIds(ids) {
        ScreenshotsApi.DownloadByKonsultantkiIds(TokenService.getToken(), ids)
    }
}

export default ScreenshotsService