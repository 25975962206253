import Api from "./base/Api"

class KonsultantkiApi extends Api {
    static baseRoute = "konsultantki"

    static async getAll(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, null, null, token)
    }

    static async getRange(token, lastId, count, filters, getRangeType) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, { lastId, count, filters, getRangeType }, null, token)
    }

    static async getScreenshoots(token, kosultantkaId) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/screenshoots`, { kosultantkaId }, null, token)
    }

    static async update(token, id, field, value) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._put(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, { field, value }, true, headers, token)
    }

    static async deleteMany(token, ids) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}`, { ids }, true, headers, token)
    }

    static async register(token, ids) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}/register`, { ids }, true, headers, token)
    }

    static async getEvents(token, loggedDateTime) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/events`, { loggedDateTime }, null, token)
    }
}

export default KonsultantkiApi