// React
import { useState, useEffect, useRef } from 'react'

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import LogoutButton from '../LogoutButton';
import NavLink from '../NavLink';
import NotificationsButton from '../NotificationsButton';

// Services
import RolesService from '../../services/RolesService';

// Settings
import mainNavbarRoutes from '../../settings/mainNavbarRoutes';
import pjson from "../../../package.json"

// Styled
import Navbar from "./styled/navbar"
import HamburgerButton from "./styled/hamburgerButton"

// Css
import "./index.css"

// Modal actions
import { showErrorModal } from '../GlobalModal/actions';
import AppSettingsConfiguration from '../../settings/AppSettingsConfiguration';

const MainNavbar = () => {
  const [pathsAccessrights, setPathsAccessrights] = useState([])
  const [wait, setWait] = useState()
  const [openDrawer, toggleDrawer] = useState(false);
  const drawerRef = useRef(null);

  const reduxState = useSelector(state => state)
  const dispatch = useDispatch()

  useEffect(() => {
    const closeDrawer = event => {
      if (drawerRef.current && drawerRef.current.contains(event.target) && !event.target.classList.contains("nav-link"))
      {
        return;
      }
      toggleDrawer(false)
    }

    document.addEventListener("mousedown", closeDrawer);
    return () => document.removeEventListener("mousedown", closeDrawer);
  }, [])

  useEffect(() => {
    getUserRoleAccessrights()
  }, [reduxState.userData])

  const getUserRoleAccessrights = async () => {
    setWait(true)
    try {
      const accessRights = await RolesService.getUserRolePathsAccessrights()
      const paths = accessRights.map(ar => ar.nazwa)
      setPathsAccessrights(paths)
    }
    catch (error) {
      showErrorModal(dispatch, "Błąd", error.toString())
    }
    setWait(false)
  }

  if (wait) {
    return null
  }

  return (
    <div className="mainNavbar-wrapper">
      <Navbar.Wrapper>
        <Navbar.LogoContainer>
          <Navbar.Logo>Konsultantki {pjson.version}</Navbar.Logo>
          {/* {
            localStorage.getItem(appModeSettings.APP_MODE) === appModeSettings.DEVELOPE ?
              <Navbar.AppModeInfo>Tryb aplikacji: {localStorage.getItem(appModeSettings.APP_MODE)}</Navbar.AppModeInfo>
              :
              null
          } */}
        </Navbar.LogoContainer>
        <HamburgerButton.Wrapper onClick={() => toggleDrawer(true)}>
          <HamburgerButton.Lines />
        </HamburgerButton.Wrapper>

        <Navbar.Items ref={drawerRef} openDrawer={openDrawer}>
          {
            reduxState.logged ?
              <>
                <Navbar.Item>
                  <Navbar.UserInfo>Witaj {reduxState.userData.login}!</Navbar.UserInfo>
                </Navbar.Item>
              </>
              :
              null
          }
          {
            mainNavbarRoutes.map((pageInfo, index) => {
              if (!reduxState.logged && pageInfo.loggedd) {
                return null
              }

              if (!pathsAccessrights.includes(pageInfo.link)) {
                return null
              }

              return <Navbar.Item key={index} >
                <NavLink to={pageInfo.link} >{pageInfo.text}</NavLink>
              </Navbar.Item>
            })
          }
          {
            reduxState.logged ?
              <>
                {
                  reduxState.appSettings.some(x => x.path === AppSettingsConfiguration.NOTIFICATIONS.FIELD_PANEL_ENABLE_PATH && x.value) ?
                    <Navbar.Item>
                      <NotificationsButton />
                    </Navbar.Item>
                    :
                    null
                }
                
                <Navbar.Item>
                  <LogoutButton />
                </Navbar.Item>
              </>
              :
              null
          }
        </Navbar.Items>
      </Navbar.Wrapper>
    </div>
  );
}

export default MainNavbar;