// React
import { useState } from 'react';

// Libraries
import { Formik } from 'formik'

// Redux
import { useDispatch } from 'react-redux';
import { setUserLogged } from '../../redux/actions/authActions'

// Types
import AlertType from '../../components/Alert/types/alertTypes';

// Components
import Alert from '../../components/Alert';
import { FormCaption, WrapForm, Form, FormItem, Button, Input } from '../../components/Styled'

// Services
import LoginService from '../../services/LoginService';

// Validation
import { loginValidationSchema } from "./validation/validation"

// Settings
import pjson from "../../../package.json"
import appModeSettings from '../../settings/appModeSettings';

// Css
import "./index.css"
import { showErrorModal } from '../../components/GlobalModal/actions';
import { redirect, useNavigate } from 'react-router-dom';

const LoginPage = (props) => {
    const navigate = useNavigate()

    const [userCretentials, setuserCretentials] = useState({
        login: "",
        password: ""
    })

    const dispatch = useDispatch()

    const handleSubmitForm = async (values, actions) => {
        try {
            const { login, password } = values
            const result = await LoginService.login(login, password)
            if (!result.success) {
                actions.setErrors({ login: result.message })
                return;
            }

            // Użytrkownik został zalogowany
            dispatch(setUserLogged(result.data))
            if (result.data.changePassword) {
                navigate("/zmianahasla")
            }
            else {
                navigate("/konsultantki")
            }
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }


    return (
        <div>
            <WrapForm>
                <div>
                    <FormCaption>Logowanie</FormCaption>
                    <Formik
                        initialValues={{ ...userCretentials }}
                        onSubmit={handleSubmitForm}
                        validationSchema={loginValidationSchema}
                    >{({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormItem>
                                <Input
                                    type="text"
                                    name="login"
                                    placeholder="login"
                                    onChange={handleChange}
                                    value={values.login}
                                />
                            </FormItem>
                            <FormItem>
                                <Input
                                    type="password"
                                    name="password"
                                    placeholder="hasło"
                                    onChange={handleChange}
                                    value={values.password}
                                />
                            </FormItem>
                            <FormItem>
                                <Button>Zaloguj</Button>
                            </FormItem>
                            {
                                errors.login ?
                                    <div className="loginPage-alert-container">
                                        <Alert
                                            type={AlertType.DANGER}
                                            title="Błąd logowania"
                                            text={errors.login}
                                        />
                                    </div>
                                    :
                                    null
                            }
                        </Form>
                    )}
                    </Formik>
                </div>
                <div className="loginPage-version-container">
                    <p>Wersja: {pjson.version}</p>
                    {
                        localStorage.getItem(appModeSettings.APP_MODE) === appModeSettings.DEVELOPE ?
                            <p style={{ fontWeight: "bold", color: "red" }}>Tryb aplikacji: {localStorage.getItem(appModeSettings.APP_MODE)}</p>
                            :
                            null
                    }
                </div>
            </WrapForm>
        </div>

    );
}

export default LoginPage;