// Tools
import KonsultantkiTool from "../../../../../Tools/KonsultantkiTool"
import TimeTool from "../../../../../Tools/TimeTool"

// Css
import "./index.css"

const KonsultantkaDataTab = ({ konsultantka }) => {
  if(!konsultantka)
  {
    return <p>Brak danych do wyświetlenia</p>
  }

  return <div className="konsultantkaDataTab-container">
    <table className="konsultantkaDataTab-table">
        <tr>
            <td>Id</td>
            <td>{konsultantka.id}</td>
        </tr>
        <tr>
            <td>Imię</td>
            <td>{konsultantka.imie}</td>
        </tr>
        <tr>
            <td>Nazwisko</td>
            <td>{konsultantka.nazwisko}</td>
        </tr>
        <tr>
            <td>Email</td>
            <td>{konsultantka.email}</td>
        </tr>
        <tr>
            <td>Telefon</td>
            <td>{konsultantka.telefon}</td>
        </tr>
        <tr>
            <td>Ulica</td>
            <td>{konsultantka.ulica}</td>
        </tr>
        <tr>
            <td>Nr domu</td>
            <td>{konsultantka.numerDomu}</td>
        </tr>
        <tr>
            <td>Nr mieszkania</td>
            <td>{konsultantka.numerMieszkania}</td>
        </tr>
        <tr>
            <td>Miasto</td>
            <td>{konsultantka.miasto}</td>
        </tr>
        <tr>
            <td>Kod pocztowy</td>
            <td>{konsultantka.kodPocztowy}</td>
        </tr>
        <tr>
            <td>Zarejestrowano poprawnie</td>
            <td>{konsultantka.zarejestrowanoPoprawnie ? "Tak" : "Nie"}</td>
        </tr>
        <tr>
            <td>Przetworzono</td>
            <td>{konsultantka.przetworzono ? "Tak" : "Nie"}</td>
        </tr>
        <tr>
            <td>Użytkownik</td>
            <td>{konsultantka.uzytkownik ? konsultantka.uzytkownik.login : ""}</td>
        </tr>
        <tr>
            <td>Lider</td>
            <td>{konsultantka.lider ? `${konsultantka.lider.imie} ${konsultantka.lider.nazwisko}` : ""}</td>
        </tr>
        <tr>
            <td>Data rejestracji</td>
            <td>{TimeTool.format(konsultantka.data)}</td>
        </tr>
        <tr>
            <td>Url</td>
            <td>{konsultantka.url}</td>
        </tr>
        <tr>
            <td>Ip</td>
            <td>{konsultantka.ip}</td>
        </tr>
        <tr>
            <td>Url źródłowy</td>
            <td>{konsultantka.urlReferrer}</td>
        </tr>
        <tr>
            <td>Źródło rejestracji</td>
            <td>{KonsultantkiTool.getRegisterSourceFormated(konsultantka.zrodloRejestracji)}</td>
        </tr>
    </table>
    </div>
}

export default KonsultantkaDataTab