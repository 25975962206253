// Libraries
import * as Yup from 'yup';

export const setPasswordValidationSchema = Yup.object().shape({
    password: Yup.string()
        .required("Pole jest wymagane")
        .min(11, "Zbyt krótkie hasło")
        .matches(/(?=.*[A-Z])/, "Hasło powinno zawierać dużą literę, cyfrę i znak specjalny")
        .matches(/(?=.*[0-9])/, "Hasło powinno zawierać dużą literę, cyfrę i znak specjalny")
        .matches(/(?=.*[!@#$%^&*])/, "Hasło powinno zawierać dużą literę, cyfrę i znak specjalny"),
    reppassword: Yup.string()
        .required("Pole jest wymagane")
        .min(11, "Zbyt krótkie hasło")
})