import Api from "./base/Api"

class RegisterSettingsApi extends Api {
    static baseRoute = "registersettings"

    static async getSubliders(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/subliders`, null, null, token)
    }

    static async getUserPages(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/userpages`, null, null, token)
    }

    static async getRedirections(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/redirections`, null, null, token)
    }

    static async setRedirection(token, liderID) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}/redirections`, { liderID }, true, headers, token)
    }
}

export default RegisterSettingsApi