// libraries
import styled from "styled-components";

const ButtonDark = styled.button`
    background-color: #2E2E2E;
    color: white;
    font-size: 1.5rem;
    padding: 10px;
    border: 2px solid #2E2E2E;
    border-radius: 8%;
    cursor: pointer;
    &:hover {        
        background-color: #212121;
        cursor:pointer;
    }
`

export default ButtonDark