// Libraries
import styled from "styled-components";

const Navbar = {
  Wrapper: styled.nav`
      flex: 1;
      align-self: flex-start;
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      position: fixed;
      z-index: 100;
      width: 100vw;
      top: 0;
    `,
  LogoContainer: styled.div`
      display: flex;
      flexDirection: row;
    `,
  Logo: styled.h1`
      font-size: 1em;
      border: 1px solid gray;
      padding: 0.5rem 1rem;
    `,
  UserInfo: styled.h2`
      font-size: 1.3em;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
    `,
  VersionInfo: styled.p`
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
    `,
  AppModeInfo: styled.p`
      font-size: 0.8em;
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
    `,
  Items: styled.ul`
      align-items: center;
      display: flex;
      list-style: none;
  
      @media only screen and (max-width: 768px) {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        flex-direction: column;
        background-color: white;
        padding: 1rem 2rem;
        transition: 0.2s ease-out;
        transform: ${({ openDrawer }) =>
      openDrawer ? `translateX(0)` : `translateX(100%)`};
      }
    `,
  Item: styled.li`
      padding: 0 1rem;
      cursor: pointer;
  
      @media only screen and (max-width: 768px) {
        padding: 1rem 0;
      }
    `
};

export default Navbar