// Libraries
import { useEffect } from "react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { ServerEventNames, deleteServerEventByName } from "../redux/actions/serverEventsActions"
import { deleteKonsultantki, updateKonsultantki } from "../redux/actions/konsultantkiActions"

// Services
import EventsStackService from "../services/EventsStackService"

// Types
import { showErrorModal } from "../components/GlobalModal/actions"
import { setAppSettings, updateAppSettings } from "../redux/actions/appSettingsActions"
import AppSettingsService from "../services/AppSettingsService"

const ServerEventsProvider = ({ children }) => {
    const dispatch = useDispatch()
    const reduxState = useSelector(state => state)

    useEffect(() => {
        // Main function
        const invokeEvents = async () => {
            //
            // UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK
            // Pobieranie nowo zarejestrowanych konsultantek oraz zaktualizowanych i aktualizacja dany lokalnych aplikacji
            //
            if (reduxState.serverEvents.some(x => x.name === ServerEventNames.UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK)) {
                await updateKonsultantkiBasedOnEventsStack()
                dispatch(deleteServerEventByName(ServerEventNames.UPDATE_KONSULTANTKI_BASED_ON_EVENTS_STACK))
            }

            //
            // FETCH_APPSETTINGS
            // Pobieranie startowych ustawień aplikacji
            //
            if(reduxState.serverEvents.some(x => x.name === ServerEventNames.FETCH_APPSETTINGS)) {
                await fetchAppsettings()
                dispatch(deleteServerEventByName(ServerEventNames.FETCH_APPSETTINGS))
            }

            //
            // REFRESH_APPSETTINGS
            // Pobiera wszystkie ustawienia z serwera i naspisuje lokalne
            //
            if(reduxState.serverEvents.some(x => x.name === ServerEventNames.REFRESH_APPSETTINGS)) {
                await refreshAppsettings()
                dispatch(deleteServerEventByName(ServerEventNames.REFRESH_APPSETTINGS))
            }

            //
            // UPDATE_APPSETTINGS_BASED_ON_EVENTS_STACK
            // Pobieranie zaktualizowanych ustawień aplikacji i aktualizacja dany lokalnych aplikacji
            //
            if (reduxState.serverEvents.some(x => x.name === ServerEventNames.UPDATE_APPSETTINGS_BASED_ON_EVENTS_STACK)) {
                await updateAppsettingsBasedOnEventsStack()
                dispatch(deleteServerEventByName(ServerEventNames.UPDATE_APPSETTINGS_BASED_ON_EVENTS_STACK))
            }
        }

        // Invoke
        invokeEvents()
    }, [reduxState.serverEvents])

    const updateKonsultantkiBasedOnEventsStack = async () =>
    {
        try
        {
            const events = await EventsStackService.getAll(reduxState.loggedDateTime, EventsStackService.DataTypes.KONSULTANTKA, null)
            for(const event of events)
            {
                if(event.dataType === EventsStackService.DataTypes.KONSULTANTKA && event.eventType === EventsStackService.EventType.DELETE)
                {
                    dispatch(deleteKonsultantki(event.data))
                }
    
                if(event.dataType === EventsStackService.DataTypes.KONSULTANTKA && event.eventType === EventsStackService.EventType.UPDATE)
                {
                    dispatch(updateKonsultantki(event.data))
                }
            }
        }
        catch(error)
        {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const fetchAppsettings = async () => {
        try
        {
            const appsettings = await AppSettingsService.getAll()
            dispatch(setAppSettings(appsettings))
        }
        catch(error)
        {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const refreshAppsettings = async () => {
        try
        {
            const appsettings = await AppSettingsService.getAll()
            dispatch(setAppSettings(appsettings))
            dispatch(updateAppSettings(appsettings))
        }
        catch(error)
        {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    const updateAppsettingsBasedOnEventsStack = async () => {
        try
        {
            const events = await EventsStackService.getAll(reduxState.loggedDateTime, EventsStackService.DataTypes.APPSETTING, null)
            for(const event of events)
            {
                if(event.dataType === EventsStackService.DataTypes.KONSULTANTKA && event.eventType === EventsStackService.EventType.UPDATE)
                {
                    dispatch(updateAppSettings(event.data))
                }
            }
        }
        catch(error)
        {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    return <>{children}</>
}

export default ServerEventsProvider