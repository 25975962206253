import Api from "./base/Api"

class RegistrationRedirectionsScheduleApi extends Api {
    static baseRoute = "registrationredirectionsschedule"

    static async getRedirectionScheduleByLeaderID(token, leaderID) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, { leaderID }, null, token)
    }

    static async getRedirectionScheduleByUser(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/user`, null, null, token)
    }

    static async createRedirectionSchedule(token, registrationRedirectionScheduleData) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}`, { registrationRedirectionScheduleData }, true, headers, token)
    }

    static async deleteRedirectionSchedule(token, id) {
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/one/${id}`, null, false, null, token)
    }
    
    static async deleteManyRedirectionSchedule(token, ids) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/many`, { ids }, true, headers, token)
    }
}

export default RegistrationRedirectionsScheduleApi