import Api from "./base/Api"

class NotificationsApi extends Api {
    static baseRoute = "notifications"

    static async getPermitted(token, lastId, count) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/permitted`, { lastId, count }, null, token)
    }

    static async remove(token, id) {
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, null, null, token)
    }
}

export default NotificationsApi