
// React
import { useState } from "react"

// Tabs
import KonsultantkaDataTab from "./Tabs/KonsultantkaDataTab"

// Css
import "./index.css"
import KonsultantkaHistoryTab from "./Tabs/KonsultantkaHistoryTab"
import KonsultantkaRegisterLogsTab from "./Tabs/KonsultantkaRegisterLogsTab"
import KonsultantkaLogsTab from "./Tabs/KonsultantkaLogsTab"
import ScreenShootsTab from "./Tabs/ScreenShootsTab"

const tabsData = [
  { name: "KonsultantkaDataTab", caption: "Dane konsultantki", component: KonsultantkaDataTab },
  { name: "KonsultantkaRegisterLogsTab", caption: "Logi rejestracji", component: KonsultantkaRegisterLogsTab },
  { name: "KonsultantkaHistoryTab", caption: "Historia zmian konsultantki", component: KonsultantkaHistoryTab },
  { name: "KonsultantkaLogsTab", caption: "Logi konsultantki", component: KonsultantkaLogsTab },
  { name: "ScreenShootsTab", caption: "Screen shooty", component: ScreenShootsTab },
]

const TabsPanel = ({ konsultantka }) => {
  const [selectedTabName, setSelectedTabName] = useState("KonsultantkaDataTab")

  if(!konsultantka)
  {
    return null
  }

  return <>
    <div className="tabspanel-nav">
      {
        tabsData.map((x, index) => {
          if(x.name === selectedTabName)
          {
            return <div onClick={() => setSelectedTabName(x.name)} className="tabspanel-nav-item tabspanel-nav-item-selected">{x.caption}</div>
          }
          return <div onClick={() => setSelectedTabName(x.name)} className="tabspanel-nav-item">{x.caption}</div>
        })
      }
    </div>
    <div className="tabspanel-content">
      {
        tabsData.map((x, index) => {
          if(x.name === selectedTabName)
          {
            return <x.component konsultantka={konsultantka} />
          }
          return null
        })
      }
    </div>
  </>
}

export default TabsPanel