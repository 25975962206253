// Services
import UsersService from "../../../services/UsersService";
import LidersService from "../../../services/LidersService";

// Types
import RegisterSourceType from "../../../types/RegisterSourceType"
import KonsultantkiTool from "../../../Tools/KonsultantkiTool";

const gridDefaultConfiguration = [
    {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressSorting: true,
        maxWidth: 80
    },
    {
        headerName: "Imie",
        field: "imie",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.imie;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.imie = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Nazwisko",
        field: "nazwisko",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.nazwisko;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.nazwisko = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Email",
        field: "email",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.email;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.email = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Telefon",
        field: "telefon",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.telefon;
        },
        valueSetter(params) {
            if (params.newValue && !isNaN(params.newValue)) {
                params.data.telefon = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Ulica",
        field: "ulica",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.ulica;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.ulica = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "NrDomu",
        field: "numerDomu",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.numerDomu;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.numerDomu = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "NrMieszkania",
        field: "numerMieszkania",
        filter: "agTextColumnFilter",
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Miasto",
        field: "miasto",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.miasto;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.miasto = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Kod pocztowy",
        field: "kodPocztowy",
        filter: "agTextColumnFilter",
        valueGetter(params) {
            return params.data.kodPocztowy;
        },
        valueSetter(params) {
            if (params.newValue) {
                params.data.kodPocztowy = params.newValue
                return true
            }
            return false
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Zarejestrowano poprawnie",
        field: "zarejestrowanoPoprawnie",
        filter: "agSetColumnFilter",
        editable: true,
        cellClassRules: {
            'rag-green': 'x == "Tak" || x == "Tak !!!"',
            'rag-red': 'x == "Nie" || x == "Nie !!!"'
        },
        valueGetter(params) {
            let result = "Nie"
            if (params.data && params.data.zarejestrowanoPoprawnie) {
                result = "Tak"
            }

            if (params.data && params.data.logs && params.data.logs.length > 0) {
                const logDefsNames = ["KonsultantkaDuplicate", "KonsultantkaOverwrite"]
                const logs = params.data.logs.filter(x => logDefsNames.includes(x.logDefinition.name))

                if(logs.length > 0)
                {
                    result += " !!!"
                }
            }
            return result
        },
        cellEditorSelector: (params) => {
            return {
                component: "agRichSelectCellEditor",
                params: {
                    values: ["Tak", "Nie"],
                },
                popup: true,
            };
        },
        filterParams: {
            values: (params) => {
                params.success(["Tak", "Nie"])
            },
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Przetworzono",
        field: "przetworzono",
        filter: "agSetColumnFilter",
        editable: true,
        cellClassRules: {
            'rag-orange': 'x == "Tak"',
            'rag-white': 'x == "Nie"',
        },
        valueGetter(params) {
            if (params.data && params.data.przetworzono) {
                return "Tak"
            }
            return "Nie"
        },
        cellEditorSelector: (params) => {
            return {
                component: "agRichSelectCellEditor",
                params: {
                    values: ["Tak", "Nie"],
                },
                popup: true,
            };
        },
        filterParams: {
            values: (params) => {
                params.success(["Tak", "Nie"])
            },
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Użytkownik",
        field: "uzytkownik.login",
        filter: "agSetColumnFilter",
        editable: false,
        filterParams: {
            values: async (params) => {
                const users = await UsersService.getAllPermited()
                const userLogins = users.map(x => x.login)
                params.success(userLogins)
            },
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Lider", 
        field: "lider.imie+nazwisko", 
        editable: false, 
        valueGetter(params) {
            if (params.data && params.data.lider) {
                return `${params.data.lider.imie} ${params.data.lider.nazwisko}`
            }
            return ""
        },
        filter: "agSetColumnFilter",
        filterParams: {
            values: async (params) => {
                const liders = await LidersService.getAllPermited()
                const lidersNamesSurnames = liders.map(x => `${x.imie} ${x.nazwisko}`)
                params.success(lidersNamesSurnames)
            },
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Data rejestracji",
        field: "data",
        editable: false,
        filter: "agTextColumnFilter",
        valueGetter(params) {
            if (params.data && params.data.data) {
                const dateTimeSplited = params.data.data.split("T")
                const date = dateTimeSplited[0]
                const time = dateTimeSplited[1].split(".")[0]
                return `${date} ${time}`
            }
            return ""
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Url",
        field: "url",
        filter: "agTextColumnFilter",
        editable: false,
        valueGetter(params) {

            if (params.data && params.data.url) {
                const urlSplited = params.data.url.split("://")
                if (urlSplited.length > 0) {
                    return urlSplited[1]
                }
                return params.data.url
            }
            return ""
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Ip",
        field: "ip",
        filter: "agTextColumnFilter",
        editable: false,
        valueGetter(params) {
            if (params.data && params.data.ip) {
                return params.data.ip
            }
            return ""
        },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
    {
        headerName: "Źródło rejestracji",
        field: "zrodloRejestracji",
        filter: "agTextColumnFilter",
        editable: false,
        valueGetter(params) {
            if (params.data && params.data.zrodloRejestracji) {
                return KonsultantkiTool.getRegisterSourceFormated(params.data.zrodloRejestracji)
            }
            return ""
        },
        filterParams: {
            values: async (params) => {
                const usersData = await LidersService.getAll()
                const userLogins = usersData.map(x => `${x.imie} ${x.nazwisko}`)
                params.success([
                    RegisterSourceType.ANOTHER,
                    RegisterSourceType.FACEBOOK,
                    RegisterSourceType.GOOGLEADS
                ])
            },
          },
        menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
        minWidth: 50,
        flex: 1
    },
]

export default gridDefaultConfiguration