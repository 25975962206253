// Libraries
import { Minus, Plus } from "react-feather";

// Components
import ButtonDanger from "../Styled/ButtonDanger"
import ButtonDark from "../Styled/ButtonDark"
import ButtonPrimary from "../Styled/ButtonPrimary"
import ButtonSecondary from "../Styled/ButtonSecondary"

// Css
import "./index.css"

const SublidersGrid = ({ mainLeaderData, userUIAccessrights, onClickActivateConstRedirection, onClickDeleteLeader, onClickEditLeader, onClickAddRegistrationRedirectionsSchedule, onClickOpenTreeLevel, ...props }) => {

  const generateSublidersGrid = (leaderData = null, mLeft = 20, key = 0) => {
      if (leaderData == null)
      {
          return
      }

      return <div key={key}>
          <div className="sublidersgrid-row"
              style={{
                  backgroundColor: leaderData.przekierowaniaRejestracji.some(pr => pr.odAktualnegoUzytkownia) ? "green" : ""
              }}>
              <div
                  className="sublidersgrid-row-icon-container"
                  onClick={() => onClickOpenTreeLevel(leaderData)}>
                  {
                      leaderData.canTreeOpen ?
                          <>
                              {
                                  leaderData.treeOpened ?
                                      <Minus
                                          size={25}
                                          cursor="pointer" />
                                      :
                                      <Plus
                                          size={25}
                                          cursor="pointer" />
                              }
                          </>
                          :
                          null
                  }
              </div>
              <div className="sublidersgrid-row-item" style={{flex: 1}}>
                  {
                      leaderData.przekierowaniaRejestracji.some(pr => pr.odAktualnegoUzytkownia) ?
                          "Tak"
                          :
                          "Nie"
                  }
              </div>
              <div className="sublidersgrid-row-item" style={{flex: 2}}>{leaderData.imie} {leaderData.nazwisko}</div>
              <div className="sublidersgrid-row-item" style={{flex: 1}}>{leaderData.nrPersonalny}</div>
              <div className="sublidersgrid-row-item" style={{flex: 4}}>{leaderData.link}</div>
              <div className="sublidersgrid-row-item" style={{flex: 6}}>
                  {
                      leaderData.przekierowaniaRejestracji.some(pr => pr.odAktualnegoUzytkownia) ?
                          <>
                              {
                                  leaderData.przekierowaniaRejestracji.length > 0 ?
                                      <>
                                          {
                                              leaderData.przekierowaniaRejestracji.map((pr, index) => {
                                                  return <p style={{ fontWeight:"bold" }}  key={index}>{pr.login}</p>
                                              })
                                          }
                                      </>
                                      :
                                      null
                              }
                          </>
                          :
                          <div className="sublidersgrid-row-item-buttons-container">
                          {
                              userUIAccessrights.buttonAddregistrationredirectionsschedule ?
                                  <ButtonDark onClick={() => onClickActivateConstRedirection(leaderData.id)}>Aktywuj stałe</ButtonDark>
                                  :
                                  null
                          }
                          {
                              userUIAccessrights.buttonDeleteLeader ?
                                  <ButtonDanger onClick={() => onClickDeleteLeader(leaderData.id)}>Usuń</ButtonDanger>
                                  :
                                  null
                          }
                          {
                              userUIAccessrights.buttonEditLeader ?
                                  <ButtonSecondary onClick={() => onClickEditLeader(leaderData.id)} >Edytuj</ButtonSecondary>
                                  :
                                  null
                          }
                          {
                              userUIAccessrights.buttonAddregistrationredirectionsschedule ?
                                  <ButtonPrimary onClick={() => onClickAddRegistrationRedirectionsSchedule(leaderData.id)}>Dodaj harmonogram</ButtonPrimary>
                                  :
                                  null
                          }
                          </div>
                  }
              </div>
          </div>
          {
              leaderData.treeOpened ?
                  <>
                      {
                          leaderData.subLiders != null ?
                              <div style={{ margin: 10, marginLeft: mLeft }}>
                                  {leaderData.subLiders.map((leader, i) => {
                                      return generateSublidersGrid(leader, mLeft + 20, i)
                                  })}
                              </div>
                              :
                              null
                      }
                  </>
                  :
                  null
          }
      </div>
  }

  return <div className="sublidersgrid-container" {...props}>
    {generateSublidersGrid(mainLeaderData)}
  </div>
}

export default SublidersGrid