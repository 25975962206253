import TimeTool from "../../../Tools/TimeTool"

const gridDefaultConfiguration = [
  {
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    filter: false,
    suppressSizeToFit: true,
    suppressMenu: true,
    suppressSorting: true,
    maxWidth: 80
  },
  { 
    headerName: "Typ",
    field: "type",
    filter: true 
  },
  { 
    headerName: "Data od",
    field: "dateFrom",
    valueGetter(params) {
      if (params.data && params.data.dateFrom) {
          return TimeTool.format(params.data.dateFrom)
      }
      else {
          return ""
      }
  },
    filter: true
  },
  {
    headerName: "Data do",
    field: "dateTo",
    valueGetter(params) {
        if (params.data && params.data.dateTo) {
            return TimeTool.format(params.data.dateTo)
        }
        else {
            return ""
        }
    },
    filter: true
  },
  { 
    headerName: "Ilość",
    field: "quantity",
    filter: true
  },
  {
      headerName: "Ilość pozostała",
      field: "leftQuantity",
      filter: true
  },
  {
    headerName: "Lider",
    field: "lider",
    valueGetter(params) {
      if (params.data?.przekierowanieRejestracji?.lider) {
          return `${params.data.przekierowanieRejestracji.lider.imie} ${params.data.przekierowanieRejestracji.lider.nazwisko}`
      }
      else {
          return ""
      }
  },
    filter: true
  },
  {
    headerName: "Aktywny",
    field: "active",
    valueGetter(params) {
      if (params.data && params.data.active) {
          return "Tak"
      }
      else {
          return "Nie"
      }
  },
    filter: true
  }
]

export default gridDefaultConfiguration