import Api from "./base/Api"

class AppSettingsApi extends Api {
  static baseRoute = "appsettings"

  static async getOne(token, path) {
    return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/one`, { path }, null, token)
  }

  // query = { path, group, field }
  static async getMany(token, query = null) {
    if(!query) {
      query = {}
    }
    return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/many`, query, null, token)
  }

  static async update(token, path, type, value) {
    const headers = {
        "Content-Type": "application/json"
    }
    return await this._put(`${this.getBaseUrl()}/${this.baseRoute}`, { path, type, value }, true, headers, token)
  }

  // appsettings = [
  //   { path, type, value }
  // ] 
  static async updateBulk(token, appsettings) {
    const headers = {
        "Content-Type": "application/json"
    }
    return await this._put(`${this.getBaseUrl()}/${this.baseRoute}/bulk`, { appsettings }, true, headers, token)
  }
}

export default AppSettingsApi