import Api from "./base/Api"

class UsersApi extends Api {
    static baseRoute = "users"

    static async getUsersData(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/permitted`, null, null, token)
    }

    static async getAllUsersData(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, null, null, token)
    }

    static async getById(token, userId) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/one/${userId}`, null, null, token)
    }

    static async update(userId, token, userData) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._put(`${this.getBaseUrl()}/${this.baseRoute}/${userId}`, { userData }, true, headers, token)
    }

    static async updateCurrent(token, userData) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._put(`${this.getBaseUrl()}/${this.baseRoute}`, { userData }, true, headers, token)
    }

    static async deleteUser(token, id) {
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, null, false, null, token)
    }

    static async checkExists(token, login) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}/exists`, { login }, true, headers, token)
    }
}

export default UsersApi