import Api from "./base/Api"

class ScreenshotsApi extends Api {
    static baseRoute = "konsultantki/screenshoots"

    static DownloadByKonsultantkiIds(token, ids) {
        let querystring = ""
        for (let id of ids) {
            querystring += `konsultantkiIds[]=${id}&`
        }
        querystring = querystring.substring(0, querystring.length - 1)
        window.location = `${ScreenshotsApi.getBaseUrl()}/${ScreenshotsApi.baseRoute}/download?${querystring}&token=${token}&appRequest=appRequest`
    }
}

export default ScreenshotsApi