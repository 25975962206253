// Api
import EventsStackApi from "../api/EventsStackApi"
import TokenService from "./TokenService"

class EventsStackService {
    static EventType = {
        NEW: "NEW",
        ADD: "ADD",
        UPDATE: "UPDATE",
        DELETE: "DELETE"
    }

    static DataTypes = {
        KONSULTANTKA: "KONSULTANTKA",
        NOTIFICATION: "NOTIFICATION",
        APPSETTING: "APPSETTING"
    }

    static async getAll(loggedDateTime, dataType, eventType) {
        const res = await EventsStackApi.getAll(TokenService.getToken(), loggedDateTime, dataType, eventType)
        if (!res.ok) {
            const message = `EventsStackService.getEvents()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.events
    }
}

export default EventsStackService