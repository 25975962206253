import {
    SHOW_GLOBALMODAL,
    HIDE_GLOBALMODAL,
    SET_RESULT_GLOBALMODAL,
    RESET_GLOBALMODAL
} from "../types/globalModalTypes"

// options
// {
//     key: "",
//     open: false,
//     type:"",
//     title:"",
//     text:"",
//     buttons: ""
//     blockClosing: false
// }


export const showGlobalModal = (options) => {
    return {
        type: SHOW_GLOBALMODAL,
        payload: options
    }
}

export const hideGlobalModal = (options) => {
    return {
        type: HIDE_GLOBALMODAL,
        payload: options
    }
}

export const setResultGlobalModal = (result) => {
    return {
        type: SET_RESULT_GLOBALMODAL,
        payload: result
    }
}

export const resetGlobalModal = () => {
    return {
        type: RESET_GLOBALMODAL
    }
}