import { AgGridReact } from "ag-grid-react"
import gridOptions from "./configuration/gridOptions"
import "./index.css"

const KonsultantkaRegisterLogsTab = ({ konsultantka }) => {
  if(!konsultantka || !konsultantka.logRejestracjiKonsultantkis)
  {
    return <p>Brak danych do wyświetlenia</p>
  }

  return <div className="ag-theme-alpine konsultantkaRegisterLogsTab-container">
    <AgGridReact
      gridOptions={gridOptions}
      rowData={konsultantka.logRejestracjiKonsultantkis}
    />
  </div>
}

export default KonsultantkaRegisterLogsTab