// React
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import NotificationsPanelItem from "../NotificationPanelItem";

// Css
import "./index.css"
import { useEffect } from "react";
import { setNotificationModalVisibility, setNotificationsPanelVisibility } from "../../redux/actions/notificationsActions";

const NotificationsPanel = () => {
    const reduxState = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const handleClickCloseNotificationsPanel = e => {
            if(e.target.id === "notificationButton")
            {
                return
            }

            let isPanelElement = false
            let parent = e.target.parentElement
            while(parent !== document.body)
            {
                if(parent.classList.contains("notificationsPanel-container"))
                {
                    isPanelElement = true
                    break
                }
                parent = parent.parentElement
            }

            if(!isPanelElement)
            {
                dispatch(setNotificationModalVisibility())
            }
        }

        document.addEventListener("click", handleClickCloseNotificationsPanel)

        return () => {
            document.removeEventListener("click", handleClickCloseNotificationsPanel)
        }
    },[])

    const handleClickPreview = () => {
        dispatch(setNotificationsPanelVisibility())
        navigate("/ustawienia/powiadomienia")
    }

    return (
        <div className="notificationsPanel-container">
            <div className="notificationsPanel-items-container">
            {
                reduxState.notification.notifications.length === 0 ?
                    <NotificationsPanelItem
                        description="Brak nowych powiadomień"
                    />
                    :
                    <>
                    {
                        reduxState.notification.notifications.map((notification, index) => {
                            return <NotificationsPanelItem
                                key={index}
                                title={notification.title}
                                description={notification.description}
                                datetime={notification.datetime}
                                isNew={notification.isNew}
                            />
                        })
                    }
                    </>
            }
            </div>
            <p className="notificationsPanel-preview-button"
                onClick={handleClickPreview}>Podgląd historycznych powiadomień</p>
        </div>

    );
}
export default NotificationsPanel;
