import { AgGridReact } from "ag-grid-react"
import gridOptions from "./configuration/gridOptions"

// Css
import "./index.css"

const KonsultantkaLogsTab = ({ konsultantka }) => {
  if(!konsultantka || !konsultantka.logs)
  {
    return <p>Brak danych do wyświetlenia</p>
  }

  return <div className="ag-theme-alpine konsultantkaLogsTab-container">
      <AgGridReact
        gridOptions={gridOptions}
        rowData={konsultantka.logs}
      />
  </div>
}

export default KonsultantkaLogsTab