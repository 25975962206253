import Api from "./base/Api"

class RolesApi extends Api {
    static baseRoute = "roles"

    static async getRoles(token) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}`, null, null, token)
    }

    static async checkHasAccessToRoute(token, route) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/accessroute`, { route }, null, token)
    }

    static async checkHasAccessToEndpoint(token, endpoint, method) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/accessendpoint`, { endpoint, method }, null, token)
    }

    static async updateRoleAccessright(token, roleId, accessrightId, granted) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._put(`${this.getBaseUrl()}/${this.baseRoute}/roleaccessrights`, { roleId, accessrightId, granted }, true, headers, token)
    }

    static async getUserRoleAccessrights(token, type) {
        return await this._get(`${this.getBaseUrl()}/${this.baseRoute}/roleaccessrights`, { type }, null, token)
    }

    static async addRole(token, roleName) {
        const headers = {
            "Content-Type": "application/json"
        }
        return await this._post(`${this.getBaseUrl()}/${this.baseRoute}`, { roleName }, true, headers, token)
    }

    static async deleteRole(token, id) {
        return await this._delete(`${this.getBaseUrl()}/${this.baseRoute}/${id}`, null, false, null, token)
    }
}

export default RolesApi