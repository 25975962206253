// React
import { Outlet } from "react-router-dom";

// Components
import SettingsNavbar from "../../components/SettingsNavbar"

const SettingsPage = () => {
    return (
        <>
            <SettingsNavbar />
            <Outlet/>
        </>
    );
}

export default SettingsPage;
