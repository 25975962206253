// Libraries
import { useNavigate } from 'react-router-dom';

// Components
import { WrapForm } from '../../components/Styled'
import ChangePasswordForm from "../../components/ChangePasswordForm";

const ChangePasswordPage = (props) => {
    const navigate = useNavigate()

    return (
        <WrapForm style={{ width: "40%" }}>
            <ChangePasswordForm navigate={navigate} />
        </WrapForm>
    );
}

export default ChangePasswordPage;
