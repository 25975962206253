// Services
import EventsStackService from "../../services/EventsStackService";

// Redux
import { deleteKonsultantki, updateKonsultantki } from "../actions/konsultantkiActions";
import { addNotifications } from "../actions/notificationsActions";
import { updateAppSettings } from "../actions/appSettingsActions";

// Settings
import serverEventsSettings from "../../settings/serverEventsSettings";

const serverEventsMiddleware = ({ dispatch, getState }) => next => {
  const handleEvents = async () => {
    if(!getState().logged)
    {
        setTimeout(handleEvents, serverEventsSettings.REFRESH_INTERVAL / 4);
        return
    }

    try
    {
      const events = await EventsStackService.getAll(getState().loggedDateTime, null, null)
      for(const event of events)
      {
        if(event.dataType === EventsStackService.DataTypes.KONSULTANTKA) {
            if(event.eventType === EventsStackService.EventType.UPDATE) {
                dispatch(updateKonsultantki(event.data))
            }

            if(event.eventType === EventsStackService.EventType.DELETE) {
                dispatch(deleteKonsultantki(event.data))
            }
        }

        if(event.dataType === EventsStackService.DataTypes.NOTIFICATION) {
            if(event.eventType === EventsStackService.EventType.NEW) {
              dispatch(addNotifications(event.data))
            }
        }

        if(event.dataType === EventsStackService.DataTypes.APPSETTING) {
          if(event.eventType === EventsStackService.EventType.UPDATE) {
            dispatch(updateAppSettings(event.data))
          }
        }
      }
    }
    catch(error){
      //TODO: logować błedy do tabeli logów
    }

    setTimeout(handleEvents, serverEventsSettings.REFRESH_INTERVAL);

  }

  handleEvents()

  return action => next(action);
}

export default serverEventsMiddleware

