import LogsApi from "../api/LogsApi"
import TokenService from "./TokenService"

class LogsService {
    static async getAll(lastId = null, count = null) {
        const res = await LogsApi.getAll(TokenService.getToken(), lastId, count)
        if (!res.ok) {
            const message = `LogsService.getAll()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    static async getOne(id) {
        const res = await LogsApi.getOne(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `LogsService.getOne()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    static async getReferenced(referenceId, referenceTable) {
        const query = { referenceId, referenceTable }
        const res = await LogsApi.getQuery(TokenService.getToken(), query)
        if (!res.ok) {
            const message = `LogsService.getReferenced()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }
}

export default LogsService