// React
import { useEffect, useState } from 'react';

// Libraries
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

// Types
import ModalColors from './constants/modalColors';
import ModalType from './types/modalType';
import ModalBorderColors from './constants/modalBorderColors';
import ModalButtonsType from './types/modalButtonsType';

// Components
import ButtonDark from "../Styled/ButtonDark"

// Css
import "./index.css"

// Styles
import { styleBox } from './styles'

// GFX
import xSymbol from "./gfx/x-symbol.svg"

const ModalComponent = ({ type, onAction, onClose, buttons, open, title, text, blockClosing }) => {
    const [color, setColor] = useState("")
    const [borderColor, setBorderColor] = useState("")

    useEffect(() => {
        setModalColor()
    }, [type])

    const setModalColor = () => {
        switch (type) {
            case ModalType.DANGER:
                setColor(ModalColors.DANGER)
                setBorderColor(ModalBorderColors.DANGER)
                break;
            case ModalType.WARNING:
                setColor(ModalColors.WARNING)
                setBorderColor(ModalBorderColors.WARNING)
                break;
            case ModalType.SUCCESS:
                setColor(ModalColors.SUCCESS)
                setBorderColor(ModalBorderColors.SUCCESS)
                break;
            case ModalType.INFO:
            default:
                setColor(ModalColors.INFO)
                setBorderColor(ModalBorderColors.INFO)
        }
    }

    const handleClose = () => {
        if (blockClosing) {
            return;
        }

        onClose()
    }

    const handleClickOk = () => {
        handleClose()
    }

    const handleClickYes = () => {
        if (onAction) {
            onAction(ModalButtonsType.YESNO, { result: true })
        }
    }

    const handleClickNo = () => {
        if (onAction) {
            onAction(ModalButtonsType.YESNO, { result: false })
        }
    }

    const getButtons = () => {
        switch (buttons) {
            case ModalButtonsType.OK:
                return <>
                    <ButtonDark onClick={handleClickOk}>Ok</ButtonDark>
                </>
            case ModalButtonsType.YESNO:
                return <>
                    <ButtonDark onClick={handleClickYes}>Tak</ButtonDark>
                    <ButtonDark onClick={handleClickNo}>Nie</ButtonDark>
                </>
            case ModalButtonsType.EMPTY:
            default:
                return <></>
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{
                ...styleBox,
                backgroundColor: color,
                borderColor: borderColor,
                padding: 6,
            }}>
                {
                    blockClosing ?
                        null
                        :
                        <img
                            className="modal-x"
                            onClick={() => handleClose()}
                            src={xSymbol}
                            alt="xSymbol" />
                }
                <h2 className="modal-title">{title}</h2>
                <div className="modal-text">{text}</div>
                <div className="modal-buttons-container">
                    {getButtons()}
                </div>
            </Box>
        </Modal>
    );
}

export default ModalComponent