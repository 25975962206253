class KonsultantkiTool {
  static getRegisterSourceFormated(registerSource) {
    switch (registerSource) {
      case "FACEBOOK":
        return "Facebook"
      case "GOOGLEADS":
        return "Google Ads"
      case "INSTAGRAM":
        return "Instagram"
      case "DIRECT":
        return "Bezpośrednie"
      case "ANOTHER":
      default:
          return "Inne"
  }
  }
}

module.exports = KonsultantkiTool