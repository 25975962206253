// Api
import KonsultantkiApi from "../api/KonsultantkiApi"

// Services
import TokenService from "./TokenService"

class KonsultantkiService {
    static getRangeTypes = {
        GT: "GT",
        LT: "LT"
    }

    static async getAll() {
        const res = await KonsultantkiApi.getAll(TokenService.getToken())
        if (!res.ok) {
            const message = `KonsultantkiService.getAll()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.konsultantki
    }

    static async getRange(lastId, count, filters = null, getRangeType = KonsultantkiService.getRangeTypes.LT) {
        if (filters) {
            filters = JSON.stringify(filters)
        }

        const res = await KonsultantkiApi.getRange(TokenService.getToken(), lastId, count, filters, getRangeType)
        if (!res.ok) {
            const message = `KonsultantkiService.getRange()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.konsultantki
    }

    static async getScreenShoots(id) {
        const res = await KonsultantkiApi.getScreenshoots(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `KonsultantkiService.getScreenshoots()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.files
    }

    static async register(ids) {
        const res = await KonsultantkiApi.register(TokenService.getToken(), ids)
        if (!res.ok) {
            const message = `KonsultantkiService.register()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data.results
    }

    static async update(id, field, value) {
        const res = await KonsultantkiApi.update(TokenService.getToken(), id, field, value)
        if (!res.ok) {
            const message = `KonsultantkiService.update()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static async deleteMany(ids) {
        const res = await KonsultantkiApi.deleteMany(TokenService.getToken(), ids)
        if (!res.ok) {
            const message = `KonsultantkiService.delete()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static checkIfMeetsTheAgGridFilters(konsultantkaField, filterData) {
        let isOk = true
        if (filterData.filterType === 'text') {
            switch (filterData.type) {
                case 'contains':
                    isOk = isOk && konsultantkaField.toLowerCase().includes(filterData.filter.toLowerCase())
                case 'notContains':
                    isOk = isOk && !konsultantkaField.toLowerCase().includes(filterData.filter.toLowerCase())
                case 'equals':
                    isOk = isOk && konsultantkaField.toLowerCase() == filterData.filter.toLowerCase()
                case 'notEqual':
                    isOk = isOk && konsultantkaField.toLowerCase() != filterData.filter.toLowerCase()
                case 'startsWith':
                    isOk = isOk && konsultantkaField.toLowerCase().startsWith(filterData.filter.toLowerCase())
                case 'endsWith':
                    isOk = isOk && konsultantkaField.toLowerCase().endsWith(filterData.filter.toLowerCase())
                case 'blank':
                    isOk = isOk && konsultantkaField.toLowerCase() == ''
                case 'notBlank':
                    isOk = isOk && konsultantkaField.toLowerCase() != '' && konsultantkaField != null
            }
        } else if (filterData.filterType === 'set') {
            if (filterData.values.some(x => x.toLowerCase() === 'tak')) {
                isOk = isOk && konsultantkaField
            } else if (filterData.values.some(x => x.toLowerCase() === 'nie')) {
                isOk = isOk && !konsultantkaField
            } else {
                isOk = isOk && filterData.values.some(x => x.toLowerCase() == konsultantkaField.toLowerCase())
            }
        }
        return isOk
    }
}

export default KonsultantkiService