// Api
import AppSettingsApi from "../api/AppSettingsApi"

// Services
import TokenService from "./TokenService"

class AppSettingsService {
  static async getOne(path) {
    const res =  await AppSettingsApi.getOne(TokenService.getToken(), path)
    if(!res.ok) {
      const message = `AppSettingsService.getOne()\n${res.status} - ${res.message}`
      throw new Error(message)
    }
    return res.data.appsetting
  }

  static async getAll() {
    const res = await AppSettingsApi.getMany(TokenService.getToken())
    if (!res.ok) {
      const message = `AppSettingsService.getAll()\n${res.status} - ${res.message}`
      throw new Error(message)
    }
    return res.data.appsettings
  }

  // query = { path, group, field }
  static async getMany(query) {
    const res = await AppSettingsApi.getMany(TokenService.getToken(), query)
    if (!res.ok) {
      const message = `AppSettingsService.getMany()\n${res.status} - ${res.message}`
      throw new Error(message)
    }
    return res.data.appsettings
  }

  static async update(path, type, value) {
    const res = await AppSettingsApi.update(TokenService.getToken(), path, type, value)
    if (!res.ok) {
      const message = `AppSettingsService.update()\n${res.status} - ${res.message}`
      throw new Error(message)
    }
  }

  // appsettings = [
  //   { path, type, value }
  // ] 
  static async updateBulk(appsettings) {
    const res = await AppSettingsApi.updateBulk(TokenService.getToken(), appsettings)
    if (!res.ok) {
      const message = `AppSettingsService.updateBulk()\n${res.status} - ${res.message}`
      throw new Error(message)
    }
  }
}

export default AppSettingsService