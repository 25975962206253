const mainNavbarRoutes = [
    {
        link: "/konsultantki",
        text: "Konsultantki",
        loggedd: true
    },
    {
        link: "/ustawienia/liderzy",
        text: "Liderzy",
        loggedd: true
    },
    {
        link: "/ustawienia",
        text: "Ustawienia",
        loggedd: true
    },
    {
        link: "/struktura",
        text: "Struktura",
        loggedd: true
    },
]

export default mainNavbarRoutes