import RegistrationRedirectionsScheduleApi from "../api/RegistrationRedirectionsScheduleApi"
import TokenService from "./TokenService"

class RegistrationRedirectionsScheduleService {
    static async getByLeaderID(leaderID) {
        const res = await RegistrationRedirectionsScheduleApi.getRedirectionScheduleByLeaderID(TokenService.getToken(), leaderID)
        if (!res.ok) {
            const message = `RegistrationRedirectionsScheduleService.getByLeaderID()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    static async getByUser() {
        const res = await RegistrationRedirectionsScheduleApi.getRedirectionScheduleByUser(TokenService.getToken())
        if (!res.ok) {
            const message = `RegistrationRedirectionsScheduleService.getByUser()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
        return res.data
    }

    // registrationRedirectionScheduleData = { type, leaderID, dateFrom, dateTo, quantity }
    static async create(registrationRedirectionScheduleData) {
        const res = await RegistrationRedirectionsScheduleApi.createRedirectionSchedule(TokenService.getToken(), registrationRedirectionScheduleData)
        if (!res.ok) {
            const message = `RegistrationRedirectionsScheduleService.create()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    static async delete(id) {
        const res = await RegistrationRedirectionsScheduleApi.deleteRedirectionSchedule(TokenService.getToken(), id)
        if (!res.ok) {
            const message = `RegistrationRedirectionsScheduleService.delete()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }

    // ids = []
    static async deleteMany(ids) {
        const res = await RegistrationRedirectionsScheduleApi.deleteManyRedirectionSchedule(TokenService.getToken(), ids)
        if (!res.ok) {
            const message = `RegistrationRedirectionsScheduleService.delete()\n${res.status} - ${res.message}`
            throw new Error(message)
        }
    }
}

export default RegistrationRedirectionsScheduleService