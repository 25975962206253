// Tools
import { isMobile } from "react-device-detect"
import TimeTool from "../../Tools/TimeTool"

// GFX
import xSymbol from "./gfx/x-symbol.svg"

// Css
import "./index.css"

const NotificationsModal = ({ id, title, datetime, description, onClickClose = (id) => {} }) => {
  if(isMobile)
  {
    return <div className="notificationsModal-container">
      <p className="notificationsModal-title">Nowe powiadomienie!</p>
    </div>
  }

  return <div className="notificationsModal-container">
    <img className="notificationsModal-xsymbol" 
      src={xSymbol} 
      alt="xSymbol" 
      onClick={() => onClickClose(id)} />
    <p className="notificationsModal-title">{title}</p>
    <p className="notificationsModal-dateTime">{datetime ? TimeTool.format(datetime) : datetime}</p>
    <p className="notificationsModal-description">{description}</p>
  </div>
}

export default NotificationsModal
