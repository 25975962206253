// React
import { useEffect, useState } from "react";

// Libraries
import { Navigate, Outlet, useLocation } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Services
import RolesService from "../../../services/RolesService";
import LoginService from "../../../services/LoginService";
import { showErrorModal } from "../../GlobalModal/actions";

const PrivateRoute = (props) => {
    const location = useLocation()

    const [wait, setWait] = useState(true)
    const [permissons, setPermissons] = useState({ granted: true })

    const reduxState = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        setWaitFalse()
    }, [permissons])

    useEffect(() => {
        checkHasRole(location.pathname)
    }, [location])

    const setWaitFalse = () => {
        setWait(false)
    }

    const checkHasRole = async (path) => {
        if (!reduxState.initLoggedCheck) {
            return
        }

        try {
            setWait(true)
            const result = await RolesService.checkHasAccessToRoute(path)
            if (!result) {
                await LoginService.logout()
            }
            setPermissons({ granted: result })
        }
        catch (error) {
            showErrorModal(dispatch, "Błąd", error.toString())
        }
    }

    if (wait)
    {
        return null
    }

    if(reduxState.logged)
    {
        if(reduxState.userData.changePassword && location.pathname !== "/zmianahasla")
        {
            return <Navigate to="/zmianahasla" />
        }
        else
        {
            if(permissons.granted)
            {
                return <Outlet/>
            }
            return <Navigate to="/" />
        }
    }
    return <Navigate to="/" />
}

export default PrivateRoute;
