import NotificationsApi from "../api/NotificationsApi"
import TokenService from "./TokenService"

class NotificationsService {
  static async getPermitted(lastId, count) {
      const res = await NotificationsApi.getPermitted(TokenService.getToken(), lastId, count)
      if (!res.ok) {
        const message = `NotificationsService.getPermitted()\n${res.status} - ${res.message}`
        throw new Error(message)
      }
      return res.data
  }

  static async remove(id) {
      const res = await NotificationsApi.remove(TokenService.getToken(), id)
      if (!res.ok) {
        const message = `NotificationsService.remove()\n${res.status} - ${res.message}`
        throw new Error(message)
      }
      return res.data
  }
}

export default NotificationsService
