import {
    ADD_KONSULTANTKI,
    ADD_KONSULTANTKI_START,
    SET_KONSULTANTKI,
    UPDATE_KONSULTANTKI,
    DELETE_KONSULTANTKI
} from "../types/konsultantkiTypes"

export const setKonsultantki = (konsultantki, fetchUUID = "") => {
    return {
        type: SET_KONSULTANTKI,
        payload: { fetchUUID, konsultantki }
    }
}

export const addKonsultantki = (konsultantki, fetchUUID = "") => {
    return {
        type: ADD_KONSULTANTKI,
        payload: { fetchUUID, konsultantki }
    }
}

export const addKonsultantkiStart = (konsultantki, fetchUUID = "") => {
    return {
        type: ADD_KONSULTANTKI_START,
        payload: { fetchUUID, konsultantki }
    }
}

export const updateKonsultantki = (konsultantki) => {
    return {
        type: UPDATE_KONSULTANTKI,
        payload: konsultantki
    }
}

export const deleteKonsultantki = (ids) => {
    return {
        type: DELETE_KONSULTANTKI,
        payload: ids
    }
}